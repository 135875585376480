import { useSelector } from 'react-redux';
import { BackgroundTypes, IframeComponents } from 'shared/enums';
import { getAppConfigSelector } from 'store/app/app.redux';
import { DualBackground } from './backgrounds/DualBackground';
import { DefaultBackground } from './backgrounds/DefaultBackground';
import { useMemo } from 'react';

export const Background = () => {
  const appConfig = useSelector(getAppConfigSelector);

  const element = appConfig.embeddedStyles?.find(
    (styles) => styles.elementIdentifier === IframeComponents.Visit
  );

  const backgroundImages = useMemo(() => {
    const visitStyle = appConfig.embeddedStyles?.find(
      (embddedStyle) => embddedStyle.elementIdentifier === 'visit'
    );
    return visitStyle?.backgroundImages || [];
  }, []);

  switch (element?.backgroundType) {
    case BackgroundTypes.Dual:
      return <DualBackground backgroundImages={backgroundImages} />;
    default:
      return <DefaultBackground backgroundImage={backgroundImages[0]} />;
  }
};
