import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Menu: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2H15C15.5523 2 16 2.44772 16 3C16 3.55228 15.5523 4 15 4H1C0.447715 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2ZM1 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H1C0.447715 9 0 8.55228 0 8C0 7.44772 0.447715 7 1 7ZM1 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H1C0.447715 14 0 13.5523 0 13C0 12.4477 0.447715 12 1 12Z"
        fill={color}
      />
    </svg>
  );
};
export default Menu;
