import { themeIconLight } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Next: SvgIconType = ({
  width = 11,
  height = 18,
  className = '',
  color = themeIconLight
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L9 9L1 17" stroke={color} strokeWidth="2" />
  </svg>
);

export default Next;
