import { themeIconLight, themePrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const User: SvgIconType = ({
  width = 80,
  height = 80,
  className = '',
  color = themeIconLight
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <circle cx="40" cy="40" r="40" fill={themePrimary} />
    <path
      d="M40.5886 16H39.4114C39.1204 16 38.8294 16.0121 38.5394 16.0363C32.7605 16.5178 28.4661 21.593 28.9477 27.372L29.4403 33.2837C29.898 38.7757 34.489 43 40 43C45.511 43 50.102 38.7757 50.5597 33.2837L51.0523 27.372C51.0765 27.0819 51.0886 26.791 51.0886 26.5C51.0886 20.701 46.3876 16 40.5886 16Z"
      fill={color}
    />
    <path
      d="M51.7004 44.3877C48.6317 47.2527 44.5135 49 40 49C35.4865 49 31.3683 47.2527 28.2996 44.3877L23.0225 46.3666C18.7984 47.9506 16 51.9887 16 56.5V61C16 62.6569 17.3431 64 19 64H61C62.6569 64 64 62.6569 64 61V56.5C64 51.9887 61.2016 47.9506 56.9775 46.3666L51.7004 44.3877Z"
      fill={color}
    />
  </svg>
);

export default User;
