import { createSlice } from '@reduxjs/toolkit';
import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

// Slice
const labOrdersSlice = createSlice({
  name: 'labOrders',
  initialState: initialStore.labOrders,
  reducers: {
    getLabOrders: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setLabOrders: (state, { payload }) => {
      state.isLoading = false;
      state.labOrders = payload;
    },
    getLabOrder: (state, _data) => {
      state.isLoading = true;
    },
    setLabOrder: (state, { payload }) => {
      state.isLoading = false;
      state.currentLabOrder = payload;
    },
    failAction: (state) => {
      state.isLoading = false;
    }
  }
});

export const {
  getLabOrders,
  setLabOrders,
  getLabOrder,
  setLabOrder,
  failAction
} = labOrdersSlice.actions;

export const { reducer } = labOrdersSlice;

// Selectors
export const getLabOrdersLoadingSelector = (state: IState) =>
  state && state.labOrders.isLoading;

export const getCurrentLabOrderSelector = (state: IState) =>
  state && state.labOrders.currentLabOrder;

export const getLabOrdersSelector = (state: IState) =>
  state && state.labOrders.labOrders;
