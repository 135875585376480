import { FC, useState } from 'react';

import { themeDark, themeTextSecondary } from 'shared/utils/cssUtility';
import classes from './styles.module.scss';

interface ISidebarTabSvg {
  arrowDirection: 'right' | 'left';
  className?: string;
}

const SidebarTabSvg: FC<ISidebarTabSvg> = ({ className, arrowDirection }) => {
  const height = 58;
  const width = 12;
  const insideHeight = 12;
  const insideWidth = insideHeight;
  const iconColor = themeTextSecondary;
  const [color, setColor] = useState(iconColor);

  return (
    <div className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseOver={() => setColor(themeDark)}
        onMouseLeave={() => setColor(iconColor)}
      >
        <path
          d="M0 0.436266C0 0.24311 0.209711 0.12292 0.376373 0.220559L8.04393 4.71261C10.4943 6.1482 12 8.77529 12 11.6153V46.3848C12 49.2247 10.4944 51.8518 8.044 53.2874L0.376375 57.7796C0.209713 57.8772 0 57.757 0 57.5638V29.0001V0.436266Z"
          fill="white"
        />
        {/* Right arrow */}
        {arrowDirection === 'right' && (
          <svg
            y={`${height / 2 - insideHeight / 2}`}
            x={`${width / 2 - insideWidth / 2 - width / 10}`} //We are centering it than giving an extra 10% on the edge
            width={insideWidth}
            height={insideHeight}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.chevron}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.29316 2.40864C4.90228 2.01775 4.90228 1.384 5.29316 0.993115C5.68405 0.60223 6.3178 0.60223 6.70869 0.993115L13.0084 7.29284C13.3989 7.68337 13.3989 8.31653 13.0084 8.70706L6.70869 15.0068C6.3178 15.3977 5.68405 15.3977 5.29316 15.0068C4.90228 14.6159 4.90228 13.9822 5.29316 13.5913L10.8845 7.99995L5.29316 2.40864Z"
              fill={color}
            />
          </svg>
        )}
        {/* Left arrow */}
        {arrowDirection === 'left' && (
          <svg
            y={`${height / 2 - insideHeight / 2}`}
            x={`${width / 2 - insideWidth / 2 - width / 10}`}
            width={insideWidth}
            height={insideHeight}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.chevron}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.0081 2.40864L5.41683 7.99995L11.0081 13.5913C11.399 13.9822 11.399 14.6159 11.0081 15.0068C10.6173 15.3977 9.98351 15.3977 9.59262 15.0068L3.29289 8.70706C2.90237 8.31653 2.90237 7.68337 3.29289 7.29284L9.59262 0.993115C9.98351 0.60223 10.6173 0.60223 11.0081 0.993115C11.399 1.384 11.399 2.01775 11.0081 2.40864Z"
              fill={color}
            />
          </svg>
        )}
      </svg>
    </div>
  );
};
export default SidebarTabSvg;
