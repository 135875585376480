import { createAction, createSlice } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

export const getAffiliate = createAction('app/getAffiliate', (payload) => ({
  payload
}));

export const resetApp = createAction('app/resetApp');

const appSlice = createSlice({
  name: 'app',
  initialState: initialStore.app,
  reducers: {
    setAppConfig: (state, { payload }) => {
      state.appConfig = payload;
    },
    setAppTheme: (state, { payload }) => {
      state.appTheme = payload;
    },
    setWhiteLabelAffiliate: (state, { payload }) => {
      state.whiteLabelAffiliate = payload;
    },
    setAffiliateStylesConfig: (state, { payload }) => {
      state.affiliateStylesConfig = payload;
    }
  }
});

export const {
  setAppConfig,
  setAppTheme,
  setWhiteLabelAffiliate,
  setAffiliateStylesConfig
} = appSlice.actions;

export const { reducer } = appSlice;

// Selectors
export const getAppConfigSelector = (state: IState) =>
  state && state.app.appConfig;

export const getAppThemeSelector = (state: IState) =>
  state && state.app.appTheme;

export const getWhiteLabelAffiliateSelector = (state: IState) =>
  state && state.app.whiteLabelAffiliate;

export const getAffiliateStylesConfigSelector = (state: IState) =>
  state && state.app.affiliateStylesConfig;

export const getAffiliateGlobalStylesSelector = (state: IState) =>
  state && state.app.affiliateStylesConfig?.global;

export const getAffiliateChannelsStylesSelector = (state: IState) =>
  state && state.app.affiliateStylesConfig?.channels;

export const getAffiliateChannelStylesSelector = (state: IState) =>
  state && state.app.affiliateStylesConfig?.channel;

export const getAffiliateOrdersStylesSelector = (state: IState) =>
  state && state.app.affiliateStylesConfig?.orders;

export const getAffiliateOrderStylesSelector = (state: IState) =>
  state && state.app.affiliateStylesConfig?.order;

export const getAffiliateVisitStylesSelector = (state: IState) =>
  state && state.app.affiliateStylesConfig?.visit;
