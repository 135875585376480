import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const ChevronRight: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.29316 2.40864C4.90228 2.01775 4.90228 1.384 5.29316 0.993115C5.68405 0.60223 6.3178 0.60223 6.70869 0.993115L13.0084 7.29284C13.3989 7.68337 13.3989 8.31653 13.0084 8.70706L6.70869 15.0068C6.3178 15.3977 5.68405 15.3977 5.29316 15.0068C4.90228 14.6159 4.90228 13.9822 5.29316 13.5913L10.8845 7.99995L5.29316 2.40864Z"
      fill={color}
    />
  </svg>
);
export default ChevronRight;
