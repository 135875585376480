import { Suspense, PropsWithChildren, FC, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getUserSelector,
  getUserLoadingSelector,
  getUserData
} from 'store/user/user.redux';
import { AuthUrls, Urls } from 'shared/constants/urls';
import { BaseLayout } from 'shared/components/BaseLayout';
import { Spinner } from 'shared/components/Spinner';
import {
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
  setItemToLocalStorage,
  WHITE_LABEL_AFFILIATE,
  IFRAME,
  getItemFromLocalStorage,
  POTENTIAL_USER,
  EMAIL,
  PHONE,
  STARTED_FROM_DASHBOARD,
  REDIRECT_URL,
  removeItemsFromLocalStorage,
  ACCESS_TOKEN_NAME
} from 'shared/utils/local-storage';
import { useQuery } from 'shared/utils/hooks/useQuery';
import { useOnlineVisit } from 'shared/utils/hooks/useOnlineVisit';
import { Layouts } from 'shared/constants/layouts';
import { USER_PROGRESS_DATA } from 'pages/OnlineVisit/constants';
import { ROUTES, TLayout } from './pages';
import { getTwoFactorAuthSelector } from 'store/auth/auth.redux';
import {
  getAffiliate,
  getAffiliateGlobalStylesSelector,
  getAppConfigSelector,
  resetApp
} from 'store/app/app.redux';
import { Background } from 'pages/OnlineVisit/Layout/Background';
import { useIntegrations } from 'shared/utils/hooks/integrations';

type TRequireAuthProps = {
  privateRoute: boolean;
  url: Urls;
};

const RequireAuth: FC<PropsWithChildren<TRequireAuthProps>> = ({
  privateRoute,
  url,
  children
}) => {
  const dispatch = useDispatch();
  const { access_token } = useQuery();
  const user = useSelector(getUserSelector);
  const isLoadingUser = useSelector(getUserLoadingSelector);
  const twoFactorType = useSelector(getTwoFactorAuthSelector);
  const token = getTokenFromLocalStorage();
  const whiteLabelAffiliate = getItemFromLocalStorage(WHITE_LABEL_AFFILIATE);

  useEffect(() => {
    if (privateRoute && (token || access_token) && !user && !isLoadingUser) {
      dispatch(getUserData());
    } else if (whiteLabelAffiliate) {
      dispatch(getAffiliate({ affiliateId: whiteLabelAffiliate }));
    }
  }, [dispatch, privateRoute, token, user]);

  if (
    privateRoute &&
    url !== Urls.IframeComponent &&
    ((url === Urls.OTPPage && !twoFactorType) ||
      (url !== Urls.OTPPage && !token))
  ) {
    return <Navigate to={Urls.LoginPage} />;
  }

  if (
    (url === Urls.LoginPage ||
      url === Urls.OTPPage ||
      url === Urls.RegistrationPage) &&
    token
  ) {
    return <Navigate to={Urls.DashBoard} />;
  }

  return (!user || isLoadingUser) && privateRoute && url !== Urls.OTPPage ? (
    <Spinner />
  ) : (
    <>{children}</>
  );
};

const Layout: FC<PropsWithChildren<{ layout?: TLayout }>> = ({
  layout,
  children
}) => {
  if (layout) {
    const { type } = layout;

    switch (type) {
      case Layouts.Base: {
        return <BaseLayout>{children}</BaseLayout>;
      }
      default: {
        return <>{children}</>;
      }
    }
  }
  return <>{children}</>;
};

export const App = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const {
    iframe,
    access_token,
    firstName,
    lastName,
    email,
    phone,
    gender,
    dateOfBirth,
    affiliate,
    orderId,
    productVariations,
    externalIdentifier,
    project,
    address1,
    address2,
    city,
    state,
    zipcode
  } = useQuery();
  const isOnlineVisit = useOnlineVisit();
  const integrations = useIntegrations();
  const whiteLabelAffiliate = getItemFromLocalStorage(WHITE_LABEL_AFFILIATE);
  let potentialUser = getItemFromLocalStorage(POTENTIAL_USER);
  const potentialOrder = getItemFromLocalStorage(
    USER_PROGRESS_DATA.POTENTIAL_ORDER
  );
  const affiliateGlobalStyles = useSelector(getAffiliateGlobalStylesSelector);
  const [showContent, setShowContent] = useState(false);
  const appConfig = useSelector(getAppConfigSelector);

  const clearDataIfParamWasChanged = () => {
    if (potentialUser && email !== potentialUser?.email) {
      removeItemsFromLocalStorage([
        USER_PROGRESS_DATA.USER_PROGRESS,
        ACCESS_TOKEN_NAME,
        POTENTIAL_USER
      ]);
      dispatch(resetApp());
      potentialUser = getItemFromLocalStorage(POTENTIAL_USER);
    }

    if (
      potentialOrder &&
      productVariations &&
      productVariations !== JSON.stringify(potentialOrder?.productVariations)
    ) {
      removeItemsFromLocalStorage([USER_PROGRESS_DATA.USER_PROGRESS]);
    }
  };

  useEffect(() => {
    if (access_token) {
      setTokenToLocalStorage(access_token);
    }

    if (affiliate) {
      dispatch(getAffiliate({ affiliateId: affiliate }));

      if (whiteLabelAffiliate && affiliate !== whiteLabelAffiliate) {
        removeItemsFromLocalStorage([WHITE_LABEL_AFFILIATE]);
      }
    }

    if (iframe) {
      setItemToLocalStorage(IFRAME, iframe);
    }
  }, [pathname]);

  useEffect(() => {
    if (!isOnlineVisit) {
      removeItemsFromLocalStorage([
        ...Object.values(USER_PROGRESS_DATA),
        POTENTIAL_USER,
        EMAIL,
        PHONE,
        STARTED_FROM_DASHBOARD,
        REDIRECT_URL
      ]);
    }
  }, [isOnlineVisit]);

  useEffect(() => {
    if (
      !whiteLabelAffiliate ||
      (whiteLabelAffiliate && affiliateGlobalStyles)
    ) {
      setShowContent(true);
    }
  }, [whiteLabelAffiliate, affiliateGlobalStyles]);

  useEffect(() => {
    if (firstName || lastName || email || phone || gender || dateOfBirth) {
      const user = {
        firstName,
        lastName,
        email,
        phone,
        gender,
        dateOfBirth,
        affiliate
      };

      clearDataIfParamWasChanged();

      setItemToLocalStorage(POTENTIAL_USER, { ...potentialUser, ...user });
    }
    if (email) {
      setItemToLocalStorage(EMAIL, email);
    }
    if (phone) {
      setItemToLocalStorage(PHONE, phone);
    }
  }, [firstName, lastName, email, phone, gender, dateOfBirth]);

  useEffect(() => {
    if (
      orderId ||
      productVariations ||
      externalIdentifier ||
      affiliate ||
      project ||
      address1 ||
      address2 ||
      city ||
      state ||
      zipcode
    ) {
      const address =
        address1 || address2 || city || state || zipcode
          ? { address1, address2, city, state, zipcode }
          : null;
      const order = {
        id: orderId,
        externalIdentifier,
        project,
        productVariations: productVariations && JSON.parse(productVariations),
        affiliate,
        address
      };
      setItemToLocalStorage(USER_PROGRESS_DATA.POTENTIAL_ORDER, order);
    }
  }, [
    orderId,
    productVariations,
    affiliate,
    externalIdentifier,
    project,
    address1,
    address2,
    city,
    state,
    zipcode
  ]);

  useEffect(() => {
    if (appConfig.embeddedStyles) {
      const styles = isOnlineVisit
        ? appConfig.embeddedStyles[1].styleValue
        : appConfig.embeddedStyles[0].styleValue;
      const styleElement = document.createElement('style');
      if (styles) {
        styleElement.innerHTML = styles;
        document.head.appendChild(styleElement);
      }
    }
  }, [appConfig.embeddedStyles, isOnlineVisit]);

  return (
    <Routes>
      {ROUTES.map((el) => (
        <Route
          key={el.id}
          path={el.path}
          element={
            <Suspense
              fallback={
                el.layout?.type === Layouts.Base ? <BaseLayout /> : null
              }
            >
              <RequireAuth privateRoute={el.protected} url={el.path}>
                {showContent && (
                  <Layout layout={el.layout}>
                    {AuthUrls.includes(el.path) && <Background />}
                    <el.component />
                  </Layout>
                )}
              </RequireAuth>
            </Suspense>
          }
        />
      ))}
      <Route path="/" element={<Navigate to={Urls.DashBoard} />} />
    </Routes>
  );
};
