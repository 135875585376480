import { http } from '../utils/axios';
import { IChannel } from '../interfaces/channel.interface';

class ChannelService {
  private static instance: ChannelService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): ChannelService {
    if (!ChannelService.instance) {
      ChannelService.instance = new ChannelService();
    }

    return ChannelService.instance;
  }

  getChannels = async () => {
    const { data } = await http<IChannel[]>({
      url: '/channels',
      method: 'GET'
    });
    return data;
  };
  updateChannelById = async ({
    payload
  }: {
    payload: { content: string; channelId: string };
  }) => {
    const { channelId, content } = payload;
    const { data } = await http<IChannel[]>({
      url: `/channels/${channelId}`,
      method: 'PUT',
      data: { content }
    });
    return data;
  };
}

const instance = ChannelService.getInstance();

export default instance;
