import { UserRoles } from '../constants/userRoles';
import { IUser } from '../interfaces/user.interface';

export const getOtherParticipant = (
  currentUser: IUser | null,
  participants: IUser[] | undefined
) => {
  const otherParticipant = participants?.find(
    (participant) => participant.id !== currentUser?.id
  );
  return otherParticipant;
};

export const getOtherParticipantName = (
  currentUser: IUser | null,
  participants: IUser[] | undefined
) => {
  const otherParticipant = getOtherParticipant(currentUser, participants);
  return otherParticipant?.role === UserRoles.Provider
    ? `Dr. ${otherParticipant.firstName} ${otherParticipant.lastName}`
    : 'Clinical Support Team';
};

export const getOtherParticipantAvatar = (
  currentUser: IUser | null,
  participants: IUser[] | undefined
) => {
  const otherParticipant = getOtherParticipant(currentUser, participants);
  return otherParticipant?.picture;
};
