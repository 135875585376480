import { FC } from 'react';

import { SvgIconType } from 'shared/components/Icons';
import { generalInfo } from 'shared/constants/general-info';

type TLogoProps = {
  Logo: SvgIconType | string;
  appName: string;
  width?: number;
  height?: number;
  className?: string;
};

export const Logo: FC<TLogoProps> = ({
  Logo,
  appName,
  width,
  height,
  className = ''
}) => {
  if (Logo === 'TelegraLogo') {
    Logo = generalInfo.logo;
  }
  return (
    <>
      {typeof Logo === 'string' ? (
        <img
          src={Logo}
          alt={`${appName} Logo`}
          height={height}
          className={className}
        />
      ) : (
        <Logo width={width} height={height} className={className} />
      )}
    </>
  );
};
