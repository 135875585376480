import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Chat: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3152 11.9743L11.5916 15.7881C11.5819 15.8392 11.5581 15.886 11.5232 15.9228C11.4256 16.0257 11.2673 16.0257 11.1697 15.9228L8.38997 12.9924C8.26076 12.9975 8.13075 13 8 13C3.58172 13 0 10.0899 0 6.5C0 2.91015 3.58172 0 8 0C12.4183 0 16 2.91015 16 6.5C16 8.79863 14.5315 10.8186 12.3152 11.9743Z"
      fill={color}
    />
  </svg>
);

export default Chat;
