import {
  configureStore,
  Middleware,
  Dispatch,
  AnyAction
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import rootSaga from './sagas';
import rootReducer from './root-reducer';

const sagaMiddleware = createSagaMiddleware();

let middlewares: Middleware<any, any, Dispatch<AnyAction>>[] = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({ collapsed: true });
  middlewares = [...middlewares, logger];
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 }
    }).concat(middlewares)
});

export default store;

sagaMiddleware.run(rootSaga);
