import { SvgIconType } from './svg.interface';

const SpanishFlag: SvgIconType = ({ width = 14, height = 14 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512 342"
  >
    <g fill="none">
      <path fill="#FFDA44" d="M0 .331h512v341.337H0z" />
      <path
        d="M0 .331h512v113.775H0zm0 227.551h512v113.775H0z"
        fill="#D80027"
      />
    </g>
  </svg>
);
export default SpanishFlag;
