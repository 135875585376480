import { themeDark, themePrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const LogoHeader: SvgIconType = ({
  width = 30,
  height = 30,
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 62 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M43.3093 0H39.3256V9.91592H43.359C48.2436 9.91592 52.231 13.8628 52.231 18.6479C52.231 23.4825 48.2436 27.4294 43.359 27.4294H39.3256V36.9999H29.4569H39.3256H43.3093C53.6265 36.9999 62 28.712 62 18.5002C62 8.28787 53.6265 0 43.3093 0"
      fill="url(#paint0_linear_18310_36468)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4567 18.0066L29.4528 18.01V37H29.4567V18.0066M39.3254 9.90933e-06H34.8896L29.4528 4.90454L19.6876 13.7145L4.43603 0H0V36.9999H9.86872V18.0559L19.6876 26.8374L29.4528 18.01V4.90454L34.8896 9.90933e-06H39.3254Z"
      fill="url(#paint1_linear_18310_36468)"
    />
    <mask
      id="mask0_18310_36468"
      style={{ maskType: 'alpha' }}
      //style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="29"
      y="0"
      width="11"
      height="37"
    >
      <path
        d="M39.3253 9.90933e-06H34.8895L29.4528 4.9046V18.0101L29.4566 18.0067V37H39.3253V27.4294V9.91601V0"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_18310_36468)">
      <mask
        id="mask1_18310_36468"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="29"
        y="-1"
        width="11"
        height="39"
      >
        <rect
          x="29.3677"
          y="-0.0941467"
          width="10.0506"
          height="37.1904"
          fill="black"
        />
      </mask>
      <g mask="url(#mask1_18310_36468)">
        <rect
          width="10.0506"
          height="37.1904"
          transform="matrix(1 0 0 -1 29.3677 37.0963)"
          fill={themeDark}
        />
      </g>
    </g>
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          xlinkHref="#image0_18310_36468"
          transform="scale(0.00512821 0.00137174)"
        />
      </pattern>
      <linearGradient
        id="paint0_linear_18310_36468"
        x1="-312.61"
        y1="475.902"
        x2="-312.395"
        y2="475.902"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#59BAEC" />
        <stop offset="1" stopColor={themePrimary} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_18310_36468"
        x1="-312.611"
        y1="475.881"
        x2="-312.396"
        y2="475.881"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#59BAEC" />
        <stop offset="1" stopColor={themePrimary} />
      </linearGradient>
      <image
        id="image0_18310_36468"
        width="195"
        height="729"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMMAAALZCAMAAAA9c2VMAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAANlBMVEUPa7sPa7oParsParoOarsOaroOarkOabkOabgNabkNabgNaLkNaLgNaLcNZ7cNZ7YMZ7cMZ7bnaFo+AAAAAWJLR0QSe7xsAAAAAmtJREFUeNrtzwEKwCAIBdD0/mduY2sNcewG7xMhRsqLsZJjZ15XlGbsl7doef4etRf3yfKQnxmzrP1bEmVy9n6rGRgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGBgYGFZOvdhxFVsxkZsAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
);
export default LogoHeader;
