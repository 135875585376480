import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const External: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4V14H12V11C12 10.4477 12.4477 10 13 10C13.5523 10 14 10.4477 14 11V15C14 15.5523 13.5523 16 13 16H1C0.447715 16 0 15.5523 0 15V3C0 2.44772 0.447715 2 1 2H5C5.55228 2 6 2.44772 6 3C6 3.55228 5.55228 4 5 4H2ZM7.70711 9.70711C7.31658 10.0976 6.68342 10.0976 6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289L12.5937 1.99539H9.01613C8.46512 1.99539 8.01844 1.54871 8.01844 0.997695C8.01844 0.446683 8.46512 0 9.01613 0L15.0023 0C15.2576 0 15.513 0.0974061 15.7078 0.292218C15.9026 0.48703 16 0.742363 16 0.997695V6.98387C16 7.53488 15.5533 7.98156 15.0023 7.98156C14.4513 7.98156 14.0046 7.53488 14.0046 6.98387V3.40635L7.70711 9.70711Z"
      fill={color}
    />
  </svg>
);
export default External;
