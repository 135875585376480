export enum Colors {
  BaseWhite = '--base-white',
  BaseBlack = '--base-black',
  BaseBlue = '--base-blue',
  BaseGrey = '--base-grey',
  BaseDisabledGrey = '--base-disabled-grey',
  BaseMask = '--base-mask',

  Info = '--info',
  Success = '--success',
  SuccessSecond = '--success-second',
  Attention = '--attention',
  Critical = '--critical',

  ThemeBrandPrimary = '--theme-brand-primary',
  ThemePrimary = '--theme-primary',
  ThemeLight = '--theme-light',
  ThemeDark = '--theme-dark',
  ThemeUltraDark = '--theme-ultra-dark',

  ThemeCustomShadowDark = '--theme-custom-shadow-dark',
  ThemeCustomShadowPrimary = '--theme-custom-shadow-primary',

  ThemeExternalPrimary = '--theme-external-primary',
  ThemeExternalDark = '--theme-external-dark',
  ThemeExternalLight = '--theme-external-light',

  ThemeBackgroundPrimary = '--theme-background-primary',
  ThemeBackgroundModal = '--theme-background-modal',
  ThemeBackgroundContainer = '--theme-background-container',
  ThemeBackgroundMask = '--theme-background-mask',
  ThemeBackgroundExternal = '--theme-background-external',
  ThemeBackgroundExternalButtonBlock = 'theme-background-external-button-block',
  ThemeBackgroundProgressBar = '--theme-background-progressBar',
  ThemeBackgroundButtonLight = '--theme-background-button-light',
  ThemeBackgroundButtonHoverDark = '--theme-background-button-hover-dark',

  ThemeTextPrimary = '--theme-text-primary',
  ThemeTextSecondary = '--theme-text-secondary',
  ThemeTextDisabled = '--theme-text-disabled',
  ThemeTextLight = '--theme-text-light',
  ThemeTextDark = '--theme-text-dark',
  ThemeTextButtonDark = '--theme-text-button-dark',
  ThemeTextButtonLight = '--theme-text-button-light',

  ThemeBorder = '--theme-border',
  ThemeBorderDark = '--theme-border-dark',
  ThemeInputsBorderHover = '--theme-inputs-border-hover',

  ThemeIconLight = '--theme-icon-light',
  ThemeIconDark = '--theme-icon-dark',

  ThemeScrollbarThumb = '--theme-scrollbar-thumb',

  Scrim = '--scrim '
}

export enum FontFamilies {
  FontPrimary = '--font-primary',
  FontExternalPrimary = '--font-external-primary',
  FontSecondary = '--font-secondary',
  FontTertiary = '--font-tertiary',
  FontTitle = '--font-title '
}

export enum BorderRadiuses {
  ButtonsBorderRadius = '--buttons-border-radius'
}
