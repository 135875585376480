import { SvgIconType } from './svg.interface';

const Calendar: SvgIconType = ({
  width = 12,
  height = 12,
  className = '',
  color = '#4F566B'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 0.75C2.25 0.335786 2.58579 0 3 0C3.41421 0 3.75 0.335786 3.75 0.75V1.5H2.25V0.75ZM9.75 1.5H10.5C11.3284 1.5 12 2.17157 12 3V4.5H0V3C0 2.17157 0.671573 1.5 1.5 1.5H2.25V2.25C2.25 2.66421 2.58579 3 3 3C3.41421 3 3.75 2.66421 3.75 2.25V1.5H8.25V2.25C8.25 2.66421 8.58579 3 9 3C9.41421 3 9.75 2.66421 9.75 2.25V1.5ZM9.75 1.5H8.25V0.75C8.25 0.335786 8.58579 0 9 0C9.41421 0 9.75 0.335786 9.75 0.75V1.5ZM12 6H0V10C0 11.1046 0.895431 12 2 12H10C11.1046 12 12 11.1046 12 10V6Z"
      fill={color}
    />
  </svg>
);

export default Calendar;
