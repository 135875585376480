import { createSlice, createAction } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

export const getConversationToken = createAction(
  'conversation/getConversationToken',
  (payload) => ({ payload })
);

export const getConversation = createAction(
  'conversation/getConversation',
  (payload: { conversationId: string }) => ({ payload })
);

export const getConversationByParticipants = createAction(
  'conversation/getConversationByParticipants',
  (payload: { patientId: string; providerId: string; token?: string }) => ({
    payload
  })
);

export const upgradeTwilioConversation = createAction(
  'conversation/upgradeTwilioConversation',
  (payload: { channelId: string }) => ({ payload })
);

export const refreshTwilioConversation = createAction(
  'conversation/refreshTwilioConversation',
  (payload: { conversationId: string }) => ({ payload })
);

// Slice
const conversationSlice = createSlice({
  name: 'conversation',
  initialState: initialStore.conversation,
  reducers: {
    getConversationToken: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setConversationToken: (state, { payload }) => {
      state.isLoading = false;
      state.conversationToken = payload;
    },
    getConversationTokenFail: (state) => {
      state.isLoading = false;
    },
    setTwilioConversationObjects: (state, { payload }) => {
      state.isLoading = false;
      state.twilioConversationObjects = payload;
    },
    getConversation: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    getConversationByParticipants: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setSelectedConversation: (state, { payload }) => {
      state.isLoading = false;
      state.selectedConversation = payload;
    },
    getConversationFail: (state) => {
      state.isLoading = false;
    },
    upgradeTwilioConversation: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    refreshTwilioConversation: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    }
  }
});

export const {
  setConversationToken,
  getConversationTokenFail,
  setTwilioConversationObjects,
  setSelectedConversation,
  getConversationFail
} = conversationSlice.actions;

export const { reducer } = conversationSlice;

// Selectors
export const getConversationTokenSelector = (state: IState) =>
  state && state.conversation.conversationToken;
export const getTwilioConversationObjectsSelector = (state: IState) =>
  state && state.conversation.twilioConversationObjects;
export const getSelectedConversationSelector = (state: IState) =>
  state && state.conversation.selectedConversation;
export const getConversationLoadingSelector = (state: IState) =>
  state && state.conversation.isLoading;
