import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
import { APP_LANGUAGES } from 'shared/enums';
import { LANGUAGE, getItemFromLocalStorage } from 'shared/utils/local-storage';

const locizeOptions = {
  projectId: process.env.REACT_APP_I18N_PROJECT_ID,
  apiKey: process.env.REACT_APP_I18N_API_KEY,
  referenceLng: APP_LANGUAGES.EN,
  version: process.env.REACT_APP_I18N_VERSION || 'development'
};

export const I18N_NAMESPACE = {
  login: 'login',
  dashboard: 'dashboard',
  visitsHistory: 'visitsHistory',
  ordersHistory: 'ordersHistory',
  visit: 'visit',
  messageCenter: 'messageCenter',
  notifications: 'notifications',
  taskCenter: 'taskCenter',
  documents: 'documents',
  settings: 'settings',
  sidebar: 'sidebar',
  general: 'general',
  questionnaires: 'questionnaires',
  completeQuestionnaire: 'completeQuestionnaire',
  labOrderPage: 'labOrderPage',
  labOrdersHistory: 'labOrdersHistory',
  ovs_loader: 'ovs_loader',
  ovs_authentication: 'ovs_authentication',
  ovs_otpScreen: 'ovs_otpScreen',
  ovs_profile: 'ovs_profile',
  ovs_symptomsScreen: 'ovs_symptomsScreen',
  ovs_medicationsScreen: 'ovs_medicationsScreen',
  ovs_allergiesScreen: 'ovs_allergiesScreen',
  ovs_questionnaireStart: 'ovs_questionnaireStart',
  ovs_questionnaire: 'ovs_questionnaire',
  ovs_questionnaireFinish: 'ovs_questionnaireFinish',
  ovs_details: 'ovs_details',
  ovs_pharmacy: 'ovs_pharmacy',
  ovs_shipping: 'ovs_shipping',
  ovs_billing: 'ovs_billing',
  ovs_messaging: 'ovs_messaging',
  ovs_closingScreen: 'ovs_closingScreen',
  ovs_affiliateClosingScreen: 'ovs_affiliateClosingScreen',
  ovs_shippingDetails: 'ovs_shippingDetails',
  ovs_completedScreen: 'ovs_completedScreen',
  ovs_bodyMetricsScreen: 'ovs_bodyMetricsScreen',
  ovs: 'ovs'
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: APP_LANGUAGES.EN,
    lng: getItemFromLocalStorage(LANGUAGE) || APP_LANGUAGES.EN,
    backend: locizeOptions,
    saveMissing: true
  });

export default i18n;
