import { lazy, ComponentType, LazyExoticComponent } from 'react';

import { Urls } from 'shared/constants/urls';
import { Layouts } from 'shared/constants/layouts';

export type TLayout = {
  type: Layouts;
};

export interface IRouteItem {
  id: number | string;
  path: Urls;
  protected: boolean;
  layout?: TLayout;
  component: LazyExoticComponent<ComponentType<any>>;
}

export const ROUTES: IRouteItem[] = [
  {
    id: 1,
    path: Urls.DashBoard,
    protected: true,
    component: lazy(() => import('./DashBoardPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 2,
    path: Urls.LoginPage,
    protected: false,
    component: lazy(() => import('./LoginPage'))
  },
  {
    id: 7,
    path: Urls.OTPPage,
    protected: false,
    component: lazy(() => import('./OTPPage'))
  },
  {
    id: 7,
    path: Urls.RegistrationPage,
    protected: false,
    component: lazy(() => import('./RegistrationPage'))
  },
  // {
  //   id: 9,
  //   path: Urls.ProfilePage,
  //   protected: true,
  //   component: lazy(() => import('./ProfilePage')),
  //   layout: {
  //     type: Layouts.Base
  //   }
  // },
  {
    id: 11,
    path: Urls.Documents,
    protected: true,
    component: lazy(() => import('./DocumentsPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 12,
    path: Urls.GettingStarted,
    protected: false,
    component: lazy(() => import('./GettingStartedPage'))
  },
  {
    id: 13,
    path: Urls.OrdersHistory,
    protected: true,
    component: lazy(() => import('./OrdersHistoryPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 14,
    path: Urls.OrderPage,
    protected: true,
    component: lazy(() => import('./EcommerceOrderPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 15,
    path: Urls.VisitHistory,
    protected: true,
    component: lazy(() => import('./ServiceHistoryPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 16,
    path: Urls.VisitPage,
    protected: true,
    component: lazy(() => import('./VisitPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 17,
    path: Urls.Settings,
    protected: true,
    component: lazy(() => import('./SettingsPage')),
    layout: {
      type: Layouts.Base
    }
  },
  // {
  //   id: 15,
  //   path: Urls.TeleHealthPartner,
  //   protected: false,
  //   component: lazy(() => import('./TeleHealthPartnerPage')),
  //   layout: {
  //     type: Layouts.Base
  //   }
  // },
  {
    id: 18,
    path: Urls.Tasks,
    protected: true,
    component: lazy(() => import('./TasksPage')),
    layout: {
      type: Layouts.Base
    }
  },
  // {
  //   id: 17,
  //   path: Urls.Notifications,
  //   protected: true,
  //   component: lazy(() => import('./NotificationPage')),
  //   layout: {
  //     type: Layouts.Base
  //   }
  // },
  {
    id: 19,
    path: Urls.Messages,
    protected: true,
    component: lazy(() => import('./MessagesPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 20,
    path: Urls.OnlineVisit,
    protected: true,
    component: lazy(() => import('./OnlineVisit'))
  },
  {
    id: 21,
    path: Urls.StartVisit,
    protected: false,
    component: lazy(() => import('./OnlineVisit'))
  },
  {
    id: 22,
    path: Urls.ConfirmationOrderPage,
    protected: false,
    component: lazy(
      () => import('./OnlineVisit/screens/OrderConfirmationScreen')
    )
  },
  {
    id: 23,
    path: Urls.ValidatingVisitPage,
    protected: false,
    component: lazy(() => import('./OnlineVisit/screens/ValidatingVisitScreen'))
  },
  {
    id: 24,
    path: Urls.CompleteQuestionnaire,
    protected: false,
    component: lazy(() => import('./CompleteQuestionnaire'))
  },
  {
    id: 25,
    path: Urls.GoogleAuth,
    protected: false,
    component: lazy(() => import('./GoogleAuth'))
  },
  {
    id: 26,
    path: Urls.OrderPaymentUpdate,
    protected: false,
    component: lazy(() => import('./OrderPaymentUpdatePage'))
  },
  {
    id: 27,
    path: Urls.IframeComponent,
    protected: true,
    component: lazy(() => import('./IframeComponent'))
  },
  {
    id: 28,
    path: Urls.SubscriptionsHistory,
    protected: true,
    component: lazy(() => import('./SubscriptionsHistoryPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 29,
    path: Urls.SubscriptionPage,
    protected: true,
    component: lazy(() => import('./EcommerceSubscriptionPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 30,
    path: Urls.LabOrderPage,
    protected: true,
    component: lazy(() => import('./LabOrderPage')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 31,
    path: Urls.LabOrdersHistory,
    protected: true,
    component: lazy(() => import('./LabOrdersHistory')),
    layout: {
      type: Layouts.Base
    }
  },
  {
    id: 31,
    path: Urls.ShortUrl,
    protected: false,
    component: lazy(() => import('./ShortUrl'))
  }
];
