import {
  IAvailablePharmacies,
  IGetPotentialPharmacies,
  IPharmacyMapping,
  ISearchAvailablePharmacies,
  ISetPharmacy
} from 'shared/interfaces/pharmacy.interface';
import { http } from '../utils/axios';

class PharmacyService {
  private static instance: PharmacyService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): PharmacyService {
    if (!PharmacyService.instance) {
      PharmacyService.instance = new PharmacyService();
    }

    return PharmacyService.instance;
  }

  getAvailablePharmacies = async (payload: ISearchAvailablePharmacies) => {
    const { data } = await http<IAvailablePharmacies[]>({
      method: 'GET',
      url: '/pharmacy/actions/getAvailablePharmacies',
      params: payload
    });
    return data;
  };

  setPharmacy = async (payload: ISetPharmacy) => {
    const { data } = await http<any>({
      method: 'POST',
      url: '/pharmacy/actions/setPharmacy',
      data: payload
    });
    return data;
  };

  getPotentialPharmacies = async (payload: IGetPotentialPharmacies) => {
    const { data } = await http<IPharmacyMapping[]>({
      method: 'GET',
      url: '/pharmacy/actions/getPotentialPharmacies',
      params: payload
    });
    return data;
  };
}

const instance = PharmacyService.getInstance();

export default instance;
