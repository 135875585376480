import { takeLatest, put, call, select, take } from 'redux-saga/effects';
import { redirect } from 'react-router-dom';

import {
  TAction,
  TGetQIPayload,
  TAnswerQILocationPayload,
  TAnswerQIPayload,
  TNavigateBackPayload,
  TAttachPaymentIntentPayload,
  TGetConditionsSymptomsPayload,
  TUpdatePatientPayload
} from 'shared/types';
import {
  getItemFromLocalStorage,
  getTokenFromLocalStorage,
  setTokenToLocalStorage
} from 'shared/utils/local-storage';
import { USER_PROGRESS_DATA } from 'pages/OnlineVisit/constants';
import ProductService from 'shared/services/ProductService';
import PharmacyService from 'shared/services/PharmacyService';
import QuestionnaireService from 'shared/services/QuestionnaireService';
import OrdersService from 'shared/services/OrdersService';
import StripeService from 'shared/services/StripeService';
import {
  ICompleteQuestionnaireInstance,
  IQuestionnaireInstance
} from 'shared/interfaces/questionnaire-instance.interface';
import { IOrder } from 'shared/interfaces/order.interface';
import {
  setQuestionnaireInstance,
  getQuestionnaireInstanceByPatientAndQuestionnaire as getQuestionnaireInstanceByPatientAndQuestionnaireAction,
  fetchQuestionnaireInstance,
  setCurrentQuestionnaireInstance,
  answerQuestionnaireInstanceLocation as answerQuestionnaireInstanceLocationAction,
  updateAnswerQuestionnaireInstance,
  answerQuestionnaireInstance as answerQuestionnaireInstanceAction,
  navigateBack as navigateBackAction,
  updateQuestionnaireInstance as updateQuestionnaireInstanceAction,
  updateQuestionnaireInstances as updateQuestionnaireInstancesAction,
  setUpdatedQuestionnaireInstance,
  attachPaymentIntent as attachPaymentIntentAction,
  questionnaireInstancesSelector,
  getPaymentMethod as getPaymentMethodAction,
  setPaymentMethod,
  setLoadingForm,
  createStripeSetupIntentPreauthenticatedUser,
  setupStripeIntent,
  attachSymptoms as attachSymptomsAction,
  getConditionsAndSymptoms as getConditionsAndSymptomsAction,
  attachPatientMedications as attachPatientMedicationsAction,
  setPatientMedications,
  setPossibleSymtpoms,
  setPatientSymtpoms,
  attachMedicationAllergies as attachMedicationAllergiesAction,
  setMedicationAllergies,
  validateProspectPatientSession as validateProspectPatientSessionAction,
  setVisitLink,
  setValidationError,
  getAvailablePharmacies as getAvailablePharmaciesAction,
  setAvailablePharmacies,
  setPharmacy as setPharmacyAction,
  getPotentialPharmacies as getPotentialPharmaciesAction,
  setPotentialPharmacies,
  getCompleteQuestionnaire as getCompleteQuestionnaireAction,
  attachPaymentIntentSuccess,
  attachPaymentIntentFail,
  setCurrentOrder,
  getCurrentOnlineVisitSelector,
  setOVSAffiliate,
  getOVSAffiliate
} from './onlineVisit.redux';
import { IPatient } from 'shared/interfaces/patient.interface';
import { Urls } from 'shared/constants/urls';
import ProspectPatientSessionService from 'shared/services/ProspectPatientSessionService';
import { TProspectPatientSessionValidate } from 'shared/interfaces/prospect-patient-session.interface';
import {
  IAvailablePharmacies,
  IPharmacyMapping,
  ISearchAvailablePharmacies,
  ISetPharmacy
} from 'shared/interfaces/pharmacy.interface';
import { IGetPotentialPharmacies } from 'shared/interfaces/pharmacy.interface';
import { IPaymentMethod } from 'shared/interfaces/payment-method.interface';
import { openNotificationMessage } from 'shared/utils/NotificationMessages';
import { NotificationMessages, OrderStatuses } from 'shared/enums';
import { getCurrentOrder } from 'store/orders/orders.redux';
import PatientService from 'shared/services/PatientService';
import { IAffiliate } from 'shared/interfaces/affiliate.interface';
import AffiliateService from 'shared/services/AffiliateService';
import {
  isReglow,
  subscribeEvent
} from 'shared/components/ReglowFacebookPixel';

function* getQuestionnaireInstanceByPatientAndQuestionnaire({
  payload
}: TAction<{ questionnaire: string; patient: string }>) {
  try {
    if (payload) {
      const data: IQuestionnaireInstance = yield call(
        QuestionnaireService.getQuestionnaireInstanceByPatientAndQuestionnaire,
        payload
      );

      yield put(setQuestionnaireInstance(data));
    }
  } catch (error) {
    console.log(
      'getQuestionnaireInstanceByPatientAndQuestionnaire error',
      error
    );
  }
}

function* getQuestionnaireInstance({ payload }: TAction<TGetQIPayload>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      const result: IQuestionnaireInstance = yield call(
        QuestionnaireService.getQuestionnaireInstance,
        payload
      );

      yield put(setCurrentQuestionnaireInstance(result));
    }
  } catch (error: any) {
    console.log('getQuestionnaireInstance error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

export const checkSubmitOrderAndReglowSubscribe = (
  previousOrderState: IOrder,
  currentOrderState: IOrder
) => {
  if (
    previousOrderState.status === OrderStatuses.Started &&
    currentOrderState.status !== OrderStatuses.Started
  ) {
    if (isReglow()) {
      subscribeEvent();
    }
  }
};

function* answerQuestionnaireInstanceLocation({
  payload
}: TAction<TAnswerQILocationPayload>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      const result: IQuestionnaireInstance = yield call(
        QuestionnaireService.answerQuestionnaireInstanceLocation,
        payload
      );

      yield put(updateAnswerQuestionnaireInstance(result));

      if (result.valid) {
        yield redirect(Urls.DashBoard);

        const order: IOrder = yield select(getCurrentOnlineVisitSelector);
        if (order) {
          yield put(getCurrentOrder(order.id));
          yield take(setCurrentOrder);
          const currentOrder: IOrder = yield select(
            getCurrentOnlineVisitSelector
          );
          yield call(checkSubmitOrderAndReglowSubscribe, order, currentOrder);
        }
        const progress = getItemFromLocalStorage(
          USER_PROGRESS_DATA.USER_PROGRESS
        );
        if (!progress) {
          yield openNotificationMessage({
            type: NotificationMessages.Success,
            title: 'Success',
            message: 'Questionnaire Now Complete!'
          });
        }
      }
    }
  } catch (error: any) {
    console.log('answerQuestionnaireInstanceLocation error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* answerQuestionnaireInstance({ payload }: TAction<TAnswerQIPayload>) {
  try {
    if (payload) {
      const result: IQuestionnaireInstance = yield call(
        QuestionnaireService.answerQuestionnaireInstance,
        payload
      );

      yield put(updateAnswerQuestionnaireInstance(result));

      if (result.valid) {
        yield redirect(Urls.DashBoard);

        const order: IOrder = yield select(getCurrentOnlineVisitSelector);
        if (order) {
          yield put(getCurrentOrder(order.id));
          yield take(setCurrentOrder);
          const currentOrder: IOrder = yield select(
            getCurrentOnlineVisitSelector
          );
          yield call(checkSubmitOrderAndReglowSubscribe, order, currentOrder);
        }
        const progress = getItemFromLocalStorage(
          USER_PROGRESS_DATA.USER_PROGRESS
        );
        if (!progress) {
          yield openNotificationMessage({
            type: NotificationMessages.Success,
            title: 'Success',
            message: 'Questionnaire Now Complete!'
          });
        }
      }
    }
  } catch (error: any) {
    console.log('answerQuestionnaireInstance error', error);
  }
}

function* navigateBack({ payload }: TAction<TNavigateBackPayload>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      const result: IQuestionnaireInstance = yield call(
        QuestionnaireService.navigateBack,
        payload
      );

      yield put(updateAnswerQuestionnaireInstance(result));
    }
  } catch (error: any) {
    console.log('navigateBack error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* updateQuestionnaireInstance({ payload }: any) {
  try {
    const result: IQuestionnaireInstance = yield call(
      QuestionnaireService.updateQuestionnaireInstance,
      payload
    );

    yield put(setUpdatedQuestionnaireInstance(result));

    if (result.valid) {
      const token = getTokenFromLocalStorage();
      if (token) {
        const order: IOrder = yield select(getCurrentOnlineVisitSelector);
        if (order) {
          yield put(getCurrentOrder(order.id));
          yield take(setCurrentOrder);
          const currentOrder: IOrder = yield select(
            getCurrentOnlineVisitSelector
          );
          yield call(checkSubmitOrderAndReglowSubscribe, order, currentOrder);
        }
      }
      const progress = getItemFromLocalStorage(
        USER_PROGRESS_DATA.USER_PROGRESS
      );
      if (!progress) {
        yield openNotificationMessage({
          type: NotificationMessages.Success,
          title: 'Success',
          message: 'Questionnaire Now Complete!'
        });
      }
    }
  } catch (error: any) {
    console.log('updateQuestionnaireInstance error', error);
  }
}

function* updateQuestionnaireInstances({ payload }: any) {
  try {
    for (const qi of payload) {
      yield put(updateQuestionnaireInstanceAction(qi));
      yield take(setUpdatedQuestionnaireInstance);
    }

    const questionnaireInstances: IQuestionnaireInstance[] = yield select(
      questionnaireInstancesSelector
    );

    const token = getItemFromLocalStorage(USER_PROGRESS_DATA.TOKEN);

    yield put(
      fetchQuestionnaireInstance({ id: questionnaireInstances[0].id, token })
    );
  } catch (error: any) {
    console.log('updateQuestionnaireInstances error', error);
  }
}

function* attachPaymentIntent({
  payload
}: TAction<TAttachPaymentIntentPayload>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      const order: IOrder = yield select(getCurrentOnlineVisitSelector);
      const updatedOrder: IOrder = yield call(
        OrdersService.attachPaymentIntent,
        payload
      );

      yield put(setCurrentOrder(updatedOrder));
      yield put(attachPaymentIntentSuccess());

      const currentOrder: IOrder = yield select(getCurrentOnlineVisitSelector);
      yield call(checkSubmitOrderAndReglowSubscribe, order, currentOrder);
    }
  } catch (error: any) {
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      message: error?.response?.data.message
    });
    yield put(attachPaymentIntentFail());
    console.log('attachPaymentIntent error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* getPaymentMethod({ payload }: TAction<string>) {
  try {
    yield put(setLoadingForm(true));
    const result: IPaymentMethod = yield call(
      StripeService.getPaymentMethod,
      payload
    );

    yield put(setPaymentMethod(result));
  } catch (error: any) {
    console.log('getPaymentMethod error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* createStripeSetupIntent() {
  try {
    yield put(setLoadingForm(true));
    // TODO write type
    const result: any = yield call(
      StripeService.createStripeSetupIntentPreauthenticatedUser
    );

    yield put(setupStripeIntent(result));
  } catch (error: any) {
    console.log('createStripeSetupIntent error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* attachSymptoms({
  payload
}: TAction<{
  symptomsId: string[];
  newSymptoms: string[];
  orderId: string | number;
  token: string;
}>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      if (payload.newSymptoms.length) {
        const newCreatedSymptoms: string[] = yield call(
          OrdersService.createSymptoms,
          {
            token: payload.token,
            symptoms: payload.newSymptoms
          }
        );
        payload.symptomsId = payload.symptomsId.concat(newCreatedSymptoms);
      }
      const response: IOrder = yield call(
        OrdersService.attachSymptoms,
        payload
      );
      yield put(setPatientSymtpoms(response.symptoms));
    }
  } catch (error) {
    console.log('attachSymptoms error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* getConditionsAndSymptoms({
  payload
}: TAction<TGetConditionsSymptomsPayload>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      const response: { conditions: any[]; symptoms: any[] } = yield call(
        ProductService.getConditionsAndSymptoms,
        payload
      );
      yield put(setPossibleSymtpoms(response.symptoms));
    }
  } catch (error) {
    console.log('getConditionsAndSymptoms error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* attachPatientMedications({
  payload
}: TAction<TUpdatePatientPayload>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      const response: IPatient = yield call(
        PatientService.editPatient,
        payload
      );
      yield put(setPatientMedications(response.patientMedications));
    }
  } catch (error) {
    console.log('attachPatientMedications error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* attachMedicationAllergies({
  payload
}: TAction<TUpdatePatientPayload>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      const response: IPatient = yield call(
        PatientService.editPatient,
        payload
      );
      yield put(setMedicationAllergies(response));
    }
  } catch (error) {
    console.log('attachMedicationAllergies error', error);
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* validateProspectPatientSession({
  payload
}: TAction<TProspectPatientSessionValidate>) {
  try {
    if (payload) {
      const result: { link: string } = yield call(
        ProspectPatientSessionService.validateProspectPatientSession,
        payload
      );

      yield put(setVisitLink(result));
    }
  } catch (error: any) {
    console.error('validateProspectPatientSession error', error);
    yield put(setValidationError(error));
  }
}

function* getAvailablePharmacies({
  payload
}: TAction<ISearchAvailablePharmacies>) {
  try {
    if (payload && (payload.zipcode || payload.city || payload.state)) {
      yield put(setLoadingForm(true));
      const result: IAvailablePharmacies[] = yield call(
        PharmacyService.getAvailablePharmacies,
        payload
      );
      yield put(setAvailablePharmacies(result));
    } else {
      yield put(setAvailablePharmacies([]));
    }
  } catch (error) {
    console.log('getAvailablePharmacies error', error);
    yield put(setAvailablePharmacies([] as IAvailablePharmacies[]));
  } finally {
    yield put(setLoadingForm(false));
  }
}

function* setPharmacy({ payload }: TAction<ISetPharmacy>) {
  try {
    if (payload) {
      yield call(PharmacyService.setPharmacy, payload);
    }
  } catch (error) {
    console.log('setPharmacy error', error);
  }
}

function* getPotentialPharmacies({
  payload
}: TAction<IGetPotentialPharmacies>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      const result: IPharmacyMapping[] = yield call(
        PharmacyService.getPotentialPharmacies,
        payload
      );
      yield put(setPotentialPharmacies(result));
    }
  } catch (error) {
    console.log('setPharmacy error', error);
    yield put(setLoadingForm(false));
  }
}

function* getCompleteQuestionnaire({
  payload
}: TAction<{ inviteToken: string }>) {
  try {
    if (payload) {
      yield put(setLoadingForm(true));
      const result: {
        questionnaireInstance: ICompleteQuestionnaireInstance;
        token: string;
      } = yield call(
        QuestionnaireService.verifyInviteToken,
        payload?.inviteToken
      );
      yield put(setQuestionnaireInstance(result.questionnaireInstance));
      if (result.token) {
        setTokenToLocalStorage(result.token);
      }
    }
  } catch (error) {
    yield put(setLoadingForm(false));
    console.log('verifyInviteToken error', error);
  }
}

function* getOVSAffiliateSaga({ payload }: TAction<{ affiliateId: string }>) {
  try {
    if (payload) {
      const result: IAffiliate = yield call(
        {
          context: AffiliateService,
          fn: AffiliateService.getAffiliate
        },
        payload
      );

      yield put(setOVSAffiliate(result));
    }
  } catch (error) {
    console.log('getOVSAffiliateSaga error', error);
  }
}

function* OnlineVisit() {
  yield takeLatest(
    getQuestionnaireInstanceByPatientAndQuestionnaireAction.type,
    getQuestionnaireInstanceByPatientAndQuestionnaire
  );
  yield takeLatest(fetchQuestionnaireInstance.type, getQuestionnaireInstance);
  yield takeLatest(
    answerQuestionnaireInstanceLocationAction.type,
    answerQuestionnaireInstanceLocation
  );
  yield takeLatest(
    answerQuestionnaireInstanceAction.type,
    answerQuestionnaireInstance
  );
  yield takeLatest(navigateBackAction.type, navigateBack);
  yield takeLatest(
    updateQuestionnaireInstancesAction.type,
    updateQuestionnaireInstances
  );
  yield takeLatest(
    updateQuestionnaireInstanceAction.type,
    updateQuestionnaireInstance
  );
  yield takeLatest(attachPaymentIntentAction.type, attachPaymentIntent);
  yield takeLatest(getPaymentMethodAction.type, getPaymentMethod);
  yield takeLatest(
    createStripeSetupIntentPreauthenticatedUser.type,
    createStripeSetupIntent
  );
  yield takeLatest(attachSymptomsAction.type, attachSymptoms);
  yield takeLatest(
    getConditionsAndSymptomsAction.type,
    getConditionsAndSymptoms
  );
  yield takeLatest(
    attachPatientMedicationsAction.type,
    attachPatientMedications
  );
  yield takeLatest(
    attachMedicationAllergiesAction.type,
    attachMedicationAllergies
  );
  yield takeLatest(
    validateProspectPatientSessionAction.type,
    validateProspectPatientSession
  );
  yield takeLatest(getAvailablePharmaciesAction.type, getAvailablePharmacies);
  yield takeLatest(setPharmacyAction.type, setPharmacy);
  yield takeLatest(getPotentialPharmaciesAction.type, getPotentialPharmacies);
  yield takeLatest(
    getCompleteQuestionnaireAction.type,
    getCompleteQuestionnaire
  );
  yield takeLatest(getOVSAffiliate.type, getOVSAffiliateSaga);
}

export default OnlineVisit;
