import tinycolor from 'tinycolor2';

import { VariableCategories } from 'shared/enums';
import { Colors } from 'styles/enums';
import { IVariable } from 'shared/interfaces/affiliate.interface';

const WHITE = '#ffffff';
const BLACK = '#000000';

interface IShadeSettings {
  [variableName: string]: {
    lighten: boolean;
    step: number;
  };
}

const primaryColors = [
  Colors.ThemeLight,
  Colors.ThemeDark,
  Colors.ThemeUltraDark
];
const externalColors = [Colors.ThemeExternalLight, Colors.ThemeExternalDark];
const allThemeColors = [...primaryColors, ...externalColors];

const shadeSettings: IShadeSettings = {
  [Colors.ThemeLight]: {
    lighten: true,
    step: 30
  },
  [Colors.ThemeDark]: {
    lighten: false,
    step: 15
  },
  [Colors.ThemeUltraDark]: {
    lighten: false,
    step: 30
  },
  [Colors.ThemeExternalLight]: {
    lighten: true,
    step: 30
  },
  [Colors.ThemeExternalDark]: {
    lighten: false,
    step: 30
  }
};

const getNotWhite = (color: string, step: number): string => {
  let stepProcess = step;
  let value = tinycolor(color).lighten(step).toHexString();

  while (value === WHITE && stepProcess > 0) {
    stepProcess -= 5;
    value = tinycolor(color).lighten(stepProcess).toHexString();
  }

  return value;
};

const getNotBlack = (color: string, step: number): string => {
  let stepProcess = step;
  let value = tinycolor(color).darken(stepProcess).toHexString();

  while (value === BLACK && stepProcess > 0) {
    stepProcess -= 5;
    value = tinycolor(color).darken(stepProcess).toHexString();
  }
  return value;
};

const getShade = (color: string, variableName: string): string => {
  const tinycolorValue = tinycolor(color);
  let value = '';
  const step = shadeSettings[variableName].step;
  if (shadeSettings[variableName].lighten) {
    value = tinycolorValue.lighten(step).toHexString();
    if (value === WHITE) {
      value = getNotWhite(color, step);
    }
  }

  if (!shadeSettings[variableName].lighten) {
    value = tinycolorValue.darken(step).toHexString();
    if (value === BLACK) {
      value = getNotBlack(color, step);
    }
  }

  return value;
};

export const generateShades = (colors: IVariable[]) => {
  const primaryColor = colors.find(
    (color) => color.variableName === Colors.ThemePrimary
  );

  const externalPrimary = colors.find(
    (color) => color.variableName === Colors.ThemeExternalPrimary
  );

  const newColors: IVariable[] = [];

  allThemeColors.forEach((colorName: Colors) => {
    const colorData = colors.find((color) => color.variableName === colorName);

    if (colorData && !!colorData.variableValue) return;

    let generatedShade = '';

    if (primaryColors.includes(colorName) && primaryColor) {
      generatedShade = getShade(primaryColor.variableValue, colorName);
    }
    if (externalColors.includes(colorName) && externalPrimary) {
      generatedShade = getShade(externalPrimary.variableValue, colorName);
    }
    newColors.push({
      category: VariableCategories.BrandColors,
      variableName: colorName,
      variableValue: generatedShade
    });
  });

  return [...colors, ...newColors];
};
