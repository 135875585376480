import { takeLatest, put, call } from 'redux-saga/effects';

import { IChannel } from 'shared/interfaces/channel.interface';
import ChannelService from 'shared/services/ChannelService';
import {
  getChannels,
  setChannels,
  getChannelsFail,
  updateChannelData
} from './channel.redux';

function* getAllChannels() {
  try {
    const result: IChannel[] = yield call(ChannelService.getChannels);

    yield put(setChannels(result));
  } catch (error) {
    yield put(getChannelsFail());
    console.log('getChannelsFail error', error);
  }
}

function* updateOneChannelById(payload: any) {
  try {
    yield call(ChannelService.updateChannelById, payload);
  } catch (error) {
    yield put(getChannelsFail());
    console.log('updateOneChannelById error', error);
  }
}

function* Channel() {
  yield takeLatest(getChannels.type, getAllChannels);
  yield takeLatest(updateChannelData.type, updateOneChannelById);
}

export default Channel;
