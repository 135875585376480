import React, {
  FC,
  ReactNode,
  PropsWithChildren,
  useEffect,
  useState,
  ReactElement
} from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, Popover, Tooltip } from 'antd';

import { themePrimary } from 'shared/utils/cssUtility';
import { classNames } from 'shared/utils/class-names';
import { useMobile } from 'shared/utils/hooks/useMobile';
import { useMidScreen } from 'shared/utils/hooks/useMidScreen';
import { CloseIcon } from 'shared/components/Icons';
import SidebarTabSvg from './SidebarTabSvg';
import Submenu from 'shared/components/Submenu';
import { NotificationComponent } from '../Notifications/NotificationComponent';

import classes from './styles.module.scss';

export interface TIconCardInfo {
  icon?: ReactNode;
  iconLarge?: ReactNode;
  text?: ReactElement;
  count?: number;
  highlight?: boolean;
  reversed?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  visible: boolean;
}
export interface TContent {
  header?: TIconCardInfo[];
  bodyTop?: TIconCardInfo[];
  bodyBottom?: TIconCardInfo[];
  footer?: TIconCardInfo[];
}
interface ICard extends TIconCardInfo {
  key: string;
  className?: string;
  isMobile?: boolean;
}
interface ISidebar {
  side?: 'left' | 'right';
  showContent: boolean;
  setShowContent: (state: boolean) => void;
  content?: TContent;
  displayContent?: boolean;
}

const Card: FC<PropsWithChildren<ICard>> = ({
  icon,
  iconLarge,
  text,
  count,
  highlight,
  reversed,
  className,
  onClick,
  isMobile
}) => {
  const allClasses = classNames(
    className ? className : '',
    reversed ? classes.display_row_reverse : classes.display_row,
    highlight ? classNames(classes.highlight, 'active-page') : ''
  );

  const button = (
    <div className={allClasses} onClick={onClick}>
      {iconLarge ? iconLarge : icon}
      {count ? (
        <Badge
          count={count}
          overflowCount={9}
          color={themePrimary}
          offset={[20, 10]}
        >
          {text}
        </Badge>
      ) : (
        text
      )}
    </div>
  );

  return text && text.key === 'notifications' ? (
    <Popover
      content={<NotificationComponent dashboard={false} />}
      trigger="click"
      overlayInnerStyle={{ borderRadius: '8px' }}
      placement={isMobile ? 'bottomLeft' : 'right'}
      overlayClassName={classes.popoverNotification}
    >
      {button}
    </Popover>
  ) : (
    button
  );
};
const IconButton: FC<PropsWithChildren<ICard>> = ({
  icon,
  count,
  highlight,
  className,
  onClick,
  text
}) => {
  const allClasses = classNames(
    className ? className : '',
    highlight ? classNames(classes.highlight, 'active-page') : ''
  );
  const button = (
    <Tooltip placement={'right'} title={text?.props.children}>
      <button className={allClasses} onClick={onClick}>
        {count ? (
          <Badge
            count={count}
            size={'small'}
            overflowCount={9}
            color={themePrimary}
            offset={[5, -1]}
          >
            {icon}
          </Badge>
        ) : (
          icon
        )}
      </button>
    </Tooltip>
  );
  return text && text.key === 'notifications' ? (
    <Popover
      content={<NotificationComponent dashboard={false} />}
      trigger="click"
      overlayInnerStyle={{ borderRadius: '8px' }}
      placement="right"
    >
      {button}
    </Popover>
  ) : (
    button
  );
};

export const Sidebar: FC<PropsWithChildren<ISidebar>> = ({
  side = 'left',
  content,
  showContent,
  setShowContent
}) => {
  const [sidebarHeight, setSidebarHeight] = useState(window.innerHeight);

  const onClose = () => {
    setShowContent(false);
  };
  const onOpen = () => {
    setShowContent(true);
  };
  const isMobile = useMobile();
  const isMid = useMidScreen();
  const { pathname } = useLocation();

  useEffect(() => {
    const isMidScreen = () => {
      if ((isMid && pathname === '/messages') || isMobile) {
        setShowContent(false);
        return;
      }
      setShowContent(true);
      return;
    };
    isMidScreen();
  }, [isMid]);

  useEffect(() => {
    const updateSidebarHeight = () => {
      setSidebarHeight(window.innerHeight);
    };
    window.addEventListener('resize', updateSidebarHeight);
    return () => {
      window.removeEventListener('resize', updateSidebarHeight);
    };
  }, []);

  return (
    <div className={classNames(classes.wrapper, classes[`wrapper-${side}`])}>
      <div
        className={classNames(
          classes.content,
          classes[`content-${side}`],
          classes['content-transition'],
          {
            [classes[`content-open-${side}`]]: showContent
          }
        )}
        style={{ minHeight: sidebarHeight }}
      >
        {pathname === '/messages' && !isMobile && (
          <div
            className={classNames(classes.content, classes['content-right'], {
              [classes[`content-open-${side}`]]: showContent
            })}
            style={{ marginLeft: 279, zIndex: 0 }}
          >
            <Submenu />
          </div>
        )}

        {showContent ? (
          <div className={classes.navTabButton} onClick={onClose}>
            <SidebarTabSvg arrowDirection="left" />
          </div>
        ) : (
          <div className={classes.navTabButton} onClick={onOpen}>
            <SidebarTabSvg arrowDirection="right" />
          </div>
        )}
        <div className={classes.closeIcon}>
          <span onClick={onClose}>
            <CloseIcon />
          </span>
        </div>
        <div className={classes.sidebar__header}>
          {content &&
            content.header &&
            content.header.map((item, i) => {
              if (item.visible) {
                return (
                  <Card
                    {...item}
                    className={classNames(classes.content__top, 'sidebar-item')}
                    key={`sb-hc${i}`}
                  />
                );
              }
            })}
          <div className={classes.divider} />
          {content &&
            content.bodyTop &&
            content.bodyTop.map((item, i) => {
              if (item.visible) {
                return (
                  <Card
                    {...item}
                    className={classNames(classes.content__top, 'sidebar-item')}
                    key={`sb-btc${i}`}
                    isMobile={isMobile}
                  />
                );
              }
            })}
        </div>
        <div className={classes.sidebar__footer}>
          {content &&
            content.bodyBottom &&
            content.bodyBottom.map((item, i) => {
              if (item.visible) {
                return (
                  <Card
                    {...item}
                    className={classes.content__top}
                    key={`sb-bbc${i}`}
                  />
                );
              }
            })}
          {content &&
            content.footer &&
            content.footer.map((item, i) => {
              if (item.visible) {
                return (
                  <Card
                    {...item}
                    className={classes.content__bottom}
                    key={`sb-fc${i}`}
                  />
                );
              }
            })}
        </div>
      </div>
      <div
        className={`${classes.sidebar} ${
          showContent ? classes['sidebar__hide'] : ''
        }`}
      >
        <div className={classes.sidebar__header}>
          {content?.header &&
            content.header.map((item, i) => {
              if (item.visible) {
                return (
                  <IconButton
                    className={classNames(classes.sidebar__btn, 'sidebar-item')}
                    {...item}
                    key={`sb-h-${i}`}
                  />
                );
              }
            })}
          <div className={classes.divider} />
          {content?.bodyTop &&
            content.bodyTop.map((item, i) => {
              if (item.visible) {
                return (
                  <IconButton
                    className={classNames(classes.sidebar__btn, 'sidebar-item')}
                    {...item}
                    key={`sb-bt-${i}`}
                  />
                );
              }
            })}
        </div>
        <div className={classes.sidebar__footer}>
          {content?.bodyBottom &&
            content.bodyBottom.map((item, i) => {
              if (item.visible) {
                return (
                  <IconButton
                    className={classNames(classes.sidebar__btn, 'sidebar-item')}
                    {...item}
                    key={`sb-ih-${i}`}
                  />
                );
              }
            })}
          {content?.footer &&
            content.footer.map((item, i) => {
              if (item.visible) {
                return (
                  <IconButton
                    className={classNames(classes.sidebar__btn, 'sidebar-item')}
                    {...item}
                    key={`sb-if-${i}`}
                  />
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};
