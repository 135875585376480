import { createSlice, createAction } from '@reduxjs/toolkit';
import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

// Actions
export const getPaymentMethods = createAction(
  'billing/GET_PAYMENT_METHODS',
  (payload) => ({ payload })
);

export const createStripeSetupIntent = createAction(
  'billing/CREATE_STRIPE_SETUP_INTENT'
);

export const addPaymentMethod = createAction(
  'billing/ADD_PAYMENT_METHOD',
  (payload) => ({ payload })
);

export const removePaymentMethod = createAction(
  'billing/REMOVE_PAYMENT_METHOD',
  (payload) => ({ payload })
);

export const setDefaultPaymentMethod = createAction(
  'billing/SET_DEFAULT_PAYMENT_METHOD',
  (payload) => ({ payload })
);

const billingSlice = createSlice({
  name: 'billing',
  initialState: initialStore.billing,
  reducers: {
    setStripeSetupIntent: (state, { payload }) => {
      state.setupIntent = payload.client_secret;
    },
    setPaymentMethods: (state, { payload }) => {
      state.availablePaymentMethods = payload;
      state.isLoading = false;
    },
    removePaymentMethodData: (state, { payload }) => {
      const deletedItem = state.availablePaymentMethods.findIndex(
        (paymentMethod) => paymentMethod.paymentId === payload.paymentMethodId
      );
      state.availablePaymentMethods.splice(deletedItem, 1);
    },
    addPaymentMethodData: (state, { payload }) => {
      state.availablePaymentMethods.unshift(payload);
    },
    setDefaultPaymentMethodData: (state, { payload }) => {
      const previousDefault = state.availablePaymentMethods.find(
        (paymentMethod) =>
          paymentMethod.paymentId === state.defaultPaymentMethod
      );
      if (previousDefault) {
        previousDefault.isDefault = false;
      }
      const newDefaultValue = state.availablePaymentMethods.find(
        (paymentMethod) => paymentMethod.paymentId === payload.paymentId
      );
      if (newDefaultValue) {
        newDefaultValue.isDefault = true;
      }
      state.defaultPaymentMethod = payload.paymentId;
    }
  }
});

export const {
  setStripeSetupIntent,
  setPaymentMethods,
  removePaymentMethodData,
  addPaymentMethodData,
  setDefaultPaymentMethodData
} = billingSlice.actions;

export const { reducer } = billingSlice;

// Selectors
export const getSetupIntentSelector = (state: IState) =>
  state && state.billing.setupIntent;

export const getavailablePaymentMethodsSelector = (state: IState) =>
  state && state.billing.availablePaymentMethods;

export const getPaymentMethodsLoadingSelector = (state: IState) =>
  state && state.billing.isLoading;

export const getdefaultPaymentMethodSelector = (state: IState) =>
  state && state.billing.defaultPaymentMethod;
