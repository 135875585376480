import { IAffiliateStylesConfig, IframeComponents } from 'shared/enums';
import { BorderRadiuses, Colors } from 'styles/enums';
import { generateShades } from 'shared/utils/generateShades';
import {
  IEmbeddedStyles,
  IFrontendConfiguration,
  IVariable
} from 'shared/interfaces/affiliate.interface';
import { themeBrandPrimary } from 'shared/utils/cssUtility';

export const parseColorsAndFonts = (item: IEmbeddedStyles) => {
  const colors: { [key: string]: string } = {};
  const fontFamilies: { [key: string]: string } = {};
  for (const color of item.colors as IVariable[]) {
    colors[color.variableName] = color.variableValue;
  }
  for (const font of item.fontFamilies as IVariable[]) {
    fontFamilies[font.variableName] = font.variableValue;
  }
  return { colors, fontFamilies };
};

export const getAffiliateConfig = (
  affiliateFrontendConfig: IFrontendConfiguration
) => {
  const affiliateConfig: IFrontendConfiguration = {
    ...affiliateFrontendConfig
  };
  affiliateConfig.embeddedStyles = affiliateFrontendConfig.embeddedStyles.map(
    (element) => {
      const elementCopy: IEmbeddedStyles = { ...element };
      elementCopy.colors = generateShades(element.colors as IVariable[]);
      const colorsAndFontsConfig = parseColorsAndFonts(elementCopy);
      elementCopy.colors = colorsAndFontsConfig.colors;
      elementCopy.fontFamilies = colorsAndFontsConfig.fontFamilies;
      return elementCopy;
    }
  );
  return affiliateConfig;
};

export const getComponentConfig = (
  embeddedStyles: IEmbeddedStyles[],
  componentId: IframeComponents,
  affiliateStylesConfig: {
    [key in IframeComponents]?: IAffiliateStylesConfig;
  }
) => {
  if (!embeddedStyles?.length) return null;
  let component;

  if (componentId === IframeComponents.Global) {
    component = embeddedStyles.find(
      (item) => item.elementType === (componentId as string)
    );
  } else {
    component = embeddedStyles.find(
      (item) => item.elementIdentifier === componentId
    );
  }

  if (component) {
    affiliateStylesConfig[componentId] = {
      ...affiliateStylesConfig[componentId],
      variables: {
        ...component.colors,
        ...component.fontFamilies,
        [BorderRadiuses.ButtonsBorderRadius]: component.buttonsBorderRadius,
        [Colors.ThemeInputsBorderHover]: component.inputsBorderColor,
        [Colors.ThemeBrandPrimary]:
          Colors.ThemeBrandPrimary in component.colors
            ? component?.colors[Colors.ThemeBrandPrimary]
            : themeBrandPrimary
      } as {
        [key: string]: string;
      }
    };

    affiliateStylesConfig[componentId] = {
      ...affiliateStylesConfig[componentId],
      styles: `${component.styleValue}`,
      dashboardImage: component?.dashboardImage,
      completedScreenImage: component?.completedScreenImage
    };
  }
};
