import { Colors, BorderRadiuses } from 'styles/enums';
import cssValue from 'shared/utils/cssUtility';
import { checkIsOnlineVisit } from 'shared/utils/checkIsOnlineVisit';

const getTokens = () => {
  const isOnlineVisit = checkIsOnlineVisit();
  if (isOnlineVisit) {
    return {
      colorPrimary: cssValue(Colors.ThemeExternalPrimary),
      colorPrimaryText: cssValue(Colors.ThemeTextPrimary),
      colorText: cssValue(Colors.ThemeTextPrimary),
      colorTextBase: cssValue(Colors.ThemeTextPrimary),
      colorLinkActive: cssValue(Colors.ThemeExternalDark),
      colorLinkHover: cssValue(Colors.ThemeExternalDark)
    };
  } else {
    return {
      colorPrimary: cssValue(Colors.ThemePrimary),
      colorPrimaryText: cssValue(Colors.ThemeTextPrimary),
      colorText: cssValue(Colors.ThemeTextPrimary),
      colorTextBase: cssValue(Colors.ThemeTextPrimary),
      colorLinkActive: cssValue(Colors.ThemeDark),
      colorLinkHover: cssValue(Colors.ThemeDark)
    };
  }
};

export const baseTheme = {
  token: getTokens(),
  components: {
    Button: {
      borderRadius: +cssValue(BorderRadiuses.ButtonsBorderRadius)
    },
    Input: {
      activeShadow: 'transparent'
    },
    Select: {},
    DatePicker: {
      activeShadow: 'transparent'
    }
  }
};

export const getAffiliateTheme = (affiliateVariables: {
  [key: string]: string;
}) => {
  const isOnlineVisit = checkIsOnlineVisit();
  const colorPrimary =
    (isOnlineVisit
      ? affiliateVariables[Colors.ThemeExternalPrimary]
      : affiliateVariables[Colors.ThemePrimary]) ||
    baseTheme.token.colorPrimary;
  const colorPrimaryText =
    affiliateVariables[Colors.ThemeTextPrimary] ||
    baseTheme.token.colorPrimaryText;
  const colorLinkActive =
    (isOnlineVisit
      ? affiliateVariables[Colors.ThemeExternalDark]
      : affiliateVariables[Colors.ThemeDark]) ||
    baseTheme.token.colorLinkActive;
  const colorLinkHover =
    (isOnlineVisit
      ? affiliateVariables[Colors.ThemeExternalDark]
      : affiliateVariables[Colors.ThemeDark]) || baseTheme.token.colorLinkHover;
  const buttonsBorderRadius =
    affiliateVariables[BorderRadiuses.ButtonsBorderRadius] ||
    baseTheme.components.Button.borderRadius;

  const inputsBorderColor =
    affiliateVariables[Colors.ThemeInputsBorderHover] ||
    (isOnlineVisit
      ? affiliateVariables[Colors.ThemeExternalPrimary]
      : affiliateVariables[Colors.ThemePrimary]) ||
    baseTheme.token.colorPrimary;

  return {
    token: {
      colorPrimary,
      colorPrimaryText,
      colorText: colorPrimaryText,
      colorTextBase: colorPrimaryText,
      colorLinkActive,
      colorLinkHover
    },
    components: {
      ...baseTheme.components,
      Button: {
        ...baseTheme.components.Button,
        borderRadius: +buttonsBorderRadius
      },
      Input: {
        ...baseTheme.components.Input,
        colorPrimary: inputsBorderColor,
        hoverBorderColor: inputsBorderColor,
        colorPrimaryActive: inputsBorderColor
      },
      Select: {
        ...baseTheme.components.Select,
        colorPrimary: inputsBorderColor,
        colorPrimaryHover: inputsBorderColor,
        optionSelectedBg: affiliateVariables[Colors.ThemeBrandPrimary]
      },
      DatePicker: {
        ...baseTheme.components.DatePicker,
        colorPrimary: inputsBorderColor,
        hoverBorderColor: inputsBorderColor
      },
      Radio: {
        colorPrimary: affiliateVariables[Colors.ThemeBrandPrimary]
      }
    }
  };
};
