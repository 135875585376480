import React, { ReactNode } from 'react';
import { Client } from '@twilio/conversations';

import { useTwilioClient } from 'shared/utils/hooks/twilioConversations';

export const TwilioClientContext = React.createContext<Client | null>(null);

const TwilioProvider = ({ children }: { children: ReactNode }) => {
  const twilioClient = useTwilioClient();

  return (
    <TwilioClientContext.Provider value={twilioClient}>
      {children}
    </TwilioClientContext.Provider>
  );
};

export default TwilioProvider;
