import { FC, Suspense } from 'react';

import { classNames } from 'shared/utils/class-names';
import { MenuIcon } from 'shared/components/Icons';
import { Navigation } from 'shared/components/Sidebar/presets/Navigation';
import Avatar from './Avatar';
import Chat from './Chat';
import { SelectLanguage } from 'shared/components/SelectLanguage';

import classes from './styles.module.scss';

type THeaderProps = {
  showSideBar: boolean;
  setShowSideBar: (state: boolean) => void;
  isIframe?: boolean;
  isMobile: boolean;
};

const Header: FC<THeaderProps> = ({
  showSideBar,
  setShowSideBar,
  isIframe = false,
  isMobile
}) => (
  <>
    <div
      className={classNames(classes.header, {
        [classes.inIframe]: isIframe && !isMobile
      })}
    >
      <div className={classes.menuIcons} onClick={() => setShowSideBar(true)}>
        <MenuIcon />
      </div>
      {!isIframe && (
        <div className={classes.header__icons}>
          <SelectLanguage />
          <Chat />
          <Suspense>
            <Avatar />
          </Suspense>
        </div>
      )}
      <Suspense>
        <Navigation showContent={showSideBar} setShowContent={setShowSideBar} />
      </Suspense>
    </div>
  </>
);

export default Header;
