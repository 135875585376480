import classes from './styles.module.scss';
import { NotificationIcon } from '../Icons';
import { Text } from '../Text';
import CancelIcon from '../Icons/Cancel';
import dayjs from 'shared/utils/dayjsConfig';
import { baseGrey, themePrimary } from 'shared/utils/cssUtility';

interface NotificationProps {
  id: string;
  title: string;
  description: string;
  action?: string;
  createdAt: string;
  dashboard?: boolean;
  closeNotification: (id: string) => void;
}

const NotificationCard: React.FC<NotificationProps> = (props) => {
  return (
    <>
      <div className={classes['notification-card']}>
        <div>
          <NotificationIcon
            color={props.dashboard ? themePrimary : baseGrey}
            width={15}
            height={19}
          />
        </div>

        <div className={classes['middle-content']}>
          <Text
            type="sf-body-emphasized"
            color={props.dashboard ? 'text-primary' : 'default'}
          >
            {props.title}
          </Text>
          <Text
            type="sf-body-condensed"
            color={props.dashboard ? 'text-primary' : 'default'}
          >
            {props.description}
          </Text>
          <Text color={'theme-dark'} type="sf-body-emphasized">
            {props.action}
          </Text>
        </div>
        <div className={classes['right-side']}>
          <div
            onClick={() => props.closeNotification(props.id)}
            style={{ cursor: 'pointer' }}
          >
            <CancelIcon width={12.6} height={12.6} color={baseGrey} />
          </div>
          <Text
            type="sf-caption"
            color={props.dashboard ? 'text-primary' : 'default'}
            className={classes['notification-body__timeAgo']}
          >
            {dayjs(props.createdAt).fromNow()}
          </Text>
        </div>
      </div>
    </>
  );
};

export default NotificationCard;
