import { createSlice } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

const userSlice = createSlice({
  name: 'user',
  initialState: initialStore.user,
  reducers: {
    getUserData: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setUserData: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateUserLanguageData: (state, { payload }) => {},
    getUserFail: (state) => {
      state.isLoading = false;
    },
    removeUserData: (state) => {
      state.data = null;
    }
  }
});

export const {
  getUserData,
  setUserData,
  getUserFail,
  removeUserData,
  updateUserLanguageData
} = userSlice.actions;

export const { reducer } = userSlice;

// Selectors
export const getUserSelector = (state: IState) => state && state.user.data;
export const getUserLoadingSelector = (state: IState) =>
  state && state.user.isLoading;
