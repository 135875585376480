import { themePrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Doctor: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themePrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.1962 0H7.8038C7.70679 0 7.60982 0.00403343 7.51314 0.0120898C5.58682 0.172616 4.15536 1.86434 4.31589 3.79066L4.4801 5.76123C4.63266 7.59189 6.163 9 8 9C9.837 9 11.3673 7.59189 11.5199 5.76123L11.6841 3.79066C11.6922 3.69398 11.6962 3.59701 11.6962 3.5C11.6962 1.567 10.1292 0 8.1962 0ZM5.08784 9.14641L4.33809 9.41273C4.03241 9.90319 4.13928 10.6678 4.19441 11.0623C4.20415 11.132 4.21228 11.1901 4.21623 11.2331C5.25677 11.2761 6.15542 12.0061 6.25001 13.0797L6.34461 14.5828C6.34461 14.8405 6.10812 15.0982 5.82434 15.0982C5.54055 15.0982 5.25677 14.8834 5.25677 14.6258L5.16217 13.0798C5.06758 12.3926 4.5473 12.135 3.93244 12.2209C3.31758 12.3926 2.9865 13.0368 3.27028 13.5092L3.88514 14.8834C4.02703 15.1411 3.88514 15.3988 3.60136 15.5276C3.31757 15.6564 3.03379 15.5276 2.8919 15.2699L2.22974 13.8957C1.75677 12.9939 2.13515 11.8773 3.12839 11.4049C3.07902 11.1136 2.99522 10.439 3.08276 9.85864L2.34082 10.1222C0.932802 10.6502 0 11.9962 0 13.5V15C0 15.5523 0.447715 16 1 16H15C15.5523 16 16 15.5523 16 15V13.5C16 11.9962 15.0672 10.6502 13.6592 10.1222L13.0035 9.88929C13.1622 10.5589 13.2232 11.4223 13.1554 11.7914C13.8176 12.0491 14.2906 12.6503 14.2906 13.3804C14.2906 14.3252 13.4392 15.0982 12.3987 15.0982C11.3581 15.0982 10.5068 14.3252 10.5068 13.3804C10.5068 12.5215 11.1689 11.7914 12.0676 11.6626C12.1526 11.122 12.1094 10.1132 11.8394 9.47579L10.9122 9.14641L8.42875 13.2854C8.23454 13.6091 7.76546 13.6091 7.57125 13.2854L5.08784 9.14641ZM13.2027 13.3369C13.2027 12.9504 12.8243 12.6069 12.3987 12.6069C11.973 12.6069 11.5946 12.9504 11.5946 13.3369C11.5946 13.7234 11.973 14.067 12.3987 14.067C12.8243 14.067 13.2027 13.7234 13.2027 13.3369Z"
      fill={color}
    />
  </svg>
);

export default Doctor;
