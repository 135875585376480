import { takeLatest, put, call } from 'redux-saga/effects';

import {
  WHITE_LABEL_AFFILIATE,
  getItemFromLocalStorage
} from 'shared/utils/local-storage';
import {
  IUpdateUserLanguageRequest,
  IUser
} from 'shared/interfaces/user.interface';
import UserService from 'shared/services/UserService';
import {
  getUserData,
  setUserData,
  getUserFail,
  updateUserLanguageData
} from './user.redux';
import { setWhiteLabelAffiliate } from 'store/app/app.redux';
import { TAction } from 'shared/types';

function* getUser() {
  try {
    const result: IUser = yield call(UserService.getUser);

    yield put(setUserData(result));

    const whiteLabelAffiliate = getItemFromLocalStorage(WHITE_LABEL_AFFILIATE);
    if (result && whiteLabelAffiliate) {
      const affiliate = result.patient?.affiliates.find(
        (affiliate) => affiliate.id === whiteLabelAffiliate
      );
      if (affiliate) {
        yield put(setWhiteLabelAffiliate(affiliate));
      }
    }
  } catch (error) {
    yield put(getUserFail());
    console.log('getUser error', error);
  }
}

function* updateUserLanguage({ payload }: TAction<IUpdateUserLanguageRequest>) {
  try {
    const result: IUser = yield call(
      UserService.updateUserLanguage,
      payload as IUpdateUserLanguageRequest
    );

    yield put(setUserData(result));
  } catch (error) {
    yield put(getUserFail());
    console.log('Update user language error', error);
  }
}

function* User() {
  yield takeLatest(getUserData.type, getUser);
  yield takeLatest(updateUserLanguageData.type, updateUserLanguage);
}

export default User;
