import { SvgIconType } from './svg.interface';

const Edit: SvgIconType = ({
  width = 30,
  height = 30,
  className = '',
  color = '#1e87f0'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1936 4.27934L11.7207 0.806445L11.8078 0.71926C12.7669 -0.239753 14.3217 -0.239753 15.2807 0.71926C16.2398 1.67827 16.2398 3.23314 15.2807 4.19216L15.1936 4.27934ZM13.7793 5.69355L4.51476 14.9581L0 16L1.04187 11.4852L10.3064 2.22066L13.7793 5.69355Z"
      fill={color}
    />
  </svg>
);

export default Edit;
