import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Task: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1154 1.30809L15.7498 12.5767C16.334 13.7452 15.8604 15.166 14.6919 15.7503C14.3635 15.9145 14.0013 16 13.6341 16H2.36541C1.05903 16 0 14.941 0 13.6346C0 13.2674 0.085498 12.9052 0.249723 12.5767L5.88405 1.30809C6.46828 0.139623 7.88912 -0.33399 9.05758 0.250241C9.51536 0.479127 9.88654 0.850314 10.1154 1.30809ZM8 10.5C7.17157 10.5 6.5 11.1716 6.5 12C6.5 12.8284 7.17157 13.5 8 13.5C8.82843 13.5 9.5 12.8284 9.5 12C9.5 11.1716 8.82843 10.5 8 10.5ZM8 9C8.55229 9 9 8.6802 9 8.28571V4.71429C9 4.3198 8.55229 4 8 4C7.44772 4 7 4.3198 7 4.71429V8.28571C7 8.6802 7.44772 9 8 9Z"
      fill={color}
    />
  </svg>
);

export default Task;
