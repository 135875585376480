import { themeTextDisabled } from 'shared/utils/cssUtility';
import SvgIconType from './svg.interface';

const SelectSuffix: SvgIconType = ({
  width = 12,
  height = 12,
  className = '',
  color = themeTextDisabled
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M3.99999 1.81262L6.69348 4.50611C6.98664 4.79927 7.46196 4.79927 7.75512 4.50611C8.04829 4.21294 8.04829 3.73763 7.75512 3.44447L4.53032 0.21967C4.23743 -0.0732233 3.76256 -0.0732233 3.46966 0.21967L0.244867 3.44447C-0.0482972 3.73763 -0.0482972 4.21294 0.244867 4.50611C0.538031 4.79927 1.01334 4.79927 1.30651 4.50611L3.99999 1.81262Z"
      fill={color}
    />
    <path
      d="M7.75512 7.49389C7.46196 7.20073 6.98664 7.20073 6.69348 7.49389L3.99999 10.1874L1.30651 7.49389C1.01334 7.20073 0.538031 7.20073 0.244867 7.49389C-0.0482972 7.78706 -0.0482972 8.26237 0.244867 8.55553L3.46966 11.7803C3.76256 12.0732 4.23743 12.0732 4.53032 11.7803L7.75512 8.55553C8.04829 8.26237 8.04829 7.78706 7.75512 7.49389Z"
      fill={color}
    />
  </svg>
);

export default SelectSuffix;
