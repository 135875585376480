import { http } from '../utils/axios';
import {
  IUpdateUserLanguageRequest,
  IUser
} from '../interfaces/user.interface';

class UserService {
  private static instance: UserService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }

    return UserService.instance;
  }

  getUser = async () => {
    const { data } = await http<IUser>({
      url: '/users/me',
      method: 'GET'
    });
    return data;
  };

  updateUserLanguage = async (
    payload: IUpdateUserLanguageRequest
  ): Promise<IUser> => {
    const { data } = await http<IUser>({
      url: '/users/actions/updateUserLanguage',
      method: 'PUT',
      data: payload
    });
    return data;
  };
}

const instance = UserService.getInstance();

export default instance;
