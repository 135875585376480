import { I18N_NAMESPACE } from 'i18n';
import { useTranslation } from 'react-i18next';

export type TTranslationCustom = (
  key: string,
  defaultValue: string,
  variables?: Record<string, string | number>
) => string;

export const useTranslationCustom = (): TTranslationCustom => {
  const { t: translate } = useTranslation(Object.values(I18N_NAMESPACE));

  const t = (
    key: string,
    defaultValue: string,
    variables: Record<string, string | number> = {}
  ) => {
    const [namespace] = key.split('.');
    return translate(key, {
      ns: I18N_NAMESPACE[namespace as keyof typeof I18N_NAMESPACE],
      defaultValue,
      ...variables
    });
  };

  return t;
};
