import { fork } from 'redux-saga/effects';

import appReducer from './app/saga';
import userReducer from './user/saga';
import channelReducer from './channel/saga';
import conversationReducer from './conversation/saga';
import externalVisitReducer from './onlineVisit/saga';
import notifications from './notifications/saga';
import visits from './visits/saga';
import tasks from './tasks/saga';
import patientNotes from './patientNotes/saga';
import auth from './auth/saga';
import orders from './orders/saga';
import billing from './billing/saga';
import patient from './patient/saga';
import states from './states/saga';
import ecommerce from './ecommerce/saga';
import labOrders from './labOrders/saga';

export default function* () {
  yield fork(appReducer);
  yield fork(userReducer);
  yield fork(channelReducer);
  yield fork(conversationReducer);
  yield fork(externalVisitReducer);
  yield fork(notifications);
  yield fork(visits);
  yield fork(tasks);
  yield fork(patientNotes);
  yield fork(auth);
  yield fork(orders);
  yield fork(billing);
  yield fork(patient);
  yield fork(states);
  yield fork(ecommerce);
  yield fork(labOrders);
}
