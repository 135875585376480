import { themeIconDark } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const TrashExternalVisit: SvgIconType = ({
  width = 14,
  height = 16,
  color = themeIconDark,
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 4.25H1.75V14C1.75 14.3978 1.90804 14.7794 2.18934 15.0607C2.47064 15.342 2.85218 15.5 3.25 15.5H10.75C11.1478 15.5 11.5294 15.342 11.8107 15.0607C12.092 14.7794 12.25 14.3978 12.25 14V4.25H2.5ZM5.5 13.25H4V6.5H5.5V13.25ZM10 13.25H8.5V6.5H10V13.25ZM10.4635 2L9.25 0.5H4.75L3.5365 2H0.25V3.5H13.75V2H10.4635Z"
      fill={color}
    />
  </svg>
);

export default TrashExternalVisit;
