import { createAction, createSlice } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

export const getQuestionnaireInstanceByPatientAndQuestionnaire = createAction(
  'onlineVisit/GET_QUESTIONNAIRE_INSTANCE_BY_PATIENT_AND_QUESTIONNAIRE',
  (payload) => ({ payload })
);

export const checkOtpCode = createAction(
  'onlineVisit/CHECK_OTP_CODE',
  (payload) => ({ payload })
);

export const answerQuestionnaireInstance = createAction(
  'onlineVisit/ANSWER_QUESTIONNAIRE_INSTANCE',
  (payload) => ({ payload })
);

export const updateQuestionnaireInstance = createAction(
  'onlineVisit/UPDATE_QUESTIONNAIRE_INSTANCE',
  (payload) => ({ payload })
);

export const navigateBack = createAction(
  'onlineVisit/NAVIGATE_BACK',
  (payload) => ({ payload })
);

export const attachPaymentIntent = createAction(
  'onlineVisit/ATTACH_PAYMENT_INTENT',
  (payload) => ({ payload })
);

export const attachSymptoms = createAction(
  'onlineVisit/ATTACH_SYMPTOMS',
  (payload) => ({ payload })
);

export const getConditionsAndSymptoms = createAction(
  'onlineVisit/GET_CONDITIONS_AND_SYMPTOMS',
  (payload) => ({ payload })
);

export const attachPatientMedications = createAction(
  'onlineVisit/ATTACH_PATIENT_MEDICATIONS',
  (payload) => ({ payload })
);

export const attachMedicationAllergies = createAction(
  'onlineVisit/ATTACH_MEDICATION_ALLERGIES',
  (payload) => ({ payload })
);

export const getPaymentMethod = createAction(
  'onlineVisit/GET_PAYMENT_METHOD',
  (payload) => ({ payload })
);

export const createStripeSetupIntentPreauthenticatedUser = createAction(
  'onlineVisit/CREATE_STRIPE_SETUP_INTENT_PREAUTHENTICATED_USER'
);

export const fetchQuestionnaireInstance = createAction(
  'onlineVisit/FETCH_QUESTIONNAIRE_INSTANCE',
  (payload) => ({ payload })
);

export const answerQuestionnaireInstanceLocation = createAction(
  'onlineVisit/ANSWER_QUESTIONNAIRE_INSTANCE_LOCATION',
  (payload) => ({ payload })
);

export const validateProspectPatientSession = createAction(
  'onlineVisit/VALIDATE_PROSPECT_PATIENT_SESSION',
  (payload) => ({ payload })
);

export const getAvailablePharmacies = createAction(
  'onlineVisit/GET_AVAILABLE_PHARMACIES',
  (payload) => ({ payload })
);

export const setPharmacy = createAction(
  'onlineVisit/SET_PHARMACY',
  (payload) => ({ payload })
);

export const getPotentialPharmacies = createAction(
  'onlineVisit/GET_POTENTIAL_PHARMACIES',
  (payload) => ({ payload })
);

export const getCompleteQuestionnaire = createAction(
  'onlineVisit/GET_COMPLETE_QUESTIONNAIRE',
  (payload) => ({ payload })
);

export const getOVSAffiliate = createAction(
  'onlineVisit/GET_OVS_AFFILIATE',
  (payload) => ({
    payload
  })
);

const onlineVisitSlice = createSlice({
  name: 'onlineVisit',
  initialState: initialStore.onlineVisit,
  reducers: {
    setCurrentOrder: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingForm = false;
      state.currentOrder = payload;
      if (payload?.questionnaireInstances?.length) {
        state.questionnaireInstances = payload.questionnaireInstances;
      }
    },
    setQuestionnaireInstance: (state, { payload }) => {
      state.isLoadingForm = false;
      state.questionnaireInstances = [
        payload,
        ...state.questionnaireInstances.filter((qI) => qI.id !== payload.id)
      ];
      state.currentQuestionnaireInstance = payload;
    },
    clearAnswersAndDisableArrays: (state) => {
      state.questionnaires.answers = [];
      state.questionnaires.disableArray = [];
    },
    saveAnswerToStore: (state, { payload }) => {
      let answers = state.questionnaires.answers;
      const completedAnswer = answers.find(
        (answer) => answer.location === payload.location
      );
      const updatedAnswer = { ...payload };
      if (completedAnswer && Object.keys(completedAnswer).length) {
        if (payload.remove) {
          updatedAnswer.files = completedAnswer.files.filter(
            (file) => file.name !== payload.remove.fileName
          );
        }
        if (payload.files?.length) {
          updatedAnswer.files = completedAnswer.files?.length
            ? [...completedAnswer.files, ...payload.files]
            : payload.files;
          if (
            completedAnswer.removedFiles?.length &&
            !payload.removedFiles?.length
          ) {
            updatedAnswer.removedFiles = completedAnswer.removedFiles;
          }
        }
        if (payload.removedFiles?.length) {
          updatedAnswer.removedFiles = completedAnswer.removedFiles?.length
            ? [...completedAnswer.removedFiles, ...payload.removedFiles]
            : payload.removedFiles;
          if (completedAnswer.files?.length) {
            updatedAnswer.files = completedAnswer.files;
          }
        }
        if (payload.mode === 'navigate') {
          updatedAnswer.files = completedAnswer.files;
          updatedAnswer.removedFiles = completedAnswer.removedFiles;
        }
        answers = [
          ...answers.filter((answer) => answer.location !== payload.location),
          updatedAnswer
        ];
      } else {
        answers = [...answers, updatedAnswer];
      }

      state.questionnaires.answers = [
        ...state.questionnaires.answers.filter(
          (answer) =>
            !answers.some(
              (payloadAnswer) => payloadAnswer.location === answer.location
            )
        ),
        ...answers
      ];
    },
    setCurrentQuestionnaireInstance: (state, { payload }) => {
      state.isLoadingForm = false;
      state.currentQuestionnaireInstance = payload;
      state.questionnaireInstances = [
        payload,
        ...state.questionnaireInstances.filter((qI) => qI.id !== payload.id)
      ];
    },
    updateAnswerQuestionnaireInstance: (state, { payload }) => {
      state.questionnaireInstances = [
        payload,
        ...state.questionnaireInstances.filter((qI) => qI.id !== payload.id)
      ];
      state.currentQuestionnaireInstance = payload;
      state.questionnaires.answers = [];
      state.questionnaires.disableArray = [];
    },
    updateDisableValue: (state, { payload }) => {
      const { value, location } = payload;
      let disableArray = state.questionnaires.disableArray;
      const field = disableArray.find((field) => field.location === location);

      if (field && Object.keys(field).length) {
        disableArray = [
          ...disableArray.filter((field) => field.location !== location),
          {
            location,
            disabled: value
          }
        ];
      } else {
        disableArray = [
          ...disableArray,
          {
            location,
            disabled: value
          }
        ];
      }

      state.questionnaires.disableArray = [
        ...state.questionnaires.disableArray.filter(
          (disableValue) =>
            !disableArray.some(
              (payloadValue) => payloadValue.location === disableValue.location
            )
        ),
        ...disableArray
      ];
    },
    setUpdatedQuestionnaireInstance: (state, { payload }) => {
      state.isLoading = false;
      if (payload.id === state.currentQuestionnaireInstance?.id) {
        state.currentQuestionnaireInstance = payload;
      }

      state.questionnaireInstances = [
        ...state.questionnaireInstances.filter((qI) => qI.id !== payload.id),
        payload
      ];
    },
    setLoadingForm: (state, { payload }) => {
      state.isLoadingForm = payload;
    },
    setPaymentMethod: (state, { payload }) => {
      state.payments.defaultPaymentMethod = payload;
      state.payments.availablePaymentMethods = payload;
    },
    setupStripeIntent: (state, { payload }) => {
      state.payments.setupIntent = payload;
    },
    attachPaymentIntentSuccess: (state) => {
      state.payments.isAttachedPaymentIntent = true;
    },
    attachPaymentIntentFail: (state) => {
      state.payments.isAttachPaymentIntentFail = true;
    },
    resetPaymentIntentStatuses: (state) => {
      state.payments.isAttachedPaymentIntent = false;
      state.payments.isAttachPaymentIntentFail = false;
    },
    setPatientSymtpoms: (state, { payload }) => {
      if (state.currentOrder) {
        state.currentOrder.symptoms = payload;
      }
    },
    setPatientMedications: (state, { payload }) => {
      if (state.currentOrder?.patient) {
        state.currentOrder.patient.patientMedications = payload;
      }
    },
    setMedicationAllergies: (state, { payload }) => {
      if (state.currentOrder?.patient) {
        state.currentOrder.patient.medicationAllergies =
          payload.medicationAllergies;
      }
    },
    setPossibleSymtpoms: (state, { payload }) => {
      if (state.currentOrder) {
        state.possibleSymptoms = payload;
      }
    },
    setVisitLink: (state, { payload }) => {
      state.visitLink = payload.link;
      state.validationError = false;
    },
    setValidationError: (state, { payload }) => {
      state.validationError = !!payload;
    },
    setAvailablePharmacies: (state, { payload }) => {
      state.availablePharmacies = payload;
    },
    setPotentialPharmacies: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingForm = false;
      state.potentialPharmacies = payload;
    },
    updateQuestionnaireInstances: (state, _data) => {
      state.isLoading = true;
    },
    setCompleteQuestionnaire: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingForm = false;
      state.completeQuestionnaireInstance = payload;
    },
    setOVSAffiliate: (state, { payload }) => {
      state.affiliate = payload;
    }
  }
});

export const {
  setCurrentOrder,
  setQuestionnaireInstance,
  clearAnswersAndDisableArrays,
  saveAnswerToStore,
  setCurrentQuestionnaireInstance,
  updateAnswerQuestionnaireInstance,
  updateDisableValue,
  updateQuestionnaireInstances,
  setUpdatedQuestionnaireInstance,
  setPaymentMethod,
  setLoadingForm,
  setupStripeIntent,
  setPatientMedications,
  setPatientSymtpoms,
  setMedicationAllergies,
  setPossibleSymtpoms,
  setVisitLink,
  setValidationError,
  setAvailablePharmacies,
  setPotentialPharmacies,
  setCompleteQuestionnaire,
  attachPaymentIntentSuccess,
  attachPaymentIntentFail,
  resetPaymentIntentStatuses,
  setOVSAffiliate
} = onlineVisitSlice.actions;

export const { reducer } = onlineVisitSlice;

// Selectors
export const onlineVisitLoadingSelector = (state: IState) =>
  state?.onlineVisit.isLoading;

export const onlineVisitLoadingFormSelector = (state: IState) =>
  state?.onlineVisit.isLoadingForm;

export const getCurrentOnlineVisitSelector = (state: IState) =>
  state?.onlineVisit.currentOrder;

export const getOVSAffiliateSelector = (state: IState) =>
  state?.onlineVisit.affiliate;

export const questionnaireInstancesSelector = (state: IState) =>
  state?.onlineVisit.questionnaireInstances;

export const currentQuestionnaireInstanceSelector = (state: IState) =>
  state?.onlineVisit.currentQuestionnaireInstance;

export const answersSelector = (state: IState) =>
  state?.onlineVisit.questionnaires.answers;

export const questionnairesDisableArraySelector = (state: IState) =>
  state?.onlineVisit.questionnaires.disableArray;

export const paymentsSelector = (state: IState) => state?.onlineVisit.payments;

export const possibleSymptomsSelector = (state: IState) =>
  state?.onlineVisit.possibleSymptoms;

export const patientSymptomsSelector = (state: IState) =>
  state?.onlineVisit.currentOrder?.symptoms;

export const visitLinkSelector = (state: IState) =>
  state?.onlineVisit.visitLink;

export const validationErrorSelector = (state: IState) =>
  state?.onlineVisit.validationError;

export const availablePharmaciesSelector = (state: IState) =>
  state?.onlineVisit.availablePharmacies;

export const potentialPharmaciesSelector = (state: IState) =>
  state?.onlineVisit.potentialPharmacies;

export const getCompleteQuestionnaireSelector = (state: IState) =>
  state?.onlineVisit.completeQuestionnaireInstance;
