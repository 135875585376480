import { http } from '../utils/axios';

import { ITask } from 'shared/interfaces/task.interface';

class TasksService {
  private static instance: TasksService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): TasksService {
    if (!TasksService.instance) {
      TasksService.instance = new TasksService();
    }

    return TasksService.instance;
  }

  getAllTasks = async () => {
    const { data } = await http<ITask[]>({
      url: '/tasks',
      method: 'GET'
    });

    return data;
  };
}

const instance = TasksService.getInstance();

export default instance;
