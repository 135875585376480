import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Pill: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1.4726 7.40438L7.40438 1.4726C8.3498 0.52958 9.63063 0 10.966 0C12.3013 0 13.5821 0.52958 14.5275 1.4726C16.4908 3.43587 16.4908 6.6241 14.5275 8.59577L8.59577 14.5275C6.6241 16.4908 3.43587 16.4908 1.4726 14.5275C0.52958 13.5821 0 12.3013 0 10.966C0 9.63063 0.52958 8.3498 1.4726 7.40438ZM2.66399 8.59577C2.24553 9.01045 1.94435 9.52856 1.79109 10.0974C1.63782 10.6663 1.63794 11.2655 1.79142 11.8343L6.81707 6.81707L10.3745 10.3745L13.3362 7.40438C13.6491 7.09409 13.8976 6.72488 14.0671 6.31807C14.2366 5.91126 14.3239 5.47491 14.3239 5.03418C14.3239 4.59346 14.2366 4.1571 14.0671 3.75029C13.8976 3.34348 13.6491 2.97428 13.3362 2.66399C13.0259 2.35101 12.6567 2.10258 12.2499 1.93304C11.843 1.7635 11.4067 1.67622 10.966 1.67622C10.5252 1.67622 10.0889 1.7635 9.68207 1.93304C9.27526 2.10258 8.90605 2.35101 8.59577 2.66399L2.66399 8.59577Z"
      fill={color}
    />
  </svg>
);
export default Pill;
