import { takeLatest, put, call } from 'redux-saga/effects';
import { INotification } from 'shared/interfaces/notification.interface';
import NotificationsService from 'shared/services/NotificationsService';
import { TAction, TNotification } from 'shared/types';
import {
  getNotificationsData,
  setNotificationData,
  getNotificationsFail,
  dismissNotificationData,
  dismissAllNotificationsData
} from './notification.redux';

function* getNotifications() {
  try {
    const result: INotification = yield call(
      NotificationsService.getNotifications
    );
    yield put(setNotificationData(result));
  } catch (error) {
    yield put(getNotificationsFail());
    console.log('getNotifications error', error);
  }
}
function* dismissNotification({ payload }: TAction<TNotification>) {
  try {
    yield call(NotificationsService.dismissNotification, payload);
    const result: INotification = yield call(
      NotificationsService.getNotifications
    );
    yield put(setNotificationData(result));
  } catch (error) {
    yield put(getNotificationsFail());
    console.log('dismissNotification error', error);
  }
}
function* dismissAllNotifications() {
  try {
    yield call(NotificationsService.dismissAllNotifications);
    const result: INotification = yield call(
      NotificationsService.getNotifications
    );
    yield put(setNotificationData(result));
  } catch (error) {
    yield put(getNotificationsFail());
    console.log('dismissAllNotifications error', error);
  }
}

function* Notifications() {
  yield takeLatest(getNotificationsData.type, getNotifications);
  yield takeLatest(dismissNotificationData.type, dismissNotification);
  yield takeLatest(dismissAllNotificationsData.type, dismissAllNotifications);
}

export default Notifications;
