import { themeIconLight } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const VisaIcon: SvgIconType = ({
  width = 69,
  height = 24,
  className = '',
  color = themeIconLight
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 69 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.9538 0C47.1008 0 48.8215 0.495818 49.9188 0.956865L49.1689 5.8882L48.6727 5.62591C47.6505 5.16486 46.3376 4.72115 44.5265 4.7526C42.3584 4.7526 41.3562 5.7637 41.3562 6.70968C41.3436 7.77556 42.5273 8.47852 44.463 9.53121C47.658 11.1553 49.1347 13.1246 49.1139 15.7133C49.0708 20.437 45.2919 23.4894 39.4705 23.4894C36.987 23.4608 34.5942 22.9111 33.3008 22.2759L34.0782 17.1803L34.7923 17.5435C36.6111 18.3931 37.7888 18.7368 40.0053 18.7368C41.597 18.7368 43.3055 18.04 43.319 16.5146C43.3294 15.5186 42.6049 14.8084 40.4491 13.693C38.3485 12.6043 35.5637 10.7807 35.5945 7.51086C35.6273 3.08752 39.4817 0 44.9538 0ZM0.0688979 0.723633H9.00922C10.2131 0.771222 11.1851 1.17647 11.5207 2.54228L13.4477 12.941C13.4478 12.9414 13.4478 12.9422 13.4477 12.9431C13.4476 12.9445 13.4475 12.9461 13.4477 12.9467L14.0266 16.0623L19.4547 0.723633H25.3267L16.5987 23.1754L10.7322 23.1811L6.06222 5.06407C8.8409 6.60308 11.2065 8.38372 12.5766 10.8341C12.2233 10.0588 11.7579 9.18352 11.1623 8.32091C10.5032 7.36616 9.11744 6.14721 8.44417 5.55498L8.34485 5.46756C6.16323 3.54489 3.20045 1.99204 0 1.17039L0.0688979 0.723633ZM33.4049 0.749023H27.6624L24.0703 23.1662H29.8127L33.4049 0.749023ZM60.0958 0.749023H64.4461L69.0004 23.1662H63.7789C63.7789 23.1662 63.2609 20.5909 63.0925 19.8056C62.6583 19.8056 60.847 19.8032 59.1417 19.801L59.1338 19.801L59.1244 19.8009C57.6131 19.7989 56.1892 19.7971 55.8862 19.7971C55.6677 20.4039 54.7008 23.1661 54.7008 23.1661H48.793L57.1488 2.61092C57.7404 1.15022 58.7489 0.749023 60.0958 0.749023ZM60.0139 8.22978C60.171 7.78818 60.3752 7.21405 60.5226 6.7793V6.77948L60.9002 8.69321C60.9002 8.69321 61.9721 13.9942 62.1959 15.1057L57.5508 15.1087C58.0133 13.8292 59.7809 8.90054 59.7809 8.90054C59.7652 8.92883 59.8697 8.63514 60.0139 8.22978Z"
      fill={color}
    />
  </svg>
);

export default VisaIcon;
