import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Urls } from 'shared/constants/urls';
import { themePrimary, themeTextSecondary } from 'shared/utils/cssUtility';
import { useHighlightColor } from 'shared/utils/hooks/useHighlightColor';
import { useMobile } from 'shared/utils/hooks/useMobile';
import {
  HomeIcon,
  TasksIcon,
  CartIcon,
  ChangeIcon,
  ChatIcon,
  DocumentIcon,
  SettingsIcon,
  NotificationIcon,
  TestTubeIcon
} from 'shared/components/Icons';
import { Logo } from 'shared/components/Logo';
import { Text } from 'shared/components/Text';
import { Sidebar, TContent } from '../../';
import { getTasksData, getTasksSelector } from 'store/tasks/tasks.redux';
import { getTwilioConversationObjectsSelector } from 'store/conversation/conversation.redux';
import {
  getNotificationsData,
  getNotificationsSelector
} from 'store/notifications/notification.redux';
import {
  getAppConfigSelector,
  getWhiteLabelAffiliateSelector
} from 'store/app/app.redux';
import { useTranslationCustom } from 'shared/utils/hooks/useTranslationCustom';
import { ECOMMERCE_PLATFORMS } from 'shared/enums';

interface INavigation {
  showContent: boolean;
  setShowContent: (state: boolean) => void;
}

export const Navigation: FC<INavigation> = ({
  showContent,
  setShowContent
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMobile();
  const appConfig = useSelector(getAppConfigSelector);
  const [getIconColor, getHighLightState] = useHighlightColor(
    themeTextSecondary,
    themePrimary
  );
  const twilioConversationObjects = useSelector(
    getTwilioConversationObjectsSelector
  );
  const notifications = useSelector(getNotificationsSelector);
  const tasksData = useSelector(getTasksSelector);
  const whiteLabelAffiliate = useSelector(getWhiteLabelAffiliateSelector);
  const [getTextColor] = useHighlightColor('text-secondary', 'theme-primary');
  const [isNotifications, setIsNotifications] = useState(false);
  const taskCounter = tasksData.length;
  const t = useTranslationCustom();

  useEffect(() => {
    dispatch(getNotificationsData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTasksData());
  }, [dispatch]);

  const sidebarContent: TContent = {
    header: [
      {
        icon: (
          <Logo
            Logo={
              appConfig.logoWithName && showContent
                ? appConfig.logo
                : appConfig.smallLogo || appConfig.logo
            }
            appName={appConfig.appName}
            width={32}
            height={32}
            className={'menu_logo'}
          />
        ),
        text: !appConfig.logoWithName ? (
          <Text type="m-heading" color={getTextColor(Urls.DashBoard)}>
            {appConfig.appName}
          </Text>
        ) : (
          <></>
        ),
        onClick: async () => {
          await import('../../../../../pages/DashBoardPage');
          navigate(Urls.DashBoard);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible: true
      },
      {
        icon: (
          <HomeIcon
            width={12}
            height={12}
            color={getIconColor(Urls.DashBoard)}
          />
        ),
        text: (
          <Text type="sf-body" color={getTextColor(Urls.DashBoard)}>
            {t('sidebar.dashboard', 'Dashboard')}
          </Text>
        ),
        onClick: async () => {
          await import('../../../../../pages/DashBoardPage');
          navigate(Urls.DashBoard);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible: true,
        highlight: getHighLightState(Urls.DashBoard)
      }
    ],
    bodyTop: [
      {
        icon: (
          <CartIcon
            width={12}
            height={12}
            color={getIconColor(Urls.OrdersHistory)}
          />
        ),
        text: (
          <Text type="sf-body" color={getTextColor(Urls.OrdersHistory)}>
            {t('sidebar.ordersHistory', 'Orders History')}
          </Text>
        ),
        onClick: async () => {
          await import('../../../../../pages/OrdersHistoryPage');
          navigate(Urls.OrdersHistory);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible: whiteLabelAffiliate?.ecommerceModuleEnabled,
        highlight: getHighLightState(Urls.OrdersHistory)
      },
      {
        icon: (
          <CartIcon
            width={12}
            height={12}
            color={getIconColor(Urls.SubscriptionsHistory)}
          />
        ),
        text: (
          <Text type="sf-body" color={getTextColor(Urls.SubscriptionsHistory)}>
            {t('sidebar.subscriptionsHistory', 'Subscriptions History')}
          </Text>
        ),
        onClick: async () => {
          await import('../../../../../pages/SubscriptionsHistoryPage');
          navigate(Urls.SubscriptionsHistory);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible:
          whiteLabelAffiliate?.ecommerceModuleEnabled &&
          whiteLabelAffiliate?.ecommercePlatform !==
            ECOMMERCE_PLATFORMS.Shopify,
        highlight: getHighLightState(Urls.SubscriptionsHistory)
      },
      {
        icon: (
          <ChangeIcon
            width={12}
            height={12}
            color={getIconColor(Urls.VisitHistory)}
          />
        ),
        text: (
          <Text type="sf-body" color={getTextColor(Urls.VisitHistory)}>
            {t('sidebar.visitsHistory', 'Visits History')}
          </Text>
        ),
        onClick: async () => {
          await import('../../../../../pages/ServiceHistoryPage');
          navigate(Urls.VisitHistory);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible: true,
        highlight: getHighLightState(Urls.VisitHistory)
      },
      {
        icon: (
          <TestTubeIcon
            width={12}
            height={12}
            color={getIconColor(Urls.LabOrdersHistory)}
          />
        ),
        text: (
          <Text type="sf-body" color={getTextColor(Urls.LabOrdersHistory)}>
            {t('sidebar.labOrdersHistory', 'Lab Orders History')}
          </Text>
        ),
        onClick: async () => {
          await import('../../../../../pages/LabOrdersHistory');
          navigate(Urls.LabOrdersHistory);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible: true,
        highlight: getHighLightState(Urls.LabOrdersHistory)
      },
      {
        icon: (
          <ChatIcon
            width={12}
            height={12}
            color={getIconColor(Urls.Messages)}
          />
        ),
        text: (
          <Text type="sf-body" color={getTextColor(Urls.Messages)}>
            {t('sidebar.messageCenter', 'Message Center')}
          </Text>
        ),
        count: twilioConversationObjects.reduce((total, message) => {
          total += message.unreadCount;
          return total;
        }, 0),
        onClick: async () => {
          await import('../../../../../pages/MessagesPage');
          navigate(Urls.Messages);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible: true,
        highlight: getHighLightState(Urls.Messages)
      },
      {
        icon: (
          <NotificationIcon
            width={12}
            height={12}
            color={isNotifications ? themePrimary : themeTextSecondary}
          />
        ),
        text: (
          <Text
            type="sf-body"
            color={isNotifications ? 'theme-primary' : 'text-secondary'}
            key="notifications"
          >
            {t('sidebar.notifications', 'Notifications')}
          </Text>
        ),
        count: notifications.length,
        onClick: () => setIsNotifications(!isNotifications),
        visible: true,
        highlight: isNotifications
      },
      {
        icon: (
          <TasksIcon width={12} height={12} color={getIconColor(Urls.Tasks)} />
        ),
        text: (
          <Text type="sf-body" color={getTextColor(Urls.Tasks)}>
            {t('sidebar.taskCenter', 'Task Center')}
          </Text>
        ),
        count: taskCounter,
        onClick: async () => {
          await import('../../../../../pages/TasksPage');
          navigate(Urls.Tasks);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible: true,
        highlight: getHighLightState(Urls.Tasks)
      },
      {
        icon: (
          <DocumentIcon
            width={12}
            height={12}
            color={getIconColor(Urls.Documents)}
          />
        ),
        text: (
          <Text type="sf-body" color={getTextColor(Urls.Documents)}>
            {t('sidebar.documents', 'Documents')}
          </Text>
        ),
        count: 0,
        onClick: async () => {
          await import('../../../../../pages/DocumentsPage');
          navigate(Urls.Documents);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible: true,
        highlight: getHighLightState(Urls.Documents)
      }
    ],
    bodyBottom: [
      {
        icon: (
          <SettingsIcon
            width={12}
            height={12}
            color={getIconColor(Urls.Settings)}
          />
        ),
        text: (
          <Text type="sf-body" color={getTextColor(Urls.Settings)}>
            {t('sidebar.settings', 'Settings')}
          </Text>
        ),
        onClick: async () => {
          await import('../../../../../pages/SettingsPage');
          navigate(Urls.Settings);
          isMobile && setShowContent(false);
          setIsNotifications(false);
        },
        visible: true,
        highlight: getHighLightState(Urls.Settings)
      }
    ]
  };
  return (
    <Sidebar
      showContent={showContent}
      setShowContent={setShowContent}
      content={sidebarContent}
    />
  );
};
