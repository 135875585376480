import { createSlice } from '@reduxjs/toolkit';
import { initialStore } from '../initial-store';
import { IState } from 'shared/interfaces/state.interface';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: initialStore.notifications,
  reducers: {
    getNotificationsData: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setNotificationData: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getNotificationsFail: (state) => {
      state.isLoading = false;
    },
    dismissNotificationData: (state) => {
      state.isLoading = false;
    },
    dismissAllNotificationsData: (state) => {
      state.isLoading = false;
    }
  }
});

export const {
  getNotificationsData,
  setNotificationData,
  getNotificationsFail,
  dismissNotificationData,
  dismissAllNotificationsData
} = notificationsSlice.actions;
export const { reducer } = notificationsSlice;

// Selectors
export const getNotificationsLoadingSelector = (state: IState) =>
  state && state.notifications.isLoading;
export const getNotificationsSelector = (state: IState) =>
  state && state.notifications.data;
