import { http } from '../utils/axios';
import { IPatientNote } from '../interfaces/patientNote.interface';

class PatientNoteService {
  private static instance: PatientNoteService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): PatientNoteService {
    if (!PatientNoteService.instance) {
      PatientNoteService.instance = new PatientNoteService();
    }

    return PatientNoteService.instance;
  }

  getAllPatientNotes = async (patientId: string) => {
    const { data } = await http<IPatientNote[]>({
      url: `/notes/patient/${patientId}`,
      method: 'GET'
    });

    return data;
  };
}

const instance = PatientNoteService.getInstance();

export default instance;
