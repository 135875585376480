import { themePrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Send: SvgIconType = ({
  width = 12,
  height = 12,
  className = '',
  color = themePrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.38456 12.2294L8.75 10.125L1.45296 8.04013L0.0387813 2.55317C-0.133834 1.88343 0.285964 1.20476 0.976426 1.03732C1.26399 0.96759 1.56754 0.996145 1.83593 1.11818L19.4166 9.11182C19.7726 9.2737 20 9.62069 20 10.0021C20 10.3835 19.7726 10.7305 19.4166 10.8924L1.83593 18.886C1.1915 19.179 0.424212 18.9098 0.122137 18.2847C-0.00367004 18.0244 -0.0331091 17.7299 0.0387813 17.451L1.38456 12.2294Z"
      fill={color}
    />
  </svg>
);

export default Send;
