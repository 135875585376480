import { memo, createElement, FC, CSSProperties, ReactNode } from 'react';

import { classNames } from 'shared/utils/class-names';
import classes from './styles.module.scss';

export type TextTypes =
  | 'm-giant'
  | 'm-middle'
  | 'm-section'
  | 'm-section-bold'
  | 'm-titles'
  | 'm-titles-bold'
  | 'm-metrics'
  | 'm-heading'
  | 'm-subheading'
  | 'm-body'
  | 'm-body-emphasized'
  | 'm-caption'
  | 'm-caption-emphasized'
  | 'sf-heading'
  | 'sf-subheading'
  | 'sf-subheading-medium'
  | 'sf-subheading-light'
  | 'sf-body'
  | 'sf-body-medium'
  | 'sf-body-medium-two'
  | 'sf-body-emphasized'
  | 'sf-body-emphasized2'
  | 'sf-body-emphasized3'
  | 'sf-body-condensed'
  | 'sf-base'
  | 'sf-caption'
  | 'sf-caption-emphasized'
  | 'm-heading-2'
  | 'm-heading-3'
  | 'ov-extra72'
  | 'ov-extra56'
  | 'ov-bold36'
  | 'ov-bold32'
  | 'ov-bold24'
  | 'ov-bold20'
  | 'ov-bold18'
  | 'ov-bold16'
  | 'ov-medium20'
  | 'ov-medium16'
  | 'ov-regular18'
  | 'ov-regular16'
  | 'ov-regular14'
  | 'ov-regular12'
  | 'ov-semi32'
  | 'ov-semi14'
  | 'ov-semi13'
  | 'ov-semi12';

export type TextColors =
  | 'text-light'
  | 'default'
  | 'text-secondary'
  | 'theme-border'
  | 'text-disabled'
  | 'text-primary'
  | 'info'
  | 'success'
  | 'success-second'
  | 'attention'
  | 'critical'
  | 'theme-light'
  | 'theme-primary'
  | 'theme-dark'
  | 'theme-ultra-dark'
  | 'text-dark'
  | 'text-button-light'
  | 'text-button-dark'
  | 'base-grey'
  | 'base-yellow';

export interface IText {
  type?: TextTypes;
  color?: TextColors;
  upperCase?: boolean;
  component?: string;
  style?: CSSProperties;
  className?: string;
  onClick?: (e: any | undefined) => void;
  children: ReactNode;
  [key: string]: any;
}

export const Text: FC<IText> = memo(
  ({
    type = 'm-body',
    component = 'p',
    children,
    style,
    className,
    upperCase = false,
    color = 'text-primary',
    ...otherProps
  }) => {
    const props = {
      className: classNames(
        classes[type],
        classes[color],
        { [classes.uppercase]: upperCase },
        className
      ),
      style: { style },
      ...otherProps
    };

    return createElement(component, props, children);
  }
);

Text.displayName = 'Text';
