import { SvgIconType } from './svg.interface';

const TelegraMDLogoColorDark: SvgIconType = ({
  width = 298,
  height = 33,
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 298 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M277.518 8.8418H277.426V24.461H277.518V8.8418Z" fill="#1B81D4" />
    <path
      d="M277.522 8.8418V0H273.517L259.785 12.2326L246.014 0H242.009V33H250.919V16.1055L259.785 23.9364L268.608 16.0628V33H277.522V24.4653H277.426V8.8418H277.522Z"
      fill="url(#paint0_linear_22995_39307)"
      // style="mix-blend-mode:multiply"
    />
    <path
      d="M281.118 0H273.512L268.604 4.37611V33H281.114C290.429 33 297.991 25.6084 297.991 16.5021C297.991 7.39589 290.438 0 281.118 0ZM281.162 24.4653H277.426V8.8418H281.162C285.573 8.8418 289.173 12.3606 289.173 16.6301C289.173 20.8996 285.573 24.461 281.162 24.461V24.4653Z"
      fill="url(#paint0_linear_22995_39307)"
      // style="mix-blend-mode:multiply"
    />
    <path
      d="M7.60108 9.63939H0V6.94378H18.3351V9.63939H10.7384V28.6154H7.60108V9.63939Z"
      fill="#808080"
    />
    <path
      d="M50.5459 25.9197V28.6111H34.458V6.94378H50.1052V9.63939H37.6258V16.2931H48.7438V18.9248H37.6258V25.9197H50.5459Z"
      fill="#808080"
    />
    <path
      d="M68.3399 6.94378H71.5078V25.9197H83.5116V28.6111H68.3399V6.94378Z"
      fill="#808080"
    />
    <path
      d="M115.91 25.9197V28.6111H99.822V6.94378H115.469V9.63939H102.99V16.2931H114.108V18.9248H102.99V25.9197H115.91Z"
      fill="#808080"
    />
    <path
      d="M148.972 17.6537H152.013V26.1074C149.796 27.9329 146.724 28.8627 143.618 28.8627C136.811 28.8627 131.806 24.1881 131.806 17.7774C131.806 11.3668 136.811 6.69213 143.683 6.69213C147.2 6.69213 150.176 7.83947 152.201 10.0361L150.237 11.9554C148.4 10.2237 146.31 9.44746 143.81 9.44746C138.678 9.44746 134.974 12.9449 134.974 17.7732C134.974 22.6014 138.678 26.1031 143.779 26.1031C145.647 26.1031 147.392 25.7022 148.972 24.7084V17.6495V17.6537Z"
      fill="#808080"
    />
    <path
      d="M185.55 28.6154L180.768 21.9616C180.323 21.9915 179.847 22.0213 179.376 22.0213H173.896V28.6154H170.728V6.94378H179.376C185.14 6.94378 188.622 9.79294 188.622 14.4975C188.622 17.8414 186.846 20.2555 183.744 21.3389L189.002 28.6154H185.55ZM185.454 14.4975C185.454 11.4009 183.334 9.63513 179.28 9.63513H173.896V19.3854H179.28C183.334 19.3854 185.454 17.5898 185.454 14.4932V14.4975Z"
      fill="#808080"
    />
    <path
      d="M220.545 23.1985H208.764L206.324 28.6154H203.061L213.101 6.94378H216.238L226.309 28.6154H222.984L220.545 23.1985ZM219.437 20.7204L214.654 10.1043L209.872 20.7204H219.437Z"
      fill="#808080"
    />
    <defs>
      <linearGradient
        id="paint0_linear_22995_39307"
        x1="297.991"
        y1="16.4979"
        x2="0"
        y2="16.4979"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4C97CC" />
        <stop offset="1" stopColor="#2793CB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_22995_39307"
        x1="298.08"
        y1="-1.32663e-06"
        x2="60.6712"
        y2="428.941"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4C97CC" />
        <stop offset="1" stopColor="#2793CB" />
      </linearGradient>
    </defs>
  </svg>
);
export default TelegraMDLogoColorDark;
