import { http, setupToken } from '../utils/axios';
import { IConversation } from 'shared/interfaces/conversation.interface';

class ConversationService {
  private static instance: ConversationService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): ConversationService {
    if (!ConversationService.instance) {
      ConversationService.instance = new ConversationService();
    }

    return ConversationService.instance;
  }

  getConversationToken = async (userToken: string) => {
    const { data } = await http<{ token: string }>({
      url: `/conversation/token/?access_token=${userToken}`,
      method: 'GET'
    });
    return data.token;
  };

  getConversation = async (conversationId: string) => {
    const { data } = await http<IConversation>({
      url: `/conversation/${conversationId}`,
      method: 'GET'
    });
    return data;
  };

  getConversationByParticipants = async (
    patientId: string,
    providerId: string,
    token?: string
  ) => {
    const { data } = await http<IConversation>({
      url: `/conversation/getByParticipants/${patientId}/${providerId}`,
      method: 'GET',
      ...(token ? setupToken(token) : {})
    });
    return data;
  };

  upgradeTwilioConversation = async (channelId: string) => {
    const { data } = await http<IConversation>({
      url: `/conversation/upgrade/${channelId}`,
      method: 'PUT'
    });
    return data;
  };

  refreshTwilioConversation = async (conversationId: string) => {
    const { data } = await http<IConversation>({
      url: `/conversation/${conversationId}/actions/refresh`,
      method: 'PUT'
    });
    return data;
  };
}

const instance = ConversationService.getInstance();

export default instance;
