import { takeLatest, call, put } from 'redux-saga/effects';
import StripeService from 'shared/services/StripeService';
import { IPaymentMethod } from 'shared/interfaces/payment-method.interface';
import {
  TStripeIntent,
  TAddPaymentMethodAction,
  TAction,
  TSetDefaultPaymentMethod
} from 'shared/types';
import {
  createStripeSetupIntent,
  setStripeSetupIntent,
  addPaymentMethod,
  getPaymentMethods,
  setPaymentMethods,
  removePaymentMethod,
  removePaymentMethodData,
  addPaymentMethodData,
  setDefaultPaymentMethod,
  setDefaultPaymentMethodData
} from './billing.redux';
import { openNotificationMessage } from '../../shared/utils/NotificationMessages';
import { NotificationMessages } from '../../shared/enums';

function* createSetupIntent() {
  try {
    const stripeIntent: TStripeIntent = yield call(
      StripeService.createStripeSetupIntent
    );
    yield put(setStripeSetupIntent(stripeIntent));
  } catch (error) {
    console.log('create setup intent error', error);
  }
}

function* addCard({ payload }: TAction<TAddPaymentMethodAction>) {
  try {
    const result: IPaymentMethod = yield call(
      StripeService.addPaymentMethod,
      payload
    );
    yield put(addPaymentMethodData(result));
    yield put(setDefaultPaymentMethodData(result));
    openNotificationMessage({
      type: NotificationMessages.Success,
      title: 'Success',
      message: 'Payment method was added'
    });
  } catch (error: any) {
    console.log('add card error', error);
    openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message: 'Some critical error happened. Payment method was not added'
    });
  }
}

function* removeCard({ payload }: TAction<{ paymentMethodId: string }>) {
  try {
    const result: string = yield call(StripeService.removeCard, payload);
    yield put(removePaymentMethodData(result));
    yield openNotificationMessage({
      type: NotificationMessages.Success,
      title: 'Success',
      message: 'Payment method was deleted'
    });
  } catch (error: any) {
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message: error?.response?.data.message || 'Card was not deleted.'
    });
    console.log('remove card error', error);
  }
}

function* getAvailablePaymentMethods({
  payload
}: TAction<{ patient?: string; affiliate?: string }>) {
  try {
    const result: IPaymentMethod[] = yield call(
      StripeService.getAvailablePaymentMethods,
      payload
    );
    yield put(
      setPaymentMethods(
        (result || []).map((paymentMethod) => ({
          ...paymentMethod,
          paymentId: paymentMethod.paymentId || paymentMethod?.id
        }))
      )
    );
    const defaultPaymentMethod = (result || []).find(
      (paymentMethod) => paymentMethod.isDefault === true
    );
    yield put(setDefaultPaymentMethodData(defaultPaymentMethod));
  } catch (error) {
    console.log('get available payment method error', error);
  }
}

function* setDefaultCard({ payload }: TAction<TSetDefaultPaymentMethod>) {
  try {
    const result: IPaymentMethod = yield call(
      StripeService.setDefaultCard,
      payload
    );
    yield put(setDefaultPaymentMethodData(result));
  } catch (error) {
    console.log('set defulat card error', error);
  }
}

function* Billing() {
  yield takeLatest(createStripeSetupIntent.type, createSetupIntent);
  yield takeLatest(addPaymentMethod.type, addCard);
  yield takeLatest(getPaymentMethods.type, getAvailablePaymentMethods);
  yield takeLatest(removePaymentMethod.type, removeCard);
  yield takeLatest(setDefaultPaymentMethod.type, setDefaultCard);
}

export default Billing;
