import { themeIconDark } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Card: SvgIconType = ({
  width = 24,
  height = 24,
  color = themeIconDark,
  strokeWidth = 2,
  className = ''
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5455 7H5.45455C4.65122 7 4 7.61561 4 8.375V16.625C4 17.3844 4.65122 18 5.45455 18H18.5455C19.3488 18 20 17.3844 20 16.625V8.375C20 7.61561 19.3488 7 18.5455 7Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 11H20"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Card;
