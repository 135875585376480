import { http } from '../utils/axios';
import { IAffiliate } from 'shared/interfaces/affiliate.interface';

class AffiliateService {
  private static instance: AffiliateService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): AffiliateService {
    if (!AffiliateService.instance) {
      AffiliateService.instance = new AffiliateService();
    }

    return AffiliateService.instance;
  }

  getAffiliate = async (payload: { affiliateId: string }) => {
    const { data } = await http<IAffiliate>({
      method: 'GET',
      url: `/affiliates/${payload.affiliateId}`
    });
    return data;
  };
}

const instance = AffiliateService.getInstance();

export default instance;
