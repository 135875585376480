import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Email: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M8 8.73L16 2H0L8 8.73Z" fill={color} />
    <path
      d="M16 13V4.59338L8.71037 10.7514C8.31804 11.0829 7.68196 11.0829 7.28963 10.7514L0 4.59338V13C0 13.5523 0.447715 14 1 14H15C15.5523 14 16 13.5523 16 13Z"
      fill={color}
    />
  </svg>
);

export default Email;
