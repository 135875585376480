//Don't use words in parts of paths that might be part of IDs, such as "order" or "user", etc.
export enum Urls {
  LoginPage = '/login',
  OTPPage = '/otp-code',
  RegistrationPage = '/registration',
  DashBoard = '/dashboard',
  Documents = '/documents',
  GettingStarted = '/help/gettingstarted',
  ConsultationPage = '/consultation/:id',
  ConsultationsPage = '/consultation',
  ProfilePage = '/profile',
  ProductPage = '/product/:id',
  Settings = '/settings',
  OrdersHistory = '/ordersHistory',
  OrderPage = '/orders/:id',
  SubscriptionsHistory = '/subscriptionsHistory',
  SubscriptionPage = '/subscriptions/:id',
  VisitHistory = '/visitHistory',
  VisitPage = '/visits/:id',
  Tasks = '/tasks',
  TestSecure = '/secure',
  TeleHealthPartner = '/partners/telehealth',
  Notifications = '/notifications',
  Messages = '/messages',
  OnlineVisit = '/onlineVisit/:id',
  StartVisit = '/startVisit',
  ConfirmationOrderPage = '/affiliate/externalVisit/confirmation',
  ValidatingVisitPage = '/affiliate/externalVisit',
  CompleteQuestionnaire = '/completeQuestionnaire',
  GoogleAuth = '/google-auth',
  OrderPaymentUpdate = '/orderPayment',
  IframeComponent = '/iframe/:component',
  LabOrderPage = '/labOrder/:id',
  LabOrdersHistory = '/labOrdersHistory',
  ShortUrl = '/shortUrl/:id'
}

export const AuthUrls = [Urls.LoginPage, Urls.OTPPage, Urls.RegistrationPage];
