import { http, setupToken } from '../utils/axios';

import { IOrder } from '../interfaces/order.interface';
import {
  TAttachPaymentIntentPayload,
  TAttachSymptomsPayload,
  TCreateOrderPayload,
  TCreateSymptomsPayload
} from '../types';
import { ISymptom } from 'shared/interfaces/symptom.interface';

class OrdersService {
  private static instance: OrdersService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): OrdersService {
    if (!OrdersService.instance) {
      OrdersService.instance = new OrdersService();
    }

    return OrdersService.instance;
  }

  attachPaymentIntent = async ({
    token,
    ...other
  }: TAttachPaymentIntentPayload) => {
    const { data } = await http<IOrder>({
      method: 'POST',
      url: '/orders/actions/attachPaymentIntent',
      data: other,
      ...(token ? setupToken(token) : {})
    });
    return data;
  };

  attachSymptoms = async ({
    token,
    orderId,
    symptomsId
  }: TAttachSymptomsPayload) => {
    const { data } = await http<IOrder>({
      method: 'PUT',
      url: `/orders/${orderId}/actions/attachSymptoms`,
      data: { symptoms: symptomsId },
      ...(token ? setupToken(token) : {})
    });
    return data;
  };

  createSymptoms = async ({ token, symptoms }: TCreateSymptomsPayload) => {
    const { data } = await http<ISymptom[]>({
      method: 'POST',
      url: '/symptoms/createMultiple',
      data: { symptoms },
      ...(token ? setupToken(token) : {})
    });
    return data.map((symptom) => symptom.id);
  };

  getAllOrders = async ({
    token,
    affiliate
  }: {
    token?: string;
    affiliate?: string;
  }) => {
    const { data } = await http<IOrder[]>({
      method: 'GET',
      url: '/orders',
      params: affiliate ? { affiliate } : {},
      ...(token ? setupToken(token) : {})
    });
    return data;
  };

  createOrder = async (payload: TCreateOrderPayload) => {
    const { data } = await http<IOrder>({
      method: 'POST',
      url: '/orders',
      data: payload
    });
    return data;
  };

  getOrder = async (id: string) => {
    const { data } = await http<IOrder>({
      method: 'GET',
      url: `/orders/${id}`
    });
    return data;
  };

  processOrderPayment = async (payload: {
    id: string;
    paymentMethod: string;
  }) => {
    const { data } = await http<IOrder>({
      method: 'PUT',
      url: `/orders/${payload.id}/actions/manualProcess`,
      data: payload
    });
    return data;
  };

  updateOrder = async (payload: {
    id: string;
    newData: IOrder | Partial<IOrder>;
  }) => {
    const { data } = await http<IOrder>({
      url: `/orders/${payload.id}`,
      method: 'PUT',
      data: payload.newData
    });

    return data;
  };
}

const instance = OrdersService.getInstance();

export default instance;
