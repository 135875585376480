import { takeLatest, put, call, select } from 'redux-saga/effects';

import OrdersService from 'shared/services/OrdersService';
import { IOrder } from 'shared/interfaces/order.interface';
import {
  getOrdersData,
  setOrdersData,
  getOrdersFail,
  setCurrentOrderData,
  getCurrentOrder,
  processOrderPayment,
  processOrderPaymentFail,
  getCurrentOrderFail,
  createOrder,
  updateDataOrder
} from './orders.redux';
import {
  getCurrentOnlineVisitSelector,
  setCurrentOrder,
  setLoadingForm
} from 'store/onlineVisit/onlineVisit.redux';
import { TAction, TCreateOrderPayload } from 'shared/types';
import { openNotificationMessage } from 'shared/utils/NotificationMessages';
import {
  NotificationMessages,
  OrderStatuses,
  PaymentIntentStatuses
} from 'shared/enums';
import {
  TEMPORARY_TOKEN,
  getItemFromLocalStorage,
  removeItemsFromLocalStorage
} from 'shared/utils/local-storage';
import { USER_PROGRESS_DATA } from 'pages/OnlineVisit/constants';
import { checkSubmitOrderAndReglowSubscribe } from 'store/onlineVisit/saga';
import {
  isReglow,
  subscribeEvent
} from 'shared/components/ReglowFacebookPixel';

function* getOrdersSaga({
  payload
}: TAction<{
  token?: string;
  affiliate?: string;
}>) {
  try {
    const result: IOrder[] = yield call(OrdersService.getAllOrders, payload);
    yield put(setOrdersData(result));
  } catch (error) {
    yield put(getOrdersFail());
    console.log('getOrders error', error);
  }
}

function* createOrderSaga({ payload }: TAction<TCreateOrderPayload>) {
  try {
    const result: IOrder = yield call(OrdersService.createOrder, payload);
    yield put(setCurrentOrderData(result));
    yield put(setCurrentOrder(result)); // OVS
    if (result.status !== OrderStatuses.Started) {
      if (isReglow()) {
        subscribeEvent();
      }
    }
  } catch (error: any) {
    yield put(getCurrentOrderFail());
    console.log('getOrder error', error);
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message:
        `Order creation error. ${error.response?.data.error}` ||
        'Internal Server Error'
    });
  }
}

function* getOrderSaga({ payload }: TAction<string>) {
  try {
    const result: IOrder = yield call(OrdersService.getOrder, payload);
    yield put(setCurrentOrder(result)); // OVS
    yield put(setCurrentOrderData(result));
  } catch (error) {
    yield put(getCurrentOrderFail());
    console.log('getOrder error', error);
  }
}

function* processOrderPaymentSaga({
  payload
}: TAction<{
  id: string;
  paymentMethod: string;
}>) {
  try {
    const result: IOrder = yield call(
      OrdersService.processOrderPayment,
      payload
    );
    if (
      result.consultationPaymentIntent.status === PaymentIntentStatuses.Success
    ) {
      yield put(setCurrentOrderData(result));
      removeItemsFromLocalStorage([TEMPORARY_TOKEN]);
      yield openNotificationMessage({
        type: NotificationMessages.Success,
        title: 'Success',
        message: 'Order was successfully billed!'
      });
    } else {
      yield put(processOrderPaymentFail());
      yield openNotificationMessage({
        type: NotificationMessages.Error,
        title: 'Error',
        message: 'Order was not successfully billed.'
      });
    }
  } catch (error: any) {
    yield put(processOrderPaymentFail());
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message:
        error?.response?.data.message || 'Order was not successfully billed.'
    });
    console.log('processOrderPayment error', error);
  }
}

function* updateOrderDataSaga({
  payload
}: TAction<{
  id: string;
  newData: IOrder | Partial<IOrder>;
  callback: () => void;
}>) {
  try {
    yield put(setLoadingForm(true));
    const order: IOrder = yield select(getCurrentOnlineVisitSelector);
    const result: IOrder = yield call(OrdersService.updateOrder, payload);
    yield put(setCurrentOrderData(result));
    yield put(setCurrentOrder(result)); // OVS

    yield call(checkSubmitOrderAndReglowSubscribe, order, result);

    const progress = getItemFromLocalStorage(USER_PROGRESS_DATA.USER_PROGRESS);
    if (!progress) {
      yield openNotificationMessage({
        type: NotificationMessages.Success,
        title: 'Success',
        message: 'Order was successfully updated.'
      });
    }
    if (payload?.callback) {
      yield call(payload.callback);
    }
  } catch (error: any) {
    console.log('error', error);
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message: error?.response?.data?.message || 'Order was not updated.'
    });
  }
}

function* Visits() {
  yield takeLatest(getOrdersData.type, getOrdersSaga);
  yield takeLatest(createOrder.type, createOrderSaga);
  yield takeLatest(getCurrentOrder.type, getOrderSaga);
  yield takeLatest(processOrderPayment.type, processOrderPaymentSaga);
  yield takeLatest(updateDataOrder.type, updateOrderDataSaga);
}

export default Visits;
