import { themeExternalPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const LocationMarker: SvgIconType = ({
  width = 16,
  height = 16,
  color = themeExternalPrimary,
  className = ''
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99999 16.5C6.91736 15.5425 5.91387 14.4928 4.99999 13.3619C3.62857 11.6636 2 9.13433 2 6.72413C1.99941 5.49322 2.35101 4.2898 3.01031 3.26616C3.66961 2.24252 4.60697 1.44468 5.70376 0.973603C6.80056 0.50253 8.00748 0.379401 9.1718 0.6198C10.3361 0.860199 11.4055 1.45332 12.2445 2.3241C12.8032 2.90075 13.246 3.58655 13.5473 4.34181C13.8486 5.09708 14.0025 5.9068 14 6.72413C14 9.13433 12.3714 11.6636 11 13.3619C10.0861 14.4928 9.0826 15.5425 7.99999 16.5ZM7.99999 4.05798C7.318 4.05798 6.66395 4.33888 6.18172 4.83888C5.69948 5.33888 5.42856 6.01702 5.42856 6.72413C5.42856 7.43123 5.69948 8.10938 6.18172 8.60938C6.66395 9.10938 7.318 9.39028 7.99999 9.39028C8.68197 9.39028 9.33602 9.10938 9.81826 8.60938C10.3005 8.10938 10.5714 7.43123 10.5714 6.72413C10.5714 6.01702 10.3005 5.33888 9.81826 4.83888C9.33602 4.33888 8.68197 4.05798 7.99999 4.05798Z"
      fill={color}
    />
  </svg>
);

export default LocationMarker;
