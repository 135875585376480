import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';
import { IState } from 'shared/interfaces/state.interface';

export const getOrderReceipt = createAction(
  'ecommerce/getOrderReceipt',
  (payload: { orderId: string; affiliate: string }) => ({ payload })
);

const ecommerceSlice = createSlice({
  name: 'ecommerce',
  initialState: initialStore.ecommerce,
  reducers: {
    getEcommerceOrders: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setEcommerceOrders: (state, { payload }) => {
      state.isLoading = false;
      state.ecommerceOrders = payload;
    },
    getEcommerceCurrentOrder: (state, _data) => {
      state.isLoading = true;
    },
    setEcommerceCurrentOrder: (state, { payload }) => {
      state.isLoading = false;
      state.ecommerceCurrentOrder = payload;
    },
    getEcommerceSubscriptions: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setEcommerceSubscriptions: (state, { payload }) => {
      state.isLoading = false;
      state.ecommerceSubscriptions = payload;
    },
    getEcommerceCurrentSubscription: (state, _data) => {
      state.isLoading = true;
    },
    setEcommerceCurrentSubscription: (state, { payload }) => {
      state.isLoading = false;
      state.ecommerceCurrentSubscription = payload;
    },
    actionFail: (state) => {
      state.isLoading = false;
    }
  }
});

export const {
  getEcommerceOrders,
  setEcommerceOrders,
  getEcommerceCurrentOrder,
  setEcommerceCurrentOrder,
  getEcommerceSubscriptions,
  setEcommerceSubscriptions,
  getEcommerceCurrentSubscription,
  setEcommerceCurrentSubscription,
  actionFail
} = ecommerceSlice.actions;

export const { reducer } = ecommerceSlice;

// Selectors
export const getEcommerceLoadingSelector = (state: IState) =>
  state && state.ecommerce.isLoading;

export const getEcommerceOrdersSelector = (state: IState) =>
  state && state.ecommerce.ecommerceOrders;

export const getEcommerceCurrentOrderSelector = (state: IState) =>
  state && state.ecommerce.ecommerceCurrentOrder;

export const getEcommerceSubscriptionsSelector = (state: IState) =>
  state && state.ecommerce.ecommerceSubscriptions;

export const getEcommerceCurrentSubscriptionSelector = (state: IState) =>
  state && state.ecommerce.ecommerceCurrentSubscription;
