import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Home: SvgIconType = ({
  width = 12,
  height = 12,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={`${width}`}
    height={`${height}`}
    className={`${className}`}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.971895 6.0007L8 2.00121L15.0281 6.00069C15.4161 5.98824 15.7634 5.75368 15.9178 5.39686C16.1372 4.88964 15.9022 4.30132 15.3929 4.08281L8.39718 0.0815988C8.14357 -0.0271996 7.85617 -0.0271996 7.60257 0.0815988L0.606879 4.08281C0.238586 4.24081 0 4.60186 0 5.00121C0 5.54273 0.432243 5.98372 0.971895 6.0007Z"
      fill={color}
    />
    <path
      d="M8 3.72708L1.5 7.42605V15C1.5 15.5523 1.94772 16 2.5 16H6V11C6 10.4477 6.44772 10 7 10H9C9.55229 10 10 10.4477 10 11V16H13.5C14.0523 16 14.5 15.5523 14.5 15V7.42605L8 3.72708Z"
      fill={color}
    />
  </svg>
);
export default Home;
