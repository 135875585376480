import { createSlice } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

// Slices

const statesSlice = createSlice({
  name: 'states',
  initialState: initialStore.states,
  reducers: {
    getStatesData: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setStatesData: (state, { payload }) => {
      state.isLoading = false;
      state.allStates = payload;
    },
    getStatesFail: (state) => {
      state.isLoading = false;
    }
  }
});

export const { getStatesData, getStatesFail, setStatesData } =
  statesSlice.actions;

export const { reducer } = statesSlice;

// Selectors
export const getAllStatesSelector = (state: IState) =>
  state && state.states.allStates;

export const getStatesLoadingSelector = (state: IState) =>
  state && state.states.isLoading;
