import { SvgIconType } from './svg.interface';

const ArrowDownInput: SvgIconType = ({
  width = 24,
  height = 24,
  className = '',
  color = '#384048'
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.41 8.59L6 10L12 16L18 10L16.59 8.59L12 13.17L7.41 8.59Z"
      fill={color}
    />
  </svg>
);

export default ArrowDownInput;
