import { http } from '../utils/axios';
import { IVisit } from 'shared/interfaces/visit.interface';

class VisitsService {
  private static instance: VisitsService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): VisitsService {
    if (!VisitsService.instance) {
      VisitsService.instance = new VisitsService();
    }

    return VisitsService.instance;
  }

  getAllVisits = async (patientId: string) => {
    const { data } = await http<IVisit[]>({
      url: '/visits/visitsByPatient',
      method: 'GET',
      params: { patientId: patientId }
    });
    return data;
  };

  getVisit = async (id: string | number) => {
    const { data } = await http<IVisit>({
      url: `/visits/${id}`,
      method: 'GET'
    });
    return data;
  };
}

const instance = VisitsService.getInstance();

export default instance;
