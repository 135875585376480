import { createAction, createSlice } from '@reduxjs/toolkit';
import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

export const getPatientNotes = createAction(
  'patientNotes/getPatientNotes',
  (payload) => ({ payload })
);

const patientNotesSlice = createSlice({
  name: 'patientNotes',
  initialState: initialStore.patientNotes,
  reducers: {
    getPatientNotesData: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setPatientNotesData: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getPatientNotesFail: (state) => {
      state.isLoading = false;
    }
  }
});

export const { getPatientNotesData, setPatientNotesData, getPatientNotesFail } =
  patientNotesSlice.actions;

export const { reducer } = patientNotesSlice;

// Selectors
export const getPatientNotesSelector = (state: IState) =>
  state && state.patientNotes.data;

export const getPatientNotesLoadingSelector = (state: IState) =>
  state && state.patientNotes.isLoading;
