import { SvgIconType } from './svg.interface';

const Marker: SvgIconType = ({
  width = 18,
  height = 18,
  className = '',
  color = '#000000'
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.99999 10C3.32335 9.40158 2.69617 8.74553 2.125 8.03872C1.26786 6.97726 0.25 5.39645 0.25 3.89008C0.249629 3.12076 0.469379 2.36862 0.881442 1.72885C1.2935 1.08907 1.87936 0.590423 2.56485 0.296002C3.25035 0.0015812 4.00468 -0.0753741 4.73237 0.0748752C5.46007 0.225125 6.12842 0.595824 6.65284 1.14006C7.00199 1.50047 7.27874 1.92909 7.46706 2.40113C7.65539 2.87317 7.75155 3.37925 7.74998 3.89008C7.74998 5.39645 6.73213 6.97726 5.87499 8.03872C5.30381 8.74553 4.67663 9.40158 3.99999 10ZM3.99999 2.22374C3.57375 2.22374 3.16497 2.3993 2.86357 2.7118C2.56218 3.0243 2.39285 3.44814 2.39285 3.89008C2.39285 4.33202 2.56218 4.75586 2.86357 5.06836C3.16497 5.38086 3.57375 5.55642 3.99999 5.55642C4.42623 5.55642 4.83501 5.38086 5.13641 5.06836C5.43781 4.75586 5.60713 4.33202 5.60713 3.89008C5.60713 3.44814 5.43781 3.0243 5.13641 2.7118C4.83501 2.3993 4.42623 2.22374 3.99999 2.22374Z"
      fill={color}
    />
  </svg>
);

export default Marker;
