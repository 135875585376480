import { themeIconLight } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Card: SvgIconType = ({
  width = 24,
  height = 24,
  className = '',
  color = themeIconLight
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 34 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.8 4.5999H0.200001V3.0249C0.200001 1.57515 1.1402 0.399902 2.3 0.399902H31.7C32.8598 0.399902 33.8 1.57515 33.8 3.0249V4.5999ZM33.8 9.8499V23.4999C33.8 24.6597 32.8598 25.5999 31.7 25.5999H2.3C1.1402 25.5999 0.200001 24.6597 0.200001 23.4999V9.8499H33.8ZM8.6 17.1999C7.4402 17.1999 6.5 18.1401 6.5 19.2999C6.5 20.4597 7.4402 21.3999 8.6 21.3999H10.7C11.8598 21.3999 12.8 20.4597 12.8 19.2999C12.8 18.1401 11.8598 17.1999 10.7 17.1999H8.6Z"
      fill={color}
    />
  </svg>
);

export default Card;
