import { themePrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const DoctorRect: SvgIconType = ({
  width = 32,
  height = 32,
  className = '',
  color = themePrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="32" height="32" rx="4" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2453 6H15.7547C15.6335 6 15.5123 6.00504 15.3914 6.01511C12.9835 6.21577 11.1942 8.33042 11.3949 10.7383L11.6001 13.2015C11.7908 15.4899 13.7037 17.25 16 17.25C18.2963 17.25 20.2092 15.4899 20.3999 13.2015L20.6051 10.7383C20.6152 10.6175 20.6203 10.4963 20.6203 10.375C20.6203 7.95875 18.6615 6 16.2453 6ZM12.3598 17.433L11.4226 17.7659C11.0405 18.379 11.1741 19.3348 11.243 19.8278C11.2552 19.915 11.2653 19.9876 11.2703 20.0414C12.571 20.0951 13.6943 21.0077 13.8125 22.3497L13.9308 24.2285C13.9308 24.5506 13.6352 24.8727 13.2804 24.8727C12.9257 24.8727 12.571 24.6043 12.571 24.2822L12.4527 22.3497C12.3345 21.4908 11.6841 21.1687 10.9156 21.2761C10.147 21.4908 9.73312 22.296 10.0879 22.8865L10.8564 24.6043C11.0338 24.9264 10.8564 25.2485 10.5017 25.4095C10.147 25.5706 9.79224 25.4095 9.61488 25.0874L8.78718 23.3696C8.19596 22.2423 8.66893 20.8466 9.91049 20.2561C9.84878 19.8919 9.74402 19.0488 9.85345 18.3233L8.92603 18.6527C7.166 19.3127 6 20.9953 6 22.875V24.75C6 25.4404 6.55964 26 7.25 26H24.75C25.4404 26 26 25.4404 26 24.75V22.875C26 20.9953 24.834 19.3127 23.074 18.6527L22.2544 18.3616C22.4527 19.1986 22.529 20.2778 22.4443 20.7393C23.272 21.0613 23.8632 21.8129 23.8632 22.7255C23.8632 23.9064 22.799 24.8727 21.4983 24.8727C20.1977 24.8727 19.1335 23.9064 19.1335 22.7255C19.1335 21.6518 19.9612 20.7393 21.0845 20.5782C21.1908 19.9025 21.1367 18.6416 20.7993 17.8447L19.6402 17.433L16.5359 22.6068C16.2932 23.0114 15.7068 23.0114 15.4641 22.6068L12.3598 17.433ZM22.5034 22.6712C22.5034 22.188 22.0304 21.7586 21.4983 21.7586C20.9662 21.7586 20.4933 22.188 20.4933 22.6712C20.4933 23.1543 20.9662 23.5837 21.4983 23.5837C22.0304 23.5837 22.5034 23.1543 22.5034 22.6712Z"
      fill="white"
    />
  </svg>
);

export default DoctorRect;
