import { call, takeLatest, put } from 'redux-saga/effects';

import { IStateAddress } from 'shared/interfaces/state-address.interface';
import StatesService from 'shared/services/StatesService';

import { getStatesData, setStatesData, getStatesFail } from './states.redux';

function* getStates() {
  try {
    const result: IStateAddress[] = yield call(StatesService.getAllStates);

    yield put(setStatesData(result));
  } catch (error) {
    console.log('error', error);
    yield put(getStatesFail());
  }
}

function* States() {
  yield takeLatest(getStatesData.type, getStates);
}

export default States;
