import { SvgIconType } from './svg.interface';

const Location: SvgIconType = ({
  width = 32,
  height = 32,
  color = '#1e87f0',
  className = ''
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99999 16C4.91736 15.0425 3.91387 13.9928 2.99999 12.8619C1.62857 11.1636 7.53336e-07 8.63433 7.53336e-07 6.22413C-0.000594015 4.99322 0.351007 3.7898 1.01031 2.76616C1.66961 1.74252 2.60697 0.944677 3.70376 0.473603C4.80056 0.00252992 6.00748 -0.120599 7.1718 0.1198C8.33611 0.360199 9.40547 0.953319 10.2445 1.8241C10.8032 2.40075 11.246 3.08655 11.5473 3.84181C11.8486 4.59708 12.0025 5.4068 12 6.22413C12 8.63433 10.3714 11.1636 8.99998 12.8619C8.0861 13.9928 7.0826 15.0425 5.99999 16ZM5.99999 3.55798C5.318 3.55798 4.66395 3.83888 4.18172 4.33888C3.69948 4.83888 3.42856 5.51702 3.42856 6.22413C3.42856 6.93123 3.69948 7.60938 4.18172 8.10938C4.66395 8.60938 5.318 8.89028 5.99999 8.89028C6.68197 8.89028 7.33602 8.60938 7.81826 8.10938C8.30049 7.60938 8.57141 6.93123 8.57141 6.22413C8.57141 5.51702 8.30049 4.83888 7.81826 4.33888C7.33602 3.83888 6.68197 3.55798 5.99999 3.55798Z"
      fill={color}
    />
  </svg>
);

export default Location;
