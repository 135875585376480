import { createSlice } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

// Slice
const channelSlice = createSlice({
  name: 'channel',
  initialState: initialStore.channel,
  reducers: {
    getChannels: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setChannels: (state, { payload }) => {
      state.isLoading = false;
      state.channels = payload;
    },
    getChannelsFail: (state) => {
      state.isLoading = false;
    },
    updateChannelData: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    }
  }
});

export const { getChannels, setChannels, getChannelsFail, updateChannelData } =
  channelSlice.actions;

export const { reducer } = channelSlice;

// Selectors
export const getChannelsSelector = (state: IState) =>
  state && state.channel.channels;
export const getChannelLoadingSelector = (state: IState) =>
  state && state.channel.isLoading;
