import { SvgIconType } from './svg.interface';

const EditExternalVisit: SvgIconType = ({
  width = 30,
  height = 30,
  className = '',
  color = '#1e87f0'
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      stroke={color}
      d="M18.65,1.68 C18.41,1.45 18.109,1.33 17.81,1.33 C17.499,1.33 17.209,1.45 16.98,1.68 L8.92,9.76 L8,12.33 L10.55,11.41 L18.651,3.34 C19.12,2.87 19.12,2.15 18.65,1.68 L18.65,1.68 L18.65,1.68 Z"
    ></path>
    <polyline
      fill="none"
      stroke={color}
      points="16.5 8.482 16.5 18.5 3.5 18.5 3.5 1.5 14.211 1.5"
    ></polyline>
  </svg>
);

export default EditExternalVisit;
