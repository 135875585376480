import variables from '../../styles/variables.scss';

const cssValue = (property: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(property);
};

export const baseWhite = variables.baseWhite;
export const baseBlack = variables.baseBlack;
export const baseBlue = variables.baseBlue;
export const baseGrey = variables.baseGrey;
export const baseMask = variables.baseMask;

export const disabled = variables.disabled;

export const info = variables.info;
export const success = variables.success;
export const successSecond = variables.successSecond;
export const attention = variables.attention;
export const critical = variables.critical;

export const themeBrandPrimary = variables.themeBrandPrimary;
export const themePrimary = variables.themePrimary;
export const themeLight = variables.themeLight;
export const themeDark = variables.themeDark;
export const themeUltraDark = variables.themeUltraDark;

export const themeCustomShadowDark = variables.themeCustomShadowDark;
export const themeCustomShadowPrimary = variables.themeCustomShadowPrimary;

export const themeExternalPrimary = variables.themeExternalPrimary;
export const themeExternalDark = variables.themeExternalDark;
export const themeExternalLight = variables.themeExternalLight;

export const themeBackgroundPrimary = variables.themeBackgroundPrimary;
export const themeBackgroundModal = variables.themeBackgroundModal;
export const themeBackgroundContainer = variables.themeBackgroundContainer;
export const themeBackgroundMask = variables.themeBackgroundMask;
export const themeBackgroundExternal = variables.themeBackgroundExternal;
export const themeBackgroundExternalButtonBlock =
  variables.themeBackgroundExternalButtonBlock;
export const themeBackgroundProgressBar = variables.themeBackgroundProgressBar;
export const themeBackgroundButtonLight = variables.themeBackgroundButtonLight;
export const themeBackgroundButtonHoverDark =
  variables.themeBackgroundButtonHoverDark;

export const themeTextPrimary = variables.themeTextPrimary;
export const themeTextSecondary = variables.themeTextSecondary;
export const themeTextDisabled = variables.themeTextDisabled;
export const themeTextLight = variables.themeTextLight;
export const themeTextDark = variables.themeTextDark;
export const themeTextButtonLight = variables.themeTextButtonLight;
export const themeTextButtonDark = variables.themeTextButtonDark;

export const themeBorder = variables.themeBorder;
export const themeBorderDark = variables.themeBorderDark;
export const themeInputsBorderHover = variables.themeInputsBorderHover;

export const themeIconLight = variables.themeIconLight;
export const themeIconDark = variables.themeIconDark;

export const themeScrollbarThumb = variables.themeScrollbarThumb;

export const scrim = variables.scrim;

export const fontPrimary = variables.fontPrimary;
export const fontExternalPrimary = variables.fontExternalPrimary;
export const fontSecondary = variables.fontSecondary;
export const fontTertiary = variables.fontTertiary;
export const fontTitle = variables.fontTitle;

export default cssValue;
