import { takeLatest, put, call, select } from 'redux-saga/effects';

import { TAction } from 'shared/types';
import ConversationService from 'shared/services/ConversationService';
import { IChannel } from 'shared/interfaces/channel.interface';
import { getChannelsSelector, setChannels } from 'store/channel/channel.redux';
import { IConversation } from 'shared/interfaces/conversation.interface';
import {
  getConversationToken,
  setConversationToken,
  getConversationTokenFail,
  getConversation,
  setSelectedConversation,
  getConversationFail,
  upgradeTwilioConversation,
  refreshTwilioConversation,
  getConversationByParticipants
} from './conversation.redux';

function* getToken({ payload }: TAction<{ userToken: string }>) {
  try {
    if (payload) {
      const result: string = yield call(
        ConversationService.getConversationToken,
        payload?.userToken
      );

      yield put(setConversationToken(result));
    }
  } catch (error) {
    yield put(getConversationTokenFail());
    console.log('getConversationToken error', error);
  }
}

function* getConversationSaga({
  payload
}: TAction<{ conversationId: string }>) {
  try {
    if (payload) {
      const result: string = yield call(
        ConversationService.getConversation,
        payload?.conversationId
      );

      yield put(setSelectedConversation(result));
    }
  } catch (error) {
    yield put(getConversationFail());
    console.log('getConversation error', error);
  }
}

function* getConversationByParticipantsSaga({
  payload
}: TAction<{ patientId: string; providerId: string; token?: string }>) {
  try {
    if (payload) {
      const result: string = yield call(
        ConversationService.getConversationByParticipants,
        payload.patientId,
        payload.providerId,
        payload.token
      );

      yield put(setSelectedConversation(result));
    }
  } catch (error) {
    yield put(getConversationFail());
    console.log('getConversationByParticipants error', error);
  }
}

function* upgradeTwilioConversationSaga({
  payload
}: TAction<{ channelId: string }>) {
  try {
    if (payload) {
      const result: string = yield call(
        ConversationService.upgradeTwilioConversation,
        payload?.channelId
      );

      yield put(setSelectedConversation(result));
    }
  } catch (error) {
    yield put(getConversationFail());
    console.log('upgradeTwilioConversation error', error);
  }
}

function* refreshTwilioConversationSaga({
  payload
}: TAction<{ conversationId: string }>) {
  try {
    if (payload) {
      const conversation: IConversation = yield call(
        ConversationService.refreshTwilioConversation,
        payload?.conversationId
      );

      yield put(setSelectedConversation(conversation));

      const channels: IChannel[] = yield select(getChannelsSelector);

      const updatedChannels = channels.map((channel) => {
        if (channel.conversation.id === payload.conversationId) {
          return {
            ...channel,
            conversation: conversation
          };
        } else {
          return channel;
        }
      });

      yield put(setChannels(updatedChannels));
    }
  } catch (error) {
    yield put(getConversationFail());
    console.log('getConversation error', error);
  }
}

function* Conversation() {
  yield takeLatest(getConversationToken.type, getToken);
  yield takeLatest(getConversation.type, getConversationSaga);
  yield takeLatest(
    getConversationByParticipants.type,
    getConversationByParticipantsSaga
  );
  yield takeLatest(
    upgradeTwilioConversation.type,
    upgradeTwilioConversationSaga
  );
  yield takeLatest(
    refreshTwilioConversation.type,
    refreshTwilioConversationSaga
  );
}

export default Conversation;
