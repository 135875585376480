import { http } from '../utils/axios';
import { ILabOrder } from 'shared/interfaces/labOrder.interface';

class LabOrderService {
  private static instance: LabOrderService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): LabOrderService {
    if (!LabOrderService.instance) {
      LabOrderService.instance = new LabOrderService();
    }

    return LabOrderService.instance;
  }

  getLabOrders = async (payload: { affiliate?: string }) => {
    const { data } = await http<ILabOrder[]>({
      url: '/labOrders',
      method: 'GET',
      params: { ...payload, getAll: true }
    });

    return data;
  };

  getLabOrder = async (id: string) => {
    const { data } = await http<ILabOrder>({
      url: `/labOrders/${id}`,
      method: 'GET'
    });

    return data;
  };
}

const instance = LabOrderService.getInstance();

export default instance;
