import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOVSAffiliateSelector } from 'store/onlineVisit/onlineVisit.redux';
import { getUserSelector } from 'store/user/user.redux';

export const useIntegrations = () => {
  const [enabledIntegrations, setEnabledIntegrations] = useState([]);
  const [integrationAccessLevel, setIntegrationAccessLevel] = useState({
    authenticated: false,
    user: null
  });
  const affiliate = useSelector(getOVSAffiliateSelector);
  const user = useSelector(getUserSelector);
  const getIntegration = (integrationKey: any) => {
    return enabledIntegrations.find(
      (x: any) => x.integration.title === integrationKey
    );
  };
  useEffect(() => {
    if (affiliate?.integrationConfigurations.length) {
      setEnabledIntegrations(affiliate?.integrationConfigurations);
    }
  }, [affiliate?.integrationConfigurations.length]);

  useEffect(() => {
    setIntegrationAccessLevel({
      authenticated: user?.id && true,
      user
    });
  }, [user?.id]);

  return {
    enabledIntegrations,
    getIntegration,
    integrationAccessLevel
  };
};
