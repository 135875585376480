import { SvgIconType } from './svg.interface';

const Block: SvgIconType = ({
  width = 12,
  height = 12,
  className = '',
  color = '#4F566B'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM4.52347 12.8907L12.8907 4.52347C13.5892 5.50428 14 6.70417 14 8C14 11.3137 11.3137 14 8 14C6.70417 14 5.50428 13.5892 4.52347 12.8907ZM2 8C2 9.29583 2.41079 10.4957 3.10925 11.4765L11.4765 3.10925C10.4957 2.41079 9.29583 2 8 2C4.68629 2 2 4.68629 2 8Z"
      fill={color}
    />
  </svg>
);

export default Block;
