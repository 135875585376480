import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs from 'shared/utils/dayjsConfig';
import { Client, Conversation } from '@twilio/conversations';

import {
  getItemFromLocalStorage,
  getTokenFromLocalStorage
} from 'shared/utils/local-storage';
import { IChannel } from 'shared/interfaces/channel.interface';
import ConversationService from 'shared/services/ConversationService';
import { getUserData, getUserSelector } from 'store/user/user.redux';
import { getChannelsSelector, getChannels } from 'store/channel/channel.redux';
import {
  getConversationTokenSelector,
  getConversationToken,
  setConversationToken,
  setTwilioConversationObjects,
  setSelectedConversation,
  getSelectedConversationSelector,
  refreshTwilioConversation
} from 'store/conversation/conversation.redux';
import { IConversation } from 'shared/interfaces/conversation.interface';
import { ROUTES } from 'pages';
import { Urls } from 'shared/constants/urls';
import { USER_PROGRESS_DATA } from 'pages/OnlineVisit/constants';

export const useTwilioClient = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const userAccessToken = getTokenFromLocalStorage();
  const patientToken = getItemFromLocalStorage(USER_PROGRESS_DATA.TOKEN);
  const userToken = userAccessToken || patientToken;
  const channels = useSelector(getChannelsSelector);
  const conversationToken = useSelector(getConversationTokenSelector);
  const selectedConversation = useSelector(getSelectedConversationSelector);
  const [twilioClient, setTwilioClient] = useState<Client | null>(null);
  const [eventListener, setEventListener] = useState(false);
  const { pathname } = useLocation();

  const unprotectedRoute = useMemo(() => {
    return ROUTES.find((el) => {
      // here we split the string to get path without dynamic values.
      // for example, instead of '/affiliate/externalVisit/:id' => 'externalVisit'
      const splittedPath = el.path.split('/');
      const elementPath = splittedPath[3] ? splittedPath[2] : splittedPath[1];
      return (
        (pathname.includes(elementPath) && !el.protected) ||
        (!userToken && el.path === Urls.DashBoard)
      );
    });
  }, [pathname, userToken]);

  useEffect(() => {
    // check if it is in external visit flow,
    // where the user does not exist yet and it is not necessary to make the call
    if (!user && !unprotectedRoute) {
      dispatch(getUserData());
    }
  }, [dispatch, user, unprotectedRoute]);

  useEffect(() => {
    if (
      !pathname.includes('messages') &&
      !pathname.includes('channels') &&
      !pathname.includes('channel')
    ) {
      dispatch(setSelectedConversation(null));
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    if (!conversationToken && userToken) {
      dispatch(getConversationToken({ userToken }));
    }
  }, [dispatch, conversationToken, userToken]);

  // Initialize Twilio
  useEffect(() => {
    const initializeTwilio = async () => {
      try {
        // Initialize twilio for the current user (user is defined by his token)
        const client = new Client(conversationToken);

        // Store the client for future use
        setTwilioClient(client);

        return client;
      } catch (error) {
        console.log('initializeTwilio Error', error);
      }
    };

    if (conversationToken) {
      initializeTwilio();
      if (userAccessToken) {
        dispatch(getChannels());
      }
    }
  }, [dispatch, conversationToken]);

  useEffect(() => {
    if (twilioClient && channels?.length) {
      // Get conversations for Active Tabs for counting a messages count
      // find consultations for which we do not have a conversation
      const getUpdatedConversations = () => {
        Promise.all(
          channels.map(async (channel: IChannel) => {
            try {
              const conversation = await twilioClient.peekConversationBySid(
                channel.conversation.sid
              );
              let lastMessage;
              let unreadCount = await conversation.getUnreadMessagesCount();

              // Null means that no the recipient has not read any messages
              if (unreadCount === null) {
                const convMessages = await conversation.getMessages();
                unreadCount = ([...convMessages.items] || []).length;
              }
              if (unreadCount !== null) {
                if (unreadCount >= 0) {
                  if (conversation.sid === selectedConversation?.sid) {
                    // Mark all messages as read when new messages are added
                    await conversation.setAllMessagesRead();
                    unreadCount = 0;
                  }
                  const convMessages = await conversation.getMessages();
                  lastMessage = ([...convMessages.items] || []).pop();
                }
              }
              return {
                conversation: conversation.sid,
                channel: channel.id,
                unreadCount: unreadCount || 0,
                lastMessage: {
                  text: lastMessage?.body,
                  timestamp: lastMessage?.dateCreated
                    ? dayjs(lastMessage?.dateCreated).format('MMM D, YYYY')
                    : '',
                  time: lastMessage?.dateCreated
                    ? new Date(lastMessage?.dateCreated).toISOString()
                    : null
                },
                state: 'active'
              };
            } catch (err) {
              return {
                conversation: '',
                channel: channel.id,
                unreadCount: 0,
                lastMessage: null,
                state: 'closed'
              };
            }
          })
        ).then((conversations) => {
          conversations = conversations.filter((conversation) => conversation);
          // Conversations with unread messages should come before conversations with no unread messages
          conversations.sort((a, b) => {
            // Sort conversations based on unread message count
            if (a.unreadCount > 0 && b.unreadCount === 0) {
              // Conversations with unread messages should come before conversations with no unread messages
              return -1;
            } else if (a.unreadCount === 0 && b.unreadCount > 0) {
              // Conversations with no unread messages should come after conversations with unread messages
              return 1;
            } else {
              // Sort based on the last message time
              const valueA = a.lastMessage?.time
                ? new Date(a.lastMessage.time).getTime()
                : 0;
              const valueB = b.lastMessage?.time
                ? new Date(b.lastMessage.time).getTime()
                : 0;
              return valueB - valueA; // Sort in descending order of time.
            }
          });
          dispatch(setTwilioConversationObjects(conversations));
        });
      };

      getUpdatedConversations();

      // Execute every 30 seconds
      const intervalGetUpdatedConversations = setInterval(
        getUpdatedConversations,
        30000
      );

      const intervalGetChannels = setInterval(
        () => dispatch(getChannels()),
        200000
      );

      // Cleanup
      return () => {
        clearInterval(intervalGetUpdatedConversations);
        clearInterval(intervalGetChannels);
      };
    }
  }, [dispatch, channels, twilioClient, selectedConversation]);

  // Refresh token
  useEffect(() => {
    if (twilioClient && !eventListener && userToken) {
      setEventListener(true);
      // Add an even listener that
      // Renews the token if the sessions is
      // Active and it's about to expire (3 minutes before expiration)
      twilioClient.on('tokenAboutToExpire', async () => {
        const newToken = await ConversationService.getConversationToken(
          userToken
        );
        dispatch(setConversationToken({ conversationToken: newToken }));
        const newClient = await twilioClient.updateToken(newToken);
        setTwilioClient(newClient);
      });
    }
  }, [dispatch, twilioClient, eventListener]);

  useEffect(() => {
    // Cleanup all the event listeners on unmount
    return () => {
      if (twilioClient?.removeAllListeners) {
        twilioClient.removeAllListeners();
      }
    };
  }, [twilioClient]);

  useEffect(() => {
    // Cleanup all data if the user logged out
    if (!userToken) {
      setTwilioClient(null);
      dispatch(setConversationToken(''));
      dispatch(setSelectedConversation(null));
      dispatch(setTwilioConversationObjects([]));
    }
  }, [userToken]);

  return twilioClient;
};

export const useChatInstance = (twilioClient: Client | null) => {
  const dispatch = useDispatch();
  const selectedConversation = useSelector(getSelectedConversationSelector);
  const [chatInstance, setChatInstance] = useState<Conversation | null>(null);

  const getConversationFromTwilio = async (conversation: IConversation) => {
    try {
      const conversationFromTwilio = await twilioClient?.peekConversationBySid(
        conversation.sid
      );
      if (conversationFromTwilio) {
        if (conversationFromTwilio?.state?.current === 'closed') {
          dispatch(
            refreshTwilioConversation({ conversationId: conversation.id })
          );
        } else setChatInstance(conversationFromTwilio);
      }
    } catch (error) {
      console.log('Twilio peekConversationBySid Error: ', error);
      // If there is a selectedConversation but no conversation got returned
      // from the Twilio Client, it is because it is closed or removed.
      if (conversation) {
        dispatch(
          refreshTwilioConversation({ conversationId: conversation.id })
        );
      }
    }
  };

  useEffect(() => {
    if (!twilioClient) return;
    if (selectedConversation) {
      getConversationFromTwilio(selectedConversation);
    }
  }, [twilioClient, selectedConversation]);

  return chatInstance;
};
