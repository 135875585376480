import { takeLatest, put, call } from 'redux-saga/effects';

import {
  getAffiliateConfig,
  getComponentConfig
} from 'shared/helpers/affiliateConfigHelpers';
import {
  setAppConfig,
  setAppTheme,
  setWhiteLabelAffiliate,
  setAffiliateStylesConfig,
  getAffiliate
} from './app.redux';
import { APP_LANGUAGES, IframeComponents } from 'shared/enums';
import { getAffiliateTheme } from 'shared/themes/baseTheme';
import { generalInfo } from 'shared/constants/general-info';
import { IAffiliate } from 'shared/interfaces/affiliate.interface';
import { initialStore } from 'store/initial-store';
import { TAction } from 'shared/types';
import { IAppConfig } from 'shared/interfaces/appConfig.interface';
import AffiliateService from 'shared/services/AffiliateService';
import { setOVSAffiliate } from 'store/onlineVisit/onlineVisit.redux';
import {
  LANGUAGE,
  WHITE_LABEL_AFFILIATE,
  getItemFromLocalStorage,
  setItemToLocalStorage
} from 'shared/utils/local-storage';
import { checkIsOnlineVisit } from 'shared/utils/checkIsOnlineVisit';
import { changeLanguage } from 'shared/helpers/changeLanguage';

function* getAffiliateSaga({ payload }: TAction<{ affiliateId: string }>) {
  try {
    if (payload) {
      const result: IAffiliate = yield call(
        {
          context: AffiliateService,
          fn: AffiliateService.getAffiliate
        },
        payload
      );

      if (result.whiteLabeling && result.frontendConfiguration) {
        setItemToLocalStorage(WHITE_LABEL_AFFILIATE, result.id);
        yield put(setWhiteLabelAffiliate(result));
      }

      if (!getItemFromLocalStorage(LANGUAGE)) {
        changeLanguage(result?.appLanguage || APP_LANGUAGES.EN);
      }

      //ovs
      yield put(setOVSAffiliate(result));
    }
  } catch (error) {
    console.log('getAffiliateSaga error', error);
  }
}

function* setWhiteLabelAffiliateSaga({ payload }: TAction<IAffiliate>) {
  try {
    const whiteLabelAffiliate = { ...payload };
    const { url, frontendConfiguration } = whiteLabelAffiliate;
    if (frontendConfiguration) {
      const affiliateConfiguration = getAffiliateConfig(frontendConfiguration);

      const {
        appName,
        appLogo,
        smallAppLogo,
        embeddedStyles,
        progressBar,
        logoWithName
      } = affiliateConfiguration;
      const affiliateConfig: IAppConfig = {
        appName: appName,
        companyUrl: url || generalInfo.companyUrl,
        logo: appLogo,
        smallLogo: smallAppLogo,
        logoWithName,
        embeddedStyles: embeddedStyles,
        progressBar: progressBar
      };
      yield put(setAppConfig(affiliateConfig));

      const affiliateStylesConfig = {
        ...initialStore.app.affiliateStylesConfig
      };
      if (affiliateConfig?.embeddedStyles) {
        for (const component of Object.values(IframeComponents)) {
          yield call(
            getComponentConfig,
            affiliateConfig.embeddedStyles,
            component,
            affiliateStylesConfig
          );
        }

        if (affiliateStylesConfig[IframeComponents.Global]?.variables) {
          const isOnlineVisit = checkIsOnlineVisit();
          const appTheme = getAffiliateTheme({
            ...affiliateStylesConfig[IframeComponents.Global]?.variables,
            ...(isOnlineVisit &&
              affiliateStylesConfig[IframeComponents.Visit]?.variables)
          });
          yield put(setAppTheme(appTheme));
        }
      }
      yield put(setAffiliateStylesConfig(affiliateStylesConfig));
    }
  } catch (error) {
    console.log('setWhiteLabelAffiliateSaga error', error);
  }
}

function* App() {
  yield takeLatest(getAffiliate.type, getAffiliateSaga);
  yield takeLatest(setWhiteLabelAffiliate.type, setWhiteLabelAffiliateSaga);
}

export default App;
