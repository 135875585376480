export enum TableColumnType {
  Text = 'text',
  Link = 'link',
  Render = 'render',
  LinkTextTag = 'link_text_tag',
  LinkTextTooltip = 'link_text_tooltip',
  Components = 'component',
  Action = 'action'
}
export enum TabsPosition {
  vertical = 'vertical',
  horizontal = 'horizontal'
}
export enum FormItemType {
  Input = 'input',
  Email = 'email',
  Password = 'password',
  Checkbox = 'checkbox',
  DatePicker = 'datepicker',
  RangePicker = 'rangepicker',
  CodeInput = 'codeInput',
  CheckboxAgreement = 'checkboxagreement',
  ReCaptcha = 'reCaptcha',
  Radio = 'radio',
  TextArea = 'textarea',
  Select = 'select',
  Text = 'text',
  Phone = 'phone'
}
export enum ErrorMessages {
  Required = 'This field is required',
  MaxLength = 'This field is too long',
  MinLength = 'This field is too short',
  PatternLatin = 'This field can contain only latin letters',
  PatternEmail = 'Please type a valid email address',
  PatternPhone = 'Invalid phone number',
  PatternNumbers = 'Only numbers are valid',
  Password = 'The passwords do not match',
  CaptchaRequired = 'Please verify you’re not a robot',
  OptionRequired = 'Please select an option'
}
export enum PaymentMechanism {
  PatientPay = 'patient_pay',
  AffiliatePay = 'affiliate_pay'
}

export enum PaymentBrand {
  Visa = 'visa',
  MasterCard = 'mastercard'
}

export enum ModalType {
  Confirmation = 'confirmation',
  Information = 'information',
  Form = 'form'
}

//Low Level State
export enum ComponentState {
  LOADING = 'loading',
  NOTFOUND = '404',
  DEFAULT = 'default',
  SENDING = 'sending',
  DONE = 'done'
}

export enum TwoFATypes {
  None = 'none',
  Sms = 'sms',
  Email = 'email'
}

export enum VISIT_STATUSES {
  ACTIVE = 'active',
  REASSIGNED = 'reassigned',
  EXPIRED = 'expired',
  COMPLETED = 'completed'
}

export enum VISIT_TYPES {
  SYNCHRONOUS = 'synchronous',
  ASYNCHRONOUS = 'asynchronous'
}

export enum VISIT_OUTCOMES {
  ASYNCHRONOUS = 'asynchronous',
  SYNCHRONOUS_CONNECTED_PHONE = 'synchronous_connected_phone',
  SYNCHRONOUS_UNCONNECTED = 'synchronous_unconnected',
  SYNCHRONOUS_CONNECTED_VIDEO = 'synchronous_connected_video',
  NO_ESTABLISHED_RELATIONSHIP = 'no_established_relationship',
  NO_OUTCOME = 'no_outcome'
}

export enum AFFILIATE_STATUSES {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum MessageTypes {
  Sent = 'sent',
  Received = 'received'
}
export enum PaymentIntentStatuses {
  RequiresPaymentMethod = 'requires_payment_method',
  RequiresSource = 'requires_source',
  RequiresConfirmation = 'requires_confirmation',
  RequiresAction = 'requires_action',
  RequiresSourceAction = 'requires_source_action',
  Processing = 'processing',
  Succeeded = 'succeeded',
  Canceled = 'canceled',
  Success = 'success'
}

export enum PharmacyKeys {
  Cleopatra = 'cleo',
  GoGoMeds = 'ggm',
  Olympia = 'oly',
  APSPharmacy = 'aps',
  TriadPharmacy = 'triad',
  EmpowerPharmacy = 'empower'
}

export enum OrderStatuses {
  IntakeForm = 'intake_form',
  MedicalSpecialist = 'medical_specialist',
  Consultation = 'consultation',
  Prescription = 'prescription',
  Shipped = 'shipped',
  Completed = 'completed',
  RequiresOrderProcessing = 'requires_order_processing',
  RequiresAdminReview = 'requires_admin_review',
  Cancelled = 'cancelled',
  Started = 'started'
}

export enum UserRoles {
  Patient = 'patient',
  Provider = 'provider',
  AffiliateAdmin = 'affiliate-admin'
}

export enum EntityModels {
  Patient = 'Patient',
  Practitioner = 'Practitioner',
  User = 'User',
  Affiliate = 'Affiliate',
  Order = 'Order',
  Questionnaire = 'Questionnaire',
  QuestionnaireInstance = 'QuestionnaireInstance',
  Location = 'Location',
  FormResponse = 'FormResponse',
  Address = 'Address',
  Prescription = 'Prescription',
  PrescriptionFulfillment = 'PrescriptionFulfillment',
  Note = 'Note',
  Consultation = 'Consultation',
  Visit = 'Visit',
  EncoreVisit = 'EncoreVisit',
  Subscription = 'Subscription',
  ProductVariation = 'ProductVariation',
  Product = 'Product',
  Recommendation = 'Recommendation'
}

export enum PatientStatuses {
  Active = 'active'
}

export enum PatientRoles {
  Patient = 'patient'
}

export enum Genders {
  Male = 'Male',
  Female = 'Female',
  male = 'male',
  female = 'female'
}

export enum QuestionnaireInstanceStatuses {
  Filled = 'filled',
  InProgress = 'inprogress',
  ApprovedByProvider = 'approved_by_provider',
  Stale = 'stale',
  Expired = 'expired',
  Completed = 'completed'
}

export enum InputTypes {
  Text = 'text',
  Phone = 'phone',
  Password = 'password',
  Select = 'select',
  Date = 'date'
}

export enum ErrorMessagePatterns {
  REQUIRED = 'This field is required',
  MAX_LENGTH = 'This field is too long',
  MIN_LENGTH = 'This field is too short',
  PATTERN_LATIN = 'This field can contain only latin letters',
  PATTERN_EMAIL = 'Invalid email address',
  PATTERN_PHONE = 'Invalid phone number',
  PATTERN_NUMBERS = 'Only numbers are valid',
  PASSWORDS = 'The passwords do not match',
  DATE_OF_BIRTH = 'Please check the format of Birthday field MM/DD/YYYY',
  PASSWORDS_MATCH = 'Please make sure passwords match',
  MIN_HEIGHT_NUMBER = 'The height must be at least 20 inches',
  MAX_HEIGHT_NUMBER = 'The height must be less than 100 inches',
  MIN_WEIGHT_NUMBER = 'The weight must be at least 40 lbs',
  MAX_WEIGHT_NUMBER = 'The weight must be less than 660 lbs'
}

export enum ValidatedFields {
  USER_NAME = 'USER_NAME',
  FIRST_NAME = 'FIRST_NAME',
  MIDDLE_NAME = 'MIDDLE_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  PASSWORD = 'PASSWORD',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  ADDRESS1 = 'ADDRESS1',
  ADDRESS2 = 'ADDRESS2',
  CITY = 'CITY',
  ZIPCODE = 'ZIPCODE',
  STATE = 'STATE',
  GENDER = 'GENDER',
  HEIGHT = 'HEIGHT',
  WEIGHT = 'WEIGHT'
}

export enum LocationTypes {
  Question = 'question',
  Section = 'section',
  Informational = 'informational',
  Start = 'start',
  Task = 'task',
  Upload = 'upload'
}

export enum DataTypes {
  Input = 'input',
  Select = 'select',
  Radio = 'radio',
  Upload = 'upload',
  Table = 'table',
  Signature = 'signature',
  MultipleInput = 'multipleInput',
  CheckboxGroup = 'checkboxGroup',
  Agreement = 'agreement',
  Date = 'date'
}

export enum UniversalSelectModes {
  Single = 'single',
  Multiple = 'multiple'
}

export enum FormActions {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete'
}

export enum settingsFormType {
  User = 'user',
  Medications = 'medication',
  Allergies = 'allergie',
  BillingAddress = 'billingaddress',
  ShippingAddress = 'shippingAddress',
  Payment = 'payment'
}

export enum NotificationMessages {
  Error = 'error',
  Success = 'success',
  Information = 'information'
}

export enum ExternalQuestionnaireSources {
  TypeForm = 'typeform'
}

export enum TypeFormModes {
  Widget = 'widget',
  Popup = 'popup',
  Slider = 'slider',
  Sidetab = 'sidetab',
  Popover = 'popover'
}

export enum Periods {
  Years = 'years',
  Months = 'months',
  Days = 'days',
  Hours = 'hours'
}

export enum PaymentProcessors {
  AuthNet = 'authnet',
  Stripe = 'stripe'
}

export enum AuthNetEnvironment {
  Sandbox = 'SANDBOX',
  Production = 'PRODUCTION'
}

export enum EmbeddedElementTypes {
  Global = 'global',
  Component = 'component'
}

export enum IframeComponents {
  Global = 'global',
  Channels = 'channels',
  Channel = 'channel',
  Orders = 'orders',
  Order = 'order',
  Visit = 'visit',
  Questionnaire = 'questionnaire'
}

export enum VariableCategories {
  Surface = 'surface',
  TextIcons = 'text_icons',
  BrandColors = 'brand_colors'
}

export enum ProgressBarTypes {
  Default = 'default',
  CustomCommon = 'customCommon'
}

export enum BackgroundTypes {
  Default = 'default',
  Dual = 'dual'
}

export enum APP_LANGUAGES {
  EN = 'en',
  ES = 'es'
}

export enum ECOMMERCE_PLATFORMS {
  Woocommerce = 'woocommerce',
  Shopify = 'shopify',
  Bigcommerce = 'bigcommerce'
}

export interface IAffiliateStylesConfig {
  variables?: { [key: string]: string };
  styles?: string;
  dashboardImage?: string;
  completedScreenImage?: string;
}

export enum FacebookPixelEvents {
  PageView = 'PageView',
  Subscribe = 'Subscribe'
}

export enum AppEventTypes {
  OvsCompletion = 'ovs_completion'
}

export enum LabOrderStatuses {
  Pending = 'pending',
  InProgress = 'in_progress',
  Complete = 'complete'
}

export enum RequisitionStatuses {
  PendingApproval = 'pending_approval',
  InProgress = 'in_progress',
  Completed = 'completed'
}

export enum EventTypes {
  PrescriptionRenew = 'prescription_renew',
  SubscriptionsRenew = 'subscription_renew',
  FormStarted = 'form_started',
  ProductRequest = 'product_request',
  NewStatusSetToRequest = 'new_status_set_to_request',
  NoAvailableProviders = 'no_available_providers',
  ProviderAssigned = 'provider_was_assigned',
  ProviderReassigned = 'provider_was_reassigned',
  BillingDetailsCompleted = 'billing_details_completed',
  PrescriptionCancelled = 'prescription_cancelled',
  ShippingDetailsSet = 'shipping_details_set',
  ShipmentDelivered = 'shipment_delivered',
  PrescriptionSentToPharmacy = 'prescription_sent_to_pharmacy',
  GeneratingPrescription = 'generating_prescription',
  PharmacyFulfilledPrescription = 'pharmacy_fulfilled_prescription',
  PharmacyProcessingPrescription = 'pharmacy_processing_prescription',
  PharmacySentPrescription = 'pharmacy_sent_prescription',
  PharmacyRejectedPrescription = 'pharmacy_rejected_prescription',
  AddressAppendedToOrder = 'address_appended_to_order',
  AddressAppendedToSubscription = 'address_appended_to_subscription',
  OrderSubmitted = 'order_submitted',
  OrderPaymentCollected = 'order_payment_collected',
  OrderCreated = 'order_created',
  PrescriptionDocumentGenerated = 'prescription_document_generated',
  InitializePrescriptionRenewal = 'initialize_prescription_renewal',
  UnhandledEvent = 'unhandled_event',
  PrescriptionRefill = 'prescription_refill',
  ConsultationStatusUpdated = 'consultation_status_updated',
  SendConsultationsPrescriptions = 'send_consultations_prescriptions',
  RecommendationAccepted = 'recommendation_accepted',
  RecommendationRejected = 'recommendation_rejected',
  // AFFILIATE
  FormIntentResolved = 'form_intent_resolved',
  ExternalOrderReceivedWoocommerce = 'external_order_received',
  ExternalOrderReceivedWoocommerceError = 'external_order_received_error',
  ExternalOrderReceivedGroupon = 'external_order_received_groupon',
  AbandonedCart = 'abandoned_cart',
  ConsultationBilledToPatient = 'consultation-billed-to-patient',
  ConsultationBilledToAffiliate = 'consultation-billed-to-affiliate'
}

export enum LabVisitTypes {
  AppointmentOnsiteVisits = 'appointment_onsite',
  WalkInOnsiteVisits = 'walk_in_onsite',
  MobileVisits = 'mobile',
  PatientAdministeredAtHome = 'patient_administered_at_home'
}

export enum SampleTypes {
  Blood = 'Blood',
  Urine = 'Urine',
  Saliva = 'Saliva',
  Stool = 'Stool',
  Tissue = 'Tissue',
  Swab = 'Swab',
  Hair = 'Hair',
  Nail = 'Nail',
  Sputum = 'Sputum',
  Fluid = 'Fluid',
  CSF = 'Cerebrospinal Fluid (CSF)'
}

export enum LabTestResultType {
  QUANTITY = 'quantity',
  VALUE = 'value'
}
