import { themeTextSecondary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Search: SvgIconType = ({
  width = 12,
  height = 12,
  className = '',
  color = themeTextSecondary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.94172 7.05782C7.92064 7.03674 7.89843 7.01748 7.87529 7.00005C8.42453 6.26884 8.75 5.35993 8.75 4.375C8.75 1.95875 6.79125 0 4.375 0C1.95875 0 0 1.95875 0 4.375C0 6.79125 1.95875 8.75 4.375 8.75C5.35993 8.75 6.26885 8.42453 7.00006 7.87527C7.0175 7.89842 7.03676 7.92063 7.05784 7.9417L8.93328 9.81715C9.17736 10.0612 9.57309 10.0612 9.81716 9.81715C10.0612 9.57307 10.0612 9.17734 9.81716 8.93327L7.94172 7.05782ZM7.5 4.375C7.5 6.10089 6.10089 7.5 4.375 7.5C2.64911 7.5 1.25 6.10089 1.25 4.375C1.25 2.64911 2.64911 1.25 4.375 1.25C6.10089 1.25 7.5 2.64911 7.5 4.375Z"
      fill={color}
    />
  </svg>
);

export default Search;
