import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import {
  LANGUAGE,
  TEMPORARY_TOKEN,
  getItemFromLocalStorage,
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage
} from './local-storage';
import { Urls } from 'shared/constants/urls';

const defaultConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 30000,
  headers: {
    'content-type': 'application/json'
  }
};

export const instance = axios.create(defaultConfig);

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers?.Authorization) {
      return config;
    }
    const token = getTokenFromLocalStorage();
    const temporaryToken = getItemFromLocalStorage(TEMPORARY_TOKEN);
    const language = getItemFromLocalStorage(LANGUAGE);
    const output = {
      ...config,
      headers: { ...config.headers }
    };
    if (temporaryToken) {
      output.headers['Authorization'] = `Bearer ${temporaryToken}`;
    } else if (token) {
      output.headers['Authorization'] = `Bearer ${token}`;
    }
    output.headers['accept-language'] = language;
    return output;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    const path = window.location.pathname;
    if (error?.response?.status === 401 && path !== '/login') {
      removeTokenFromLocalStorage();
      window.location.href = Urls.LoginPage;
    }
    return Promise.reject(error);
  }
);

export const http = async <T>({
  url,
  method = 'GET',
  params,
  data,
  ...rest
}: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
  instance({ url, method, params, data, ...rest });

export const setupToken = (token: string) => ({
  headers: {
    Authorization: `Bearer ${token}`
  }
});
