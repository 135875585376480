import { http } from 'shared/utils/axios';
import {
  TAuthData,
  TResponseAuth,
  IResponseOTP,
  IResponseLogin,
  TResponseCheckUserExistingAndSendOtp,
  TCheckUserExistingAndSendOtp
} from '../types';
import {
  WHITE_LABEL_AFFILIATE,
  getItemFromLocalStorage,
  getOTPTokenFromLocalStorage,
  removeOTPTokenFromLocalStorage,
  setOTPTokenToLocalStorage,
  setTokenToLocalStorage
} from 'shared/utils/local-storage';
import { NotificationMessages, TwoFATypes } from 'shared/enums';
import { openNotificationMessage } from 'shared/utils/NotificationMessages';
import i18n, { I18N_NAMESPACE } from 'i18n';

class AuthService {
  private static instance: AuthService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  }

  login = async (payload: TAuthData) => {
    const disabled2FA = `${process.env.REACT_APP_ENABLE_2FA}` === 'false';
    const url = disabled2FA ? '/auth/client' : '/auth/login';
    const { data } = await http<IResponseLogin>({
      url,
      method: 'POST',
      data: payload,
      params: { access_token: process.env.REACT_APP_MASTER_KEY },
      auth: {
        username: payload.username,
        password: payload.password
      }
    });

    if (disabled2FA) {
      setTokenToLocalStorage(data.token);
    } else {
      setOTPTokenToLocalStorage(data.token);
    }
    return data;
  };

  changeType2fa = async (type: TwoFATypes) => {
    await http<IResponseOTP>({
      url: '/two-factor/actions/change-type',
      method: 'PATCH',
      params: { access_token: getOTPTokenFromLocalStorage(), type }
    });
  };

  getOtpCode = async () => {
    const whiteLabelAffiliate = getItemFromLocalStorage(WHITE_LABEL_AFFILIATE);

    const resp = await http<IResponseOTP>({
      url: 'auth/client/otp-code',
      method: 'GET',
      params: {
        affiliate: whiteLabelAffiliate,
        access_token: getOTPTokenFromLocalStorage()
      }
    });

    if (resp.status === 200) {
      openNotificationMessage({
        type: NotificationMessages.Success,
        title: i18n.t('login.success', {
          defaultValue: 'Success',
          ns: I18N_NAMESPACE.login
        }),
        message: i18n.t('login.otpSuccessfullySent', {
          defaultValue: 'The OTP code was successfully sent',
          ns: I18N_NAMESPACE.login
        })
      });
    }
  };

  verifyOtpCode = async (code: string) => {
    const { data } = await http<TResponseAuth>({
      url: 'auth/client/otp-code',
      method: 'POST',
      params: { access_token: getOTPTokenFromLocalStorage(), code }
    });

    setTokenToLocalStorage(data.token);
    removeOTPTokenFromLocalStorage();
    return data.user;
  };

  checkUserExistingAndSendOtp = async ({
    email,
    phone,
    affiliate
  }: TCheckUserExistingAndSendOtp) => {
    const { data } = await http<TResponseCheckUserExistingAndSendOtp>({
      url: 'auth/client/checkUserAndSendOtp',
      method: 'POST',
      data: { email, phone, affiliate },
      params: { access_token: process.env.REACT_APP_MASTER_KEY }
    });
    if (data) {
      if (data.token) setOTPTokenToLocalStorage(data.token);
      openNotificationMessage({
        type: NotificationMessages.Success,
        title: i18n.t('login.success', {
          defaultValue: 'Success',
          ns: I18N_NAMESPACE.login
        }),
        message: i18n.t('login.otpSuccessfullySent', {
          defaultValue: 'The OTP code was successfully sent',
          ns: I18N_NAMESPACE.login
        })
      });
    }
    return data;
  };

  verifyOtpCodeUnauthorized = async ({
    code,
    email,
    phone
  }: {
    code: string;
    email?: string;
    phone?: string;
  }) => {
    const { data } = await http<TResponseAuth>({
      url: 'auth/client/verify-otp',
      method: 'POST',
      data: { code, email, phone },
      params: { access_token: process.env.REACT_APP_MASTER_KEY }
    });

    setOTPTokenToLocalStorage(data.token);
    return data.user;
  };
}
const instance = AuthService.getInstance();

export default instance;
