import { http } from '../utils/axios';
import { TProspectPatientSessionValidate } from 'shared/interfaces/prospect-patient-session.interface';

class ProspectPatientSessionService {
  private static instance: ProspectPatientSessionService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): ProspectPatientSessionService {
    if (!ProspectPatientSessionService.instance) {
      ProspectPatientSessionService.instance =
        new ProspectPatientSessionService();
    }

    return ProspectPatientSessionService.instance;
  }

  validateProspectPatientSession = async (
    payload: TProspectPatientSessionValidate
  ) => {
    const { data } = await http<{ link: string }>({
      url: '/prospectPatientSessions/validate',
      method: 'POST',
      data: payload
    });
    return data;
  };
}

const instance = ProspectPatientSessionService.getInstance();

export default instance;
