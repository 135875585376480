import { FC } from 'react';
import { Spin } from 'antd';

import classes from './styles.module.scss';
import { classNames } from 'shared/utils/class-names';

interface ISpinner {
  isSmall?: boolean;
  classNameProp?: string;
  centerOfPage?: boolean;
}

export const Spinner: FC<ISpinner> = ({
  isSmall = false,
  classNameProp,
  centerOfPage
}) => (
  <div
    className={classNames(classes.spinner, classNameProp, {
      [classes.center]: centerOfPage
    })}
  >
    <Spin size={isSmall ? 'small' : 'large'} />
  </div>
);
