import { themeTextSecondary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Shipping: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextSecondary
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.65073 1.57957L7.63663 1.58757L6.16988 2.39868L11.3751 5.27819L13.2058 4.2654L8.36412 1.58757C8.25273 1.52597 8.12742 1.49365 8 1.49365C7.87822 1.49365 7.75835 1.52318 7.65073 1.57957ZM14 5.53474L12.125 6.57199V8.74639C12.125 9.15885 11.7892 9.49322 11.375 9.49322C10.9608 9.49322 10.625 9.15885 10.625 8.74639V7.40179L8.74998 8.43907V14.1989L13.6139 11.5074C13.7309 11.4427 13.8285 11.348 13.8963 11.2332C13.9641 11.1185 13.9999 10.9879 14 10.8547V5.53474ZM7.24997 14.1989V8.43907L2 5.53477V10.8552C2.00014 10.9882 2.03597 11.1188 2.10377 11.2334C2.17161 11.3481 2.26901 11.4427 2.38587 11.5073L7.24997 14.1989ZM2.79417 4.26543L7.99997 7.1453L9.83071 6.13253L4.62525 3.25285L2.79417 4.26543ZM6.88728 0.293436L4.28169 1.73432C4.26727 1.74169 4.25308 1.74954 4.23913 1.75785L1.271 3.39922C1.03729 3.52863 0.842581 3.7179 0.707023 3.94743C0.571469 4.17694 0.499994 4.4384 0.5 4.70467V10.856C0.500296 11.2554 0.607772 11.6474 0.811304 11.9915C1.01484 12.3355 1.30703 12.6192 1.65763 12.8131L6.90763 15.7182C7.2418 15.903 7.61775 16 8 16C8.38225 16 8.75838 15.9029 9.09256 15.7181L14.3424 12.8131C14.6931 12.6191 14.9854 12.3353 15.189 11.991C15.3925 11.6467 15.4999 11.2543 15.5 10.8547V4.70464C15.5 4.43837 15.4285 4.17694 15.293 3.94743C15.1574 3.7179 14.9624 3.52845 14.7287 3.39904L9.09237 0.281769C8.7582 0.0969727 8.38225 0 8 0C7.61775 0 7.2418 0.0969727 6.90763 0.281769C6.90076 0.285564 6.89398 0.289454 6.88728 0.293436ZM2.84489 7.88095C3.04654 7.52067 3.50332 7.39138 3.86514 7.59218L6.11514 8.84087C6.47695 9.04167 6.60679 9.49651 6.40514 9.85679C6.20349 10.2171 5.74671 10.3464 5.38489 10.1456L3.13489 8.89688C2.77308 8.69608 2.64324 8.24124 2.84489 7.88095Z"
      fill={color}
    />
  </svg>
);

export default Shipping;
