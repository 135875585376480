import { themePrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Charge: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themePrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1H14C14.5523 1 15 1.44772 15 2V3H1V2C1 1.44772 1.44772 1 2 1ZM1 4H15C15.5523 4 16 4.40701 16 4.90909V13.0909C16 13.593 15.5523 14 15 14H1C0.447715 14 0 13.593 0 13.0909V4.90909C0 4.40701 0.447715 4 1 4ZM10.5 9C10.5 10.3807 9.38071 11.5 8 11.5C6.61929 11.5 5.5 10.3807 5.5 9C5.5 7.61929 6.61929 6.5 8 6.5C9.38071 6.5 10.5 7.61929 10.5 9Z"
      fill={color}
    />
  </svg>
);
export default Charge;
