export const ACCESS_TOKEN_NAME = 'access_token';
export const OTP_ACCESS_TOKEN_NAME = 'access_token_OTP';
export const TEMPORARY_TOKEN = 'temporary_token';
export const WHITE_LABEL_AFFILIATE = 'white_label_affiliate';
export const IFRAME = 'iframe';
export const POTENTIAL_USER = 'potentialUser';
export const EMAIL = 'email';
export const PHONE = 'phone';
export const STARTED_FROM_DASHBOARD = 'startedFromDashboard';
export const REDIRECT_URL = 'redirectUrl';
export const ATTACH_DEMOGRAPHICS_TO_REDIRECT_URL =
  'attachDemographicsToRedirectUrl';
export const LANGUAGE = 'language';
export const ADDED_ADDRESS = 'addedAddress';

export const setTokenToLocalStorage = (token: string): void => {
  localStorage.setItem(ACCESS_TOKEN_NAME, token);
};

export const getTokenFromLocalStorage = (): string | null => {
  return localStorage.getItem(ACCESS_TOKEN_NAME);
};

export const removeTokenFromLocalStorage = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_NAME);
};

export const setOTPTokenToLocalStorage = (token: string): void => {
  localStorage.setItem(OTP_ACCESS_TOKEN_NAME, token);
};

export const getOTPTokenFromLocalStorage = (): string | null => {
  return localStorage.getItem(OTP_ACCESS_TOKEN_NAME);
};

export const removeOTPTokenFromLocalStorage = (): void => {
  localStorage.removeItem(OTP_ACCESS_TOKEN_NAME);
};

export const setItemToLocalStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getItemFromLocalStorage = (key: string) => {
  const saved: string | null = localStorage.getItem(key);
  return saved ? JSON.parse(saved) : null;
};

export const removeItemsFromLocalStorage = (itemsToRemove: string[]) => {
  for (const item of itemsToRemove) {
    localStorage.removeItem(item);
  }
};

export const removeItemsFromLocalStorageWithExceptions = (
  excludedLocalStorageKeys: string[]
) => {
  // Iterate through all localStorage items
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    // Clear all localStorage items except excludedLocalStorageKeys
    if (key && !excludedLocalStorageKeys.includes(key)) {
      // Remove the item
      localStorage.removeItem(key);
    }
  }
};
