import { createSlice } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from '../initial-store';

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: initialStore.tasks,
  reducers: {
    getTasksData: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setTasksData: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    getTasksFail: (state) => {
      state.isLoading = false;
    }
  }
});

export const { getTasksData, setTasksData, getTasksFail } = tasksSlice.actions;

export const { reducer } = tasksSlice;

// Selectors
export const getTasksSelector = (state: IState) => state && state.tasks.data;

export const getTasksLoadingSelector = (state: IState) =>
  state && state.tasks.isLoading;
