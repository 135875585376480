import { createAction, createSlice } from '@reduxjs/toolkit';
import { IState } from 'shared/interfaces/state.interface';
import { TUploadPatientFile } from 'shared/types';
import { initialStore } from 'store/initial-store';

export const uploadPatientFile = createAction(
  'patient/uploadPatientFile',
  (payload: TUploadPatientFile) => ({ payload })
);

const patientSlice = createSlice({
  name: 'patient',
  initialState: initialStore.patient,
  reducers: {
    createPatient: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    getPatientData: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    updatePatientData: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    createPatientAddress: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    updatePatientAddress: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setDefaultPatientAddress: (state, _data) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setPatientAddressData: (state, { payload }) => {
      if (state.data) {
        const newAddressIndex = state.data.addresses.findIndex(
          (address) => address.id === payload.id
        );
        if (newAddressIndex >= 0) {
          if (payload?.defaultAddress) {
            state.data.addresses = state.data.addresses.map((address) => ({
              ...address,
              defaultAddress: false
            }));
          }
          state.data.addresses[newAddressIndex] = payload;
        } else {
          state.data.addresses = payload;
        }
      }
      state.isLoading = false;
    },
    setPatientData: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
    actionFail: (state) => {
      state.isLoading = false;
    },
    setPatientFiles: (state, { payload }) => {
      if (state?.data?.files) {
        state.data.files = [...state.data.files, payload];
      }
    }
  }
});

export const {
  createPatient,
  getPatientData,
  actionFail,
  setPatientData,
  updatePatientData,
  setPatientAddressData,
  updatePatientAddress,
  createPatientAddress,
  setDefaultPatientAddress,
  setPatientFiles
} = patientSlice.actions;

export const { reducer } = patientSlice;

// Selectors
export const getPatientSelector = (state: IState) =>
  state && state.patient.data;
export const getPatientLoadingSelector = (state: IState) =>
  state && state.patient.isLoading;
