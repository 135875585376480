import { themeTextSecondary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const FileDocument: SvgIconType = ({
  width = 9,
  height = 8,
  className = '',
  color = themeTextSecondary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.85343 0.600006C8.01551 0.600006 8.17037 0.663441 8.28122 0.775249L11.4414 3.96257C11.5433 4.06534 11.6 4.20092 11.6 4.34179V11.7831C11.6 12.6684 10.5144 13.4 9.60003 13.4H2.03982C1.12542 13.4 0.43982 12.6684 0.43982 11.7831L0.400024 2.21691C0.400024 1.33163 1.12542 0.600006 2.03982 0.600006H7.85343ZM8.68638 9.39334L3.31367 9.40001L3.27699 9.40136C3.01043 9.42114 2.80002 9.65474 2.80002 9.94001C2.80002 10.2382 3.02999 10.48 3.31367 10.48L8.68638 10.4733L8.72306 10.472C8.98962 10.4522 9.20002 10.2186 9.20002 9.93334C9.20002 9.63511 8.97006 9.39334 8.68638 9.39334ZM8.68638 7.26001L3.31367 7.26667L3.27699 7.26803C3.01043 7.2878 2.80002 7.52141 2.80002 7.80667C2.80002 8.10491 3.02999 8.34667 3.31367 8.34667L8.68638 8.34001L8.72306 8.33865C8.98962 8.31888 9.20002 8.08527 9.20002 7.80001C9.20002 7.50177 8.97006 7.26001 8.68638 7.26001ZM5.73442 5.13334H3.33442L3.29626 5.1347C3.01893 5.15447 2.80002 5.38807 2.80002 5.67334C2.80002 5.97157 3.03928 6.21334 3.33442 6.21334H5.73442L5.77259 6.21198C6.04992 6.19221 6.26882 5.95861 6.26882 5.67334C6.26882 5.37511 6.02956 5.13334 5.73442 5.13334Z"
      fill={color}
      fillOpacity="0.5"
    />
  </svg>
);

export default FileDocument;
