import { themePrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const DollarRect: SvgIconType = ({
  width = 32,
  height = 32,
  className = '',
  color = themePrimary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="4" fill={color} />
    <path
      d="M15.845 23.7447C14.6605 23.7447 13.5172 23.5957 12.4153 23.2979C11.3271 22.9858 10.4387 22.5887 9.75 22.1064L11.093 19C11.7404 19.4255 12.4842 19.7801 13.3244 20.0638C14.1784 20.3333 15.0255 20.4681 15.8657 20.4681C16.4304 20.4681 16.885 20.4184 17.2293 20.3191C17.5737 20.2057 17.8216 20.0638 17.9731 19.8936C18.1384 19.7092 18.2211 19.4965 18.2211 19.2553C18.2211 18.9149 18.0696 18.6454 17.7665 18.4468C17.4635 18.2482 17.0709 18.0851 16.5888 17.9574C16.1067 17.8298 15.5696 17.7021 14.9773 17.5745C14.3988 17.4468 13.8134 17.2837 13.2211 17.0851C12.6426 16.8865 12.1123 16.6312 11.6302 16.3191C11.1481 15.9929 10.7555 15.5745 10.4525 15.0638C10.1494 14.539 9.99793 13.8794 9.99793 13.0851C9.99793 12.1915 10.2321 11.383 10.7004 10.6596C11.1825 9.93617 11.8988 9.35461 12.8492 8.91489C13.7996 8.47518 14.9842 8.25532 16.4029 8.25532C17.3533 8.25532 18.2831 8.36879 19.1921 8.59575C20.115 8.80851 20.9346 9.12766 21.6508 9.55319L20.3905 12.6809C19.7018 12.2979 19.02 12.0142 18.345 11.8298C17.6701 11.6312 17.0158 11.5319 16.3822 11.5319C15.8175 11.5319 15.3629 11.5957 15.0186 11.7234C14.6742 11.8369 14.4263 11.9929 14.2748 12.1915C14.1233 12.3901 14.0475 12.617 14.0475 12.8723C14.0475 13.1986 14.1921 13.461 14.4814 13.6596C14.7844 13.844 15.177 14 15.6591 14.1277C16.155 14.2411 16.6921 14.3617 17.2707 14.4894C17.8629 14.617 18.4483 14.7801 19.0269 14.9787C19.6191 15.1631 20.1563 15.4184 20.6384 15.7447C21.1205 16.0567 21.5062 16.4752 21.7955 17C22.0985 17.5106 22.25 18.156 22.25 18.9362C22.25 19.8014 22.009 20.6028 21.5269 21.3404C21.0585 22.0638 20.3492 22.6454 19.3988 23.0851C18.4621 23.5248 17.2775 23.7447 15.845 23.7447ZM14.9773 26V22.9574H17.4566V26H14.9773ZM14.9773 9.02128V6H17.4566V9.02128H14.9773Z"
      fill="white"
    />
  </svg>
);

export default DollarRect;
