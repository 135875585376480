import { useState } from 'react';
import { Popover } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { APP_LANGUAGES } from 'shared/enums';
import {
  LANGUAGE,
  getItemFromLocalStorage,
  getTokenFromLocalStorage
} from 'shared/utils/local-storage';
import { classNames } from 'shared/utils/class-names';
import { changeLanguage } from 'shared/helpers/changeLanguage';
import { SpanishFlagIcon } from '../Icons';
import UsaFlag from '../Icons/UsaFlag';

import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSelector, updateUserLanguageData } from 'store/user/user.redux';

const languageOptions = [
  { id: APP_LANGUAGES.EN, name: 'English', icon: <UsaFlag /> },
  { id: APP_LANGUAGES.ES, name: 'Español', icon: <SpanishFlagIcon /> }
];

export const SelectLanguage = ({}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);

  const setAppLanguage = (value: APP_LANGUAGES) => {
    const token = getTokenFromLocalStorage();
    changeLanguage(value);
    if (token) {
      dispatch(
        updateUserLanguageData({ userId: user?.id, userLanguage: value })
      );
    }
    setOpen(false);
  };

  return (
    <Popover
      placement="bottom"
      trigger="click"
      open={open}
      onOpenChange={setOpen}
      content={
        <div className={'content'}>
          {languageOptions.map((language) => (
            <button
              key={language.id}
              className={classNames({
                active: language.id === getItemFromLocalStorage(LANGUAGE)
              })}
              onClick={() => setAppLanguage(language.id)}
            >
              {language.icon} {language.name}
            </button>
          ))}
        </div>
      }
    >
      <GlobalOutlined className="glob_icon" />
    </Popover>
  );
};
