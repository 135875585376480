import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import NotificationCard from './NotificationCard';
import { Text } from '../Text';
import {
  getNotificationsSelector,
  dismissNotificationData,
  dismissAllNotificationsData
} from 'store/notifications/notification.redux';
import { useTranslationCustom } from 'shared/utils/hooks/useTranslationCustom';

import classes from './styles.module.scss';

interface INotificationComponent {
  dashboard?: boolean;
}

export const NotificationComponent: FC<INotificationComponent> = ({
  dashboard
}) => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotificationsSelector);
  const t = useTranslationCustom();
  const notificationsCopy = [...notifications];
  const notificationsSorted = notificationsCopy.sort(
    (not1, not2) => new Date(not2.createdAt) - new Date(not1.createdAt)
  );

  const closeNotification = (id: string) => {
    dispatch(dismissNotificationData({ id: id }));
  };
  const closeAllNotification = () => {
    dispatch(dismissAllNotificationsData());
  };

  return (
    <>
      <div
        className={classNames({
          [classes['notification-wrapper-dashboard']]: dashboard,
          [classes['notification-wrapper']]: !dashboard,
          [classes['notification-wrapper-dashboard__empty']]:
            !notifications?.length
        })}
      >
        {dashboard && (
          <div className={classes['title']}>
            <Text color="text-primary" type="m-heading-3">
              {t('notifications.title', 'Notifications')}
            </Text>
          </div>
        )}

        <ul className={classes['notification-list']}>
          {notifications?.length ? (
            notificationsSorted.map((notification) => (
              <li key={notification.id}>
                <NotificationCard
                  dashboard={dashboard}
                  {...notification}
                  closeNotification={closeNotification}
                />
              </li>
            ))
          ) : (
            <Text color="text-secondary">
              {t('notifications.empty', 'No Notifications...')}
            </Text>
          )}
        </ul>

        <div className={classes['dismiss-text']}>
          {notifications && notifications.length > 0 && (
            <>
              <Text
                color="text-secondary"
                type="sf-body-medium-two"
                onClick={closeAllNotification}
                className={classes['text-dismiss']}
              >
                {t('notifications.dismissAll', 'Dismiss All')}
              </Text>
            </>
          )}
        </div>
      </div>
    </>
  );
};
