import { http, setupToken } from 'shared/utils/axios';
import { IEcommerceOrder } from 'shared/interfaces/ecommerceOrder.interface';
import { IEcommerceSubscription } from 'shared/interfaces/ecommerceSubscription.interface';

class EcommerceService {
  private static instance: EcommerceService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): EcommerceService {
    if (!EcommerceService.instance) {
      EcommerceService.instance = new EcommerceService();
    }

    return EcommerceService.instance;
  }

  getEcommerceOrders = async ({
    token,
    affiliate
  }: {
    token?: string;
    affiliate?: string;
  }) => {
    const { data } = await http<IEcommerceOrder[]>({
      method: 'GET',
      url: '/ecommerce/customer/getOrders',
      params: { affiliate },
      ...(token ? setupToken(token) : {})
    });
    return data;
  };

  getEcommerceOrder = async ({
    id,
    affiliate
  }: {
    id: string;
    affiliate: string;
  }) => {
    const { data } = await http<IEcommerceOrder>({
      method: 'GET',
      url: `/ecommerce/orders/${id}`,
      params: { affiliate }
    });
    return data;
  };

  getOrderReceipt = async ({
    orderId,
    affiliate
  }: {
    orderId: string;
    affiliate: string;
  }) => {
    const { data } = await http<string>({
      method: 'GET',
      url: `/ecommerce/orders/${orderId}/actions/getOrderReceipt`,
      params: { affiliate }
    });
    return data;
  };

  getEcommerceSubscriptions = async ({
    token,
    affiliate
  }: {
    token?: string;
    affiliate?: string;
  }) => {
    const { data } = await http<IEcommerceSubscription[]>({
      method: 'GET',
      url: '/ecommerce/customer/getSubscriptions',
      params: { affiliate },
      ...(token ? setupToken(token) : {})
    });
    return data;
  };

  getEcommerceSubscription = async ({
    id,
    affiliate
  }: {
    id: string;
    affiliate: string;
  }) => {
    const { data } = await http<IEcommerceSubscription>({
      method: 'GET',
      url: `/ecommerce/subscriptions/${id}`,
      params: { affiliate }
    });
    return data;
  };
}

const instance = EcommerceService.getInstance();

export default instance;
