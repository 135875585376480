import {
  TelegraMDLogoIcon,
  TelegraMDLogoColorDark
} from 'shared/components/Icons';

export const generalInfo = {
  appName: 'Telegra MD',
  companyUrl: 'https://telegramd.com',
  smallLogo: TelegraMDLogoIcon,
  logo: TelegraMDLogoColorDark,
  logoWithName: false,
  supportEmail: 'support@telegramd.com',
  termsOfUse: 'https://telegramd.com/terms-of-use/',
  privacyPolicy: 'https://telegramd.com/privacy-policy/',
  paymentProcessor:
    process.env.REACT_APP_PROCESSOR_TYPE || 'authnet'
};
