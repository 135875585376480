import { themeIconLight } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const UserFilledIcon: SvgIconType = ({
  width = 40,
  height = 40,
  className = '',
  color = themeIconLight
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9986 19.9992C23.7394 19.9992 26.7712 17.0149 26.7712 13.3326C26.7712 9.65036 23.7394 6.66602 19.9986 6.66602C16.2578 6.66602 13.2259 9.65036 13.2259 13.3326C13.2259 17.0149 16.2578 19.9992 19.9986 19.9992ZM24.7408 21.666H23.8572C22.6826 22.1973 21.3757 22.4993 20 22.4993C18.6243 22.4993 17.3227 22.1973 16.1428 21.666H15.2592C11.3331 21.666 8.14789 24.8014 8.14789 28.6659V30.8326C8.14789 32.2128 9.28548 33.3326 10.6876 33.3326H29.3124C30.7145 33.3326 31.8521 32.2128 31.8521 30.8326V28.6659C31.8521 24.8014 28.6669 21.666 24.7408 21.666Z"
      fill={color}
    />
  </svg>
);

export default UserFilledIcon;
