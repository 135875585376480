import {
  TCreatePatient,
  TUpdatePatient,
  TUploadPatientFile
} from 'shared/types';
import { http } from 'shared/utils/axios';
import { IPatient } from 'shared/interfaces/patient.interface';
import { getOTPTokenFromLocalStorage } from 'shared/utils/local-storage';
import { IFile } from 'shared/interfaces/file.interface';

class PatientService {
  private static instance: PatientService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): PatientService {
    if (!PatientService.instance) {
      PatientService.instance = new PatientService();
    }

    return PatientService.instance;
  }

  createPatient = async (payload: TCreatePatient) => {
    const { data } = await http<IPatient>({
      url: '/patients',
      method: 'POST',
      data: payload,
      params: { access_token: getOTPTokenFromLocalStorage() }
    });
    return data;
  };

  getPatient = async (patientId: string) => {
    const { data } = await http<IPatient>({
      method: 'GET',
      url: `/patients/${patientId}`
    });
    return data;
  };

  editPatient = async ({ body, patientId }: TUpdatePatient) => {
    const { data } = await http<IPatient>({
      method: 'PUT',
      url: `/patients/${patientId}`,
      data: body
    });
    return data;
  };

  uploadFile = async ({ patientId, body }: TUploadPatientFile) => {
    const { data } = await http<IFile>({
      method: 'POST',
      url: `/patients/${patientId}/uploadFile`,
      data: { patientId: patientId, data: body }
    });
    return data;
  };
}

const instance = PatientService.getInstance();

export default instance;
