import { FC } from 'react';
import './styles.scss';

type DefaultBackgroundType = {
  backgroundImage?: string;
};

export const DefaultBackground: FC<DefaultBackgroundType> = ({
  backgroundImage = ''
}) => {
  return (
    <div
      className="default-affiliate-background"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    ></div>
  );
};
