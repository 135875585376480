import { SvgIconType } from './svg.interface';

const Cancel: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = '#65A6E9'
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00067 6.58508L12.5926 1.99312C12.9835 1.60223 13.6173 1.60223 14.0082 1.99312C14.399 2.384 14.399 3.01775 14.0082 3.40864L9.41684 7.99995L14.0082 12.5913C14.399 12.9822 14.399 13.6159 14.0082 14.0068C13.6173 14.3977 12.9835 14.3977 12.5926 14.0068L8.00067 9.41482L3.4087 14.0068C3.01781 14.3977 2.38406 14.3977 1.99318 14.0068C1.60229 13.6159 1.60229 12.9822 1.99318 12.5913L6.58449 7.99995L1.99318 3.40864C1.60229 3.01775 1.60229 2.384 1.99318 1.99312C2.38406 1.60223 3.01781 1.60223 3.4087 1.99312L8.00067 6.58508Z"
      fill={color}
    />
  </svg>
);

export default Cancel;
