import { Avatar as AntdAvatar, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { DefaultUserIcon } from 'shared/components/Icons';
import {
  removeOTPTokenFromLocalStorage,
  removeTokenFromLocalStorage
} from 'shared/utils/local-storage';
import { getUserSelector } from 'store/user/user.redux';
import { resetApp } from 'store/app/app.redux';
import { useTranslationCustom } from 'shared/utils/hooks/useTranslationCustom';

import classes from './styles.module.scss';

const Avatar = () => {
  const user = useSelector(getUserSelector);
  const t = useTranslationCustom();
  const dispatch = useDispatch();
  const handleLogout = () => {
    removeTokenFromLocalStorage();
    removeOTPTokenFromLocalStorage();
    dispatch(resetApp());
  };

  return (
    <div className={classes['header__icons__person-icon']}>
      <Popover
        placement={'bottomRight'}
        title={user?.fullName}
        trigger="click"
        showArrow={false}
        overlayInnerStyle={{ right: '20px' }}
        content={
          <div className={classes.popoverContent}>
            <button className={classes.logoutButton} onClick={handleLogout}>
              {t('login.logout', 'Logout')}
            </button>
          </div>
        }
      >
        <AntdAvatar className={classes.avatar} src={<DefaultUserIcon />} />
      </Popover>
    </div>
  );
};

export default Avatar;
