import { Locale } from 'antd/es/locale';
import esES from 'antd/locale/es_ES';
import enUS from 'antd/locale/en_US';

type LanguageMap = {
  [key: string]: Locale;
};

export const antLanguage: LanguageMap = {
  es: esES,
  en: enUS
};
