import { Text } from 'shared/components/Text';
import { notification } from 'antd';
import { NotificationMessages } from 'shared/enums';
import Info from 'shared/components/Icons/Info';
import classes from './styles.module.scss';
import { CloseIcon } from 'shared/components/Icons';
import { critical, success, themePrimary } from '../cssUtility';

type TMessage = {
  type?: NotificationMessages;
  title?: string;
  message: string;
  actionButton?: () => void;
};

export const openNotificationMessage = (props: TMessage) => {
  const {
    type = NotificationMessages.Information,
    title = 'Notification',
    message,
    actionButton
  } = props;

  const chooseColor = (type: NotificationMessages) => {
    switch (type) {
      case NotificationMessages.Error:
        return critical;
      case NotificationMessages.Information:
        return themePrimary;
      case NotificationMessages.Success:
        return success;
      default:
        return themePrimary;
    }
  };

  notification.open({
    className: classes.notification,
    style: { borderLeftColor: chooseColor(type) },
    duration: 5,
    message: (
      <Text type="sf-body-emphasized" color="text-primary">
        {title}
      </Text>
    ),
    description: (
      <>
        <Text type="sf-body" color="text-secondary">
          {message}
        </Text>
        {actionButton && (
          <Text
            className={classes.action_section}
            type="sf-body-medium"
            color="theme-dark"
            component="a"
            onClick={actionButton}
          >
            Action
          </Text>
        )}
      </>
    ),
    closeIcon: <CloseIcon width={10} height={10} />,
    icon: <Info color={chooseColor(type)} width={12} height={12} />
  });
};
