export const downloadFile = (
  data: string,
  fileName: string,
  fileType: string
) => {
  try {
    const link = `data:${fileType};base64,${data}`;
    const a = document.createElement('a');
    a.setAttribute('href', link);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error download file:', error);
  }
};
