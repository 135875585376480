import { themeTextSecondary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const CartIcon: SvgIconType = ({
  width = 30,
  height = 30,
  className = '',
  color = themeTextSecondary
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.1375 3.97499H4.3719L6.21675 17.8481C6.30978 18.8239 7.15231 19.5548 8.13142 19.5092L25.0971 18.7301C25.8215 18.6967 26.4586 18.2404 26.7233 17.5651C28.7849 12.2676 29.6908 9.55731 29.7 9.43236C29.7753 8.42174 29.1432 7.53974 28.1326 7.46624L8.32253 5.99991L7.84661 2.12279C7.79086 1.66497 7.56448 1.24502 7.21267 0.946788C6.8634 0.536264 6.3515 0.299794 5.8125 0.299988H2.1375C1.12268 0.299988 0.299999 1.12266 0.299999 2.13749C0.299999 3.15231 1.12268 3.97499 2.1375 3.97499ZM9.4875 29.7C7.45785 29.7 5.8125 28.0546 5.8125 26.025C5.8125 23.9953 7.45785 22.35 9.4875 22.35C11.5171 22.35 13.1625 23.9953 13.1625 26.025C13.1625 28.0546 11.5171 29.7 9.4875 29.7ZM24.1875 29.7C22.1579 29.7 20.5125 28.0546 20.5125 26.025C20.5125 23.9953 22.1579 22.35 24.1875 22.35C26.2171 22.35 27.8625 23.9953 27.8625 26.025C27.8625 28.0546 26.2171 29.7 24.1875 29.7Z"
      fill={color}
    />
  </svg>
);

export default CartIcon;
