import { themeTextDisabled } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Dot: SvgIconType = ({
  width = 12,
  height = 12,
  className = '',
  color = themeTextDisabled
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4.5C6.82843 4.5 7.5 5.17157 7.5 6C7.5 6.82843 6.82843 7.5 6 7.5C5.17157 7.5 4.5 6.82843 4.5 6C4.5 5.17157 5.17157 4.5 6 4.5Z"
      fill={color}
    />
  </svg>
);

export default Dot;
