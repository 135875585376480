import { themeIconDark } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Phone: SvgIconType = ({
  width = 17,
  height = 17,
  className,
  strokeWidth = 2,
  color = themeIconDark
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.7094 12.3982V14.5127C15.711 14.9096 15.5452 15.2888 15.2527 15.5572C14.9602 15.8255 14.5682 15.9581 14.1729 15.9224C12.0039 15.6867 9.92053 14.9456 8.09006 13.7585C6.38704
      12.6764 4.94317 11.2325 3.861 9.52947C2.6698 7.69068 1.92849 5.59714 1.69713 3.41847C1.66152 3.02437 1.79318 2.63345 2.05996 2.3412C2.32674 2.04895 2.70406 1.88228 3.09977
      1.88191H5.2143C5.92187 1.87495 6.52496 2.3936 6.62399 3.09424C6.71324 3.77094 6.87875 4.43537 7.11738 5.07485C7.31102 5.59 7.18717 6.17074 6.8002 6.56207L5.90505 7.45722C6.90844
      9.22183 8.3695 10.6829 10.1341 11.6863L11.0293 10.7911C11.4206 10.4042 12.0013 10.2803 12.5165 10.4739C13.156 10.7126 13.8204 10.8781 14.4971 10.9673C15.2059 11.0673 15.7272
      11.6826 15.7094 12.3982L15.7094 12.3982Z"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Phone;
