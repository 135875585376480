import { SvgIconType } from './svg.interface';

const Google: SvgIconType = ({ width = 12, height = 13, className = '' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.30315C12 5.87769 11.961 5.4686 11.8887 5.07588H6.12245V7.39679H9.41744C9.27551 8.14679 8.84415 8.78224 8.19573 9.2077V10.7132H10.1744C11.3321 9.66861 12 8.13042 12 6.30315Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.12246 12.1667C7.77552 12.1667 9.16141 11.6295 10.1744 10.7132L8.19573 9.2077C7.64749 9.5677 6.9462 9.78038 6.12246 9.78038C4.52784 9.78038 3.17811 8.72493 2.69667 7.30675H0.651209V8.86129C1.65863 10.8222 3.72913 12.1667 6.12246 12.1667Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.69667 7.30675C2.57422 6.94674 2.50464 6.56224 2.50464 6.16678C2.50464 5.77133 2.57421 5.38678 2.69666 5.02678V3.47224H0.651206C0.236549 4.28224 0 5.1986 0 6.16678C0 7.13496 0.236552 8.05129 0.651209 8.86129L2.69667 7.30675Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.12246 2.55311C7.02134 2.55311 7.82839 2.85584 8.4629 3.45039L10.2189 1.72948C9.15864 0.761294 7.77274 0.166748 6.12246 0.166748C3.72913 0.166748 1.65863 1.51133 0.651206 3.47224L2.69666 5.02678C3.17811 3.6086 4.52784 2.55311 6.12246 2.55311Z"
      fill="#EA4335"
    />
  </svg>
);

export default Google;
