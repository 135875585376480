import {
  FC,
  PropsWithChildren,
  useState,
  useEffect,
  ElementType,
  useLayoutEffect
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { classNames } from 'shared/utils/class-names';
import { useMobile } from 'shared/utils/hooks/useMobile';
import { IFRAME, getItemFromLocalStorage } from 'shared/utils/local-storage';
import Header from './components';
import { getAffiliateGlobalStylesSelector } from 'store/app/app.redux';
import { Spinner } from '../Spinner';

import classes from './styles.module.scss';

export const BaseLayout: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const isMobile = useMobile();
  const isIframe = getItemFromLocalStorage(IFRAME);
  const affiliateGlobalStyles = useSelector(getAffiliateGlobalStylesSelector);
  const [showSideBar, setShowSideBar] = useState(false);
  const [isMessagesPath, setIsMessagesPath] = useState<boolean>(false);
  const [BaseLayout] = useState<ElementType>(
    styled.div`
      ${affiliateGlobalStyles?.styles}
    `
  );

  useLayoutEffect(() => {
    setShowSideBar(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (pathname === '/messages' || pathname === '/channels') {
      setIsMessagesPath(true);
    } else {
      setIsMessagesPath(false);
    }
  }, [pathname]);

  return (
    <BaseLayout className={classes.layout}>
      <Header
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        isIframe={isIframe}
        isMobile={isMobile}
      />
      {children ? (
        <main
          className={classNames(
            isMessagesPath ? classes.layout__submenu : classes.layout__children,
            isMessagesPath
              ? classes.layout__noTransition
              : classes.layout__transition,
            {
              [classes[
                `layout__${
                  isMessagesPath ? 'sidebar-open-with-submenu' : 'sidebar-open'
                }`
              ]]: showSideBar
            }
          )}
        >
          {children}
        </main>
      ) : (
        <Spinner centerOfPage />
      )}
    </BaseLayout>
  );
};
