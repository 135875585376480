import { IAddressFull } from 'shared/interfaces/address.interface';
import { http } from '../utils/axios';
import { IPatient } from 'shared/interfaces/patient.interface';
import {
  TCreatePatientAddress,
  TSetDefaultPatientAddress,
  TUpdatePatientAddress
} from 'shared/types';

class AddressService {
  private static instance: AddressService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): AddressService {
    if (!AddressService.instance) {
      AddressService.instance = new AddressService();
    }

    return AddressService.instance;
  }

  createAddress = async (payload: TCreatePatientAddress) => {
    const { billing, shipping, patient, affiliate } = payload;
    const { data } = await http<IAddressFull[]>({
      method: 'POST',
      url: '/addresses',
      data: { billing, shipping },
      params: { patient: patient || '', affiliate: affiliate || '' }
    });
    return data;
  };

  setDefaultAddress = async (payload: TSetDefaultPatientAddress) => {
    const { address, affiliate, patient, setActiveSubscriptions } = payload;
    const { data } = await http<IPatient>({
      method: 'PUT',
      url: '/addresses/actions/setDefaultAddress',
      data: { address, setActiveSubscriptions },
      params: { affiliate, patient }
    });
    return data;
  };

  editAddress = async (payload: TUpdatePatientAddress) => {
    const { addressId, billing, shipping } = payload;
    const { data } = await http<IPatient>({
      method: 'PUT',
      url: `/addresses/${addressId}`,
      data: { billing, shipping }
    });
    return data;
  };
}

const instance = AddressService.getInstance();

export default instance;
