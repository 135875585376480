import { TAction } from './../../shared/types';
import { takeLatest, put, call } from 'redux-saga/effects';

import { IPatientNote } from 'shared/interfaces/patientNote.interface';
import PatientNoteService from 'shared/services/PatientNoteService';
import {
  getPatientNotesFail,
  setPatientNotesData,
  getPatientNotes
} from './patientNotes.redux';

function* getPatientNotesSaga({ payload }: TAction<string>) {
  try {
    if (payload) {
      const result: IPatientNote[] = yield call(
        PatientNoteService.getAllPatientNotes,
        payload
      );

      yield put(setPatientNotesData(result));
    }
  } catch (error) {
    yield put(getPatientNotesFail());
    console.log('getPatientNotes error', error);
  }
}

function* Tasks() {
  yield takeLatest(getPatientNotes.type, getPatientNotesSaga);
}

export default Tasks;
