import { SvgIconType } from './svg.interface';

const CloseCircle: SvgIconType = ({
  width = 56,
  height = 56,
  className = '',
  color = '#EC5252'
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="56" rx="28" fill={color} />
    <path d="M16 16L28 28L40 16" stroke="white" strokeWidth="5" />
    <path d="M40 40L28 28L16 40" stroke="white" strokeWidth="5" />
  </svg>
);

export default CloseCircle;
