import { themeTextSecondary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const FilePrescription: SvgIconType = ({
  width = 9,
  height = 8,
  className = '',
  color = themeTextSecondary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4.25V0H0.75C0.334371 0 0 0.334371 0 0.749999V15.25C0 15.6656 0.334371 16 0.75 16H11.25C11.6656 16 12 15.6656 12 15.25V5H7.75C7.3375 5 7 4.6625 7 4.25ZM9.14154 9.85875L9.49498 10.2122C9.69029 10.4075 9.69029 10.7241 9.49498 10.9194L8.56061 11.8538L9.49998 12.7931C9.69529 12.9884 9.69529 13.305 9.49998 13.5003L9.14655 13.8537C8.95124 14.0491 8.63467 14.0491 8.43936 13.8537L7.49999 12.9141L6.56061 13.8534C6.3653 14.0488 6.04873 14.0488 5.85342 13.8534L5.49999 13.5C5.30468 13.3047 5.30468 12.9881 5.49999 12.7928L6.43937 11.8534L4.58562 10H3.99999V11.5C3.99999 11.7762 3.77624 12 3.49999 12H3C2.72375 12 2.5 11.7762 2.5 11.5V6.5C2.5 6.22375 2.72375 6 3 6H5.5C6.60468 6 7.49999 6.89531 7.49999 8C7.49999 8.75688 7.07437 9.40782 6.45437 9.7475L7.49999 10.7931L8.43437 9.85875C8.62968 9.66344 8.94625 9.66344 9.14156 9.85875H9.14154ZM5.5 8.5H4V7.5H5.5C5.77562 7.5 6 7.72437 6 8C6 8.27562 5.77562 8.49999 5.5 8.49999V8.5ZM12 3.80937V4H8V0H8.19062C8.39062 0 8.58125 0.0781285 8.72187 0.218751L11.7812 3.28125C11.9219 3.42187 12 3.6125 12 3.80938L12 3.80937Z"
      fill={color}
    />
  </svg>
);

export default FilePrescription;
