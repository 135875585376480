import { themeIconLight } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const VisaLogo: SvgIconType = ({
  width = 65,
  height = 21,
  className = '',
  color = themeIconLight
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 65 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1007 20.6887H10.5273L6.34788 4.513C6.14951 3.76892 5.72831 3.11111 5.10874 2.80108C3.56253 2.02195 1.85871 1.40188 0 1.08915V0.466389H8.97834C10.2175 0.466389 11.1468 1.40188 11.3017 2.48835L13.4702 14.1564L19.0409 0.466389H24.4594L16.1007 20.6887ZM27.5575 20.6887H22.2939L26.6282 0.466389H31.8918L27.5575 20.6887ZM38.7013 6.06857C38.8562 4.97941 39.7855 4.35665 40.8698 4.35665C42.5736 4.20028 44.4296 4.51301 45.9785 5.28944L46.9079 0.935493C45.359 0.312729 43.6551 0 42.1089 0C37.0002 0 33.2828 2.80109 33.2828 6.68864C33.2828 9.64609 35.916 11.199 37.7747 12.1344C39.7855 13.0672 40.56 13.69 40.4051 14.6228C40.4051 16.022 38.8562 16.6448 37.31 16.6448C35.4513 16.6448 33.5926 16.1784 31.8915 15.3992L30.9621 19.7559C32.8208 20.5323 34.8317 20.845 36.6904 20.845C42.4187 20.9987 45.9785 18.2003 45.9785 14C45.9785 8.7106 38.7013 8.40056 38.7013 6.06857ZM64.3998 20.6887L60.2204 0.466389H55.7312C54.8019 0.466389 53.8725 1.08915 53.5627 2.02195L45.8235 20.6887H51.2421L52.3236 17.7339H58.9813L59.6008 20.6887H64.3998ZM56.5061 5.91221L58.0523 13.5336H53.7181L56.5061 5.91221Z"
      fill={color}
    />
  </svg>
);

export default VisaLogo;
