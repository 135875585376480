import { FC } from 'react';
import './styles.scss';

type DualAffiliateBackgroundType = {
  backgroundImages: string[];
};

export const DualBackground: FC<DualAffiliateBackgroundType> = ({
  backgroundImages
}) => {
  if (backgroundImages.length !== 2) {
    return <></>;
  }
  return (
    <div
      className="dual-affiliate-background"
      style={{
        backgroundImage: `url(${backgroundImages[0]}), url(${backgroundImages[1]})`
      }}
    ></div>
  );
};
