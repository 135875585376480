import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const NotificationIcon: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2155 10.2975L13 9.06599V5C13 2.23858 10.7614 0 8 0C5.23858 0 3 2.23858 3 5V9.06599L1.78449 10.2975C1.59978 10.4847 1.49622 10.7371 1.49622 11C1.49622 11.5523 1.94393 12 2.49622 12H13.5038C13.7667 12 14.0191 11.8964 14.2062 11.7117C14.5993 11.3238 14.6035 10.6906 14.2155 10.2975Z"
      fill={color}
    />
    <path
      d="M8 16C9.20948 16 10.2184 15.1411 10.45 14H5.55001C5.78164 15.1411 6.79052 16 8 16Z"
      fill={color}
    />
  </svg>
);

export default NotificationIcon;
