import { takeLatest, put, call } from 'redux-saga/effects';

import { ITask } from 'shared/interfaces/task.interface';
import TasksService from 'shared/services/TasksService';
import { getTasksData, getTasksFail, setTasksData } from './tasks.redux';

function* getTasks() {
  try {
    const result: ITask[] = yield call(TasksService.getAllTasks);

    yield put(setTasksData(result));
  } catch (error) {
    yield put(getTasksFail());
    console.log('getTasks error', error);
  }
}

function* Tasks() {
  yield takeLatest(getTasksData.type, getTasks);
}

export default Tasks;
