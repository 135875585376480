import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Clipboard: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 2C6 0.895431 6.89543 0 8 0C9.10457 0 10 0.895431 10 2C10 2.55228 9.55228 3 9 3H7C6.44772 3 6 2.55228 6 2Z"
      fill={color}
    />
    <path
      d="M12 2C12 3.65685 10.6569 5 9 5H7C5.34315 5 4 3.65685 4 2C2.89543 2 2 2.89543 2 4V14C2 15.1046 2.89543 16 4 16H12C13.1046 16 14 15.1046 14 14V4C14 2.89543 13.1046 2 12 2Z"
      fill={color}
    />
  </svg>
);

export default Clipboard;
