import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const ChevronLeft: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0081 2.40864L5.41683 7.99995L11.0081 13.5913C11.399 13.9822 11.399 14.6159 11.0081 15.0068C10.6173 15.3977 9.98351 15.3977 9.59262 15.0068L3.29289 8.70706C2.90237 8.31653 2.90237 7.68337 3.29289 7.29284L9.59262 0.993115C9.98351 0.60223 10.6173 0.60223 11.0081 0.993115C11.399 1.384 11.399 2.01775 11.0081 2.40864Z"
      fill={color}
    />
  </svg>
);
export default ChevronLeft;
