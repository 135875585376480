import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Change: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.0145 5.59075V2.79876C12.8382 3.86 14.0003 5.82101 14.0003 7.99288C14.0003 10.6746 12.2397 13.0051 9.71653 13.7523C9.18694 13.9092 8.88466 14.466 9.04136 14.9961C9.19806 15.5261 9.75441 15.8287 10.284 15.6719C13.6523 14.6743 16.0003 11.5662 16.0003 7.99288C16.0003 5.64963 14.9852 3.48999 13.3094 2.00178H14.6002C15.1525 2.00178 15.6002 1.55367 15.6002 1.00089C15.6002 0.448114 15.1525 0 14.6002 0H10.0145C9.75853 0 9.50261 0.0977179 9.30734 0.293154C9.11208 0.48859 9.01445 0.74474 9.01445 1.00089V5.59075C9.01445 6.14353 9.46217 6.59164 10.0145 6.59164C10.5667 6.59164 11.0145 6.14353 11.0145 5.59075Z"
      fill={color}
    />
    <path
      d="M4.98581 10.4092V13.2012C3.16203 12.14 2 10.179 2 8.00712C2 5.32542 3.76052 2.99492 6.28373 2.24765C6.81332 2.09081 7.1156 1.53397 6.9589 1.00391C6.8022 0.473854 6.24585 0.171301 5.71627 0.328142C2.34797 1.32569 0 4.43384 0 8.00712C0 10.3504 1.01509 12.51 2.69089 13.9982H1.40002C0.84774 13.9982 0.400024 14.4463 0.400024 14.9991C0.400024 15.5519 0.84774 16 1.40002 16H5.98581C6.24173 16 6.49766 15.9023 6.69292 15.7068C6.88818 15.5114 6.98581 15.2553 6.98581 14.9991V10.4092C6.98581 9.85647 6.5381 9.40836 5.98581 9.40836C5.43353 9.40836 4.98581 9.85647 4.98581 10.4092Z"
      fill={color}
    />
  </svg>
);
export default Change;
