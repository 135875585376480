import { SvgIconType } from './svg.interface';

const MastercardLogo: SvgIconType = ({
  width = 60,
  height = 46,
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 60 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M38 3.79999H22V32.2H38V3.79999Z" fill="#FF5F00" />
    <path
      d="M22.9381 18C22.9381 12.25 25.7217 7.15 30 3.85C26.8557 1.45 22.8866 0 18.5567 0C8.29897 0 0 8.05 0 18C0 27.95 8.29897 36 18.5567 36C22.8866 36 26.8557 34.55 30 32.15C25.7217 28.9 22.9381 23.75 22.9381 18Z"
      fill="#EB001B"
    />
    <path
      d="M60 18C60 27.95 51.701 36 41.4433 36C37.1134 36 33.1443 34.55 30 32.15C34.3299 28.85 37.0619 23.75 37.0619 18C37.0619 12.25 34.2784 7.15 30 3.85C33.1443 1.45 37.1134 0 41.4433 0C51.701 0 60 8.1 60 18Z"
      fill="#F79E1B"
    />
  </svg>
);

export default MastercardLogo;
