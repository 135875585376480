import { useCallback } from 'react';

import { Urls } from '../../constants/urls';
import { TextColors } from '../../components/Text';

type TResult = [
  (route: Urls) => string | TextColors | any,
  (route: Urls) => boolean
];

export const useHighlightColor = (
  mainColor: string | TextColors,
  highlightColor: string | TextColors
): TResult => {
  const getHighLightState = (route: Urls) => {
    const path = window.location.pathname;
    return path === route;
  };

  const getColor = useCallback(
    (route: Urls) => {
      return getHighLightState(route) ? highlightColor : mainColor;
    },
    [highlightColor, mainColor]
  );

  return [getColor, getHighLightState];
};
