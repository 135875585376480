import React, { ReactNode, useEffect } from 'react';
import { useIntegrations } from './hooks/integrations';
import Intercom, { update, shutdown, hide } from '@intercom/messenger-js-sdk';
import { INTEGRATION_TYPES } from 'shared/constants/integrations';

export const IntegrationsContext = React.createContext<object | null>(null);

const IntegrationsProvider = ({ children }: { children: ReactNode }) => {
  const intergrationClient = useIntegrations();
  const { getIntegration, integrationAccessLevel } = intergrationClient;
  const intercomIntegration = getIntegration(INTEGRATION_TYPES.INTERCOM);

  useEffect(() => {
    if (intercomIntegration?.status === 'active') {
      Intercom({
        app_id: 'wfu4vitn'
      });
      if (integrationAccessLevel.authenticated) {
        update({ email: integrationAccessLevel.user?.email });
      }
      if (!integrationAccessLevel.authenticated) {
        shutdown();
      }
    }
  }, [intercomIntegration?.status, integrationAccessLevel.authenticated]);

  return (
    <IntegrationsContext.Provider value={intergrationClient}>
      {children}
    </IntegrationsContext.Provider>
  );
};

export default IntegrationsProvider;
