import { SvgIconType } from './svg.interface';

const CheckList: SvgIconType = ({
  width = 12,
  height = 12,
  color,
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.10237 0.232765C5.36215 -0.0558746 5.80673 -0.0792735 6.09537 0.180502C6.38401 0.440278 6.4074 0.884857 6.14763 1.1735L2.98357 4.68912C2.71408 4.98855 2.24861 5.00079 1.96375 4.71594L0.205941 2.95813C-0.0686468 2.68354 -0.0686468 2.23835 0.205941 1.96376C0.480528 1.68917 0.925722 1.68917 1.20031 1.96376L2.43408 3.19753L5.10237 0.232765ZM11.25 7.03125C11.25 6.64293 10.9352 6.32813 10.5469 6.32813H2.10938L2.02738 6.33286C1.67768 6.37347 1.40625 6.67067 1.40625 7.03125C1.40625 7.41958 1.72105 7.73438 2.10938 7.73438H10.5469L10.6289 7.72965C10.9786 7.68903 11.25 7.39184 11.25 7.03125ZM11.25 10.5469C11.25 10.1586 10.9352 9.84375 10.5469 9.84375H2.10938L2.02738 9.84848C1.67768 9.8891 1.40625 10.1863 1.40625 10.5469C1.40625 10.9352 1.72105 11.25 2.10938 11.25H10.5469L10.6289 11.2453C10.9786 11.2047 11.25 10.9075 11.25 10.5469ZM10.5469 2.8125H7.03125L6.94925 2.81723C6.59956 2.85785 6.32812 3.15504 6.32812 3.51563C6.32812 3.90395 6.64292 4.21875 7.03125 4.21875H10.5469L10.6289 4.21402C10.9786 4.17341 11.25 3.87622 11.25 3.51563C11.25 3.1273 10.9352 2.8125 10.5469 2.8125Z"
      fill={color}
    />
  </svg>
);

export default CheckList;
