import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const TestTube: SvgIconType = ({
  width = 29,
  height = 29,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.48706 3.97499V12.6455C9.48706 12.9836 9.39377 13.3151 9.21747 13.6036L1.08927 26.9043C0.341014 28.1287 1.22222 29.7 2.65718 29.7H27.3419C28.7769 29.7 29.6581 28.1287 28.9099 26.9043L20.7817 13.6036C20.6054 13.3151 20.5121 12.9836 20.5121 12.6455V3.97499H22.3496V0.299988H7.64956V3.97499H9.48706ZM10.4252 18.675H19.5739L17.6458 15.52C17.1169 14.6545 16.8371 13.6598 16.8371 12.6455V3.97499H13.1621V12.6455C13.1621 13.6598 12.8822 14.6545 12.3533 15.52L10.4252 18.675ZM15.2445 23.2687C15.2445 24.791 14.0105 26.025 12.4883 26.025C10.966 26.025 9.73202 24.791 9.73202 23.2687C9.73202 21.7465 10.966 20.5125 12.4883 20.5125C14.0105 20.5125 15.2445 21.7465 15.2445 23.2687ZM18.9195 27.8625C19.9343 27.8625 20.757 27.0398 20.757 26.025C20.757 25.0102 19.9343 24.1875 18.9195 24.1875C17.9047 24.1875 17.082 25.0102 17.082 26.025C17.082 27.0398 17.9047 27.8625 18.9195 27.8625Z"
      fill={color}
    />
  </svg>
);

export default TestTube;
