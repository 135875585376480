export enum USER_PROGRESS {
  LOADER = 'LOADER',
  AUTHENTICATION = 'AUTHENTICATION',
  OTP_SCREEN = 'OTP_SCREEN',
  PROFILE = 'PROFILE',
  SYMPTOMS_SCREEN = 'SYMPTOMS_SCREEN',
  MEDICATIONS_SCREEN = 'MEDICATIONS_SCREEN',
  ALLERGIES_SCREEN = 'ALLERGIES_SCREEN',
  BODY_METRICS = 'BODY_METRICS',
  QUESTIONNAIRE_START = 'QUESTIONNAIRE_START',
  QUESTIONNAIRE = 'QUESTIONNAIRE',
  DETAILS = 'DETAILS',
  PHARMACY = 'PHARMACY',
  SHIPPING = 'SHIPPING',
  BILLING = 'BILLING',
  MESSAGING = 'MESSAGING',
  CLOSING_SCREEN = 'CLOSING_SCREEN',
  AFFILIATE_CLOSING_SCREEN = 'AFFILIATE_CLOSING_SCREEN',
  SHIPPING_DETAILS = 'SHIPPING_DETAILS',
  COMPLETED_SCREEN = 'COMPLETED_SCREEN'
}

export enum USER_PROGRESS_DATA {
  USER_PROGRESS = 'userProgress',
  PRODUCT = 'product',
  QUESTIONNAIRE = 'questionnaire',
  LOCATIONS_LENGTH = 'locationsLength',
  CURRENT_LOCATION = 'currentLocation',
  SKIP_QUESTIONNAIRE = 'skipQuestionnaire',
  ANSWERS = 'answers',
  ORDER = 'order',
  AGREEMENTS = 'agreements',
  SHIPPING_DETAILS = 'shippingDetails',
  PAYMENT_METHOD_DATA = 'paymentMethodData',
  USER_EXISTENCE = 'userExistence',
  CONSENT_DATA = 'consentData',
  MESSAGE = 'message',
  TOKEN = 'token',
  PHARMACY = 'pharmacy',
  DOSE_SPOT = 'doseSpot',
  COMPLETED_QUESTIONNAIRE = 'completedQuestionnaire',
  QUESTIONNAIRES_WERE_COMPLETED_IN_CURRENT_SESSION = 'questionnairesWereCompletedInCurrentSession',
  POTENTIAL_ORDER = 'potentialOrder',
  ORDER_STARTED_STATUS = 'orderStartedStatus'
}

export enum FORM_SUBMIT_STATUSES {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure'
}

export enum PROGRESS_STATUSES {
  SUCCESS = 'success',
  FAILURE = 'failure'
}
