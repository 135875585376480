import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Task: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9C0 11.3894 1.04751 13.5341 2.70835 15H13.2916C14.9525 13.5341 16 11.3894 16 9C16 4.58172 12.4183 1 8 1C3.58172 1 0 4.58172 0 9ZM9.5 11C9.5 10.8037 9.46229 10.6162 9.39371 10.4443L12.2335 4.78307C12.3593 4.53228 12.2568 4.22006 12 4.07179C11.7432 3.92352 11.4215 3.99087 11.2673 4.22521L7.78418 9.51541C7.05801 9.62005 6.5 10.2449 6.5 11C6.5 11.8284 7.17157 12.5 8 12.5C8.82843 12.5 9.5 11.8284 9.5 11Z"
      fill={color}
    />
  </svg>
);

export default Task;
