import { http } from '../utils/axios';
import { INotification } from '../interfaces/notification.interface';

class NotificationsService {
  private static instance: NotificationsService;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): NotificationsService {
    if (!NotificationsService.instance) {
      NotificationsService.instance = new NotificationsService();
    }
    return NotificationsService.instance;
  }

  getNotifications = async () => {
    const { data } = await http<INotification>({
      url: '/notifications',
      method: 'GET'
    });
    return data;
  };

  dismissNotification = async ({ id }: { id: string }) => {
    const { data } = await http<INotification>({
      url: `/notifications/actions/dismissOne/${id}`,
      method: 'PUT'
    });
    return data;
  };
  dismissAllNotifications = async () => {
    const { data } = await http<INotification>({
      url: '/notifications/actions/dismissAll',
      method: 'PUT'
    });
    return data;
  };
}

const instance = NotificationsService.getInstance();

export default instance;
