import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Attach: SvgIconType = ({
  width = 9,
  height = 8,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4237 8.7641L9.85028 16.8572C8.02342 18.5818 5.04352 18.5818 3.21666 16.8572C1.41554 15.157 1.41554 12.42 3.21666 10.7197L11.7901 2.62659C12.8879 1.59028 14.6858 1.59028 15.7836 2.62659C16.8556 3.63861 16.8556 5.25985 15.7836 6.27187L7.20119 14.3647C6.83206 14.7131 6.21622 14.7131 5.84746 14.365C5.50444 14.0412 5.50444 13.5358 5.84746 13.212L13.7675 5.74446C14.1408 5.39248 14.1555 4.80719 13.8004 4.43717C13.4453 4.06716 12.8548 4.05254 12.4815 4.40452L4.56109 11.8724C3.44561 12.9254 3.44561 14.6516 4.56069 15.7042C5.65004 16.7325 7.39824 16.7325 8.48759 15.7042L17.07 7.6114C18.9145 5.87023 18.9145 3.02823 17.0703 1.2874C15.2519 -0.429133 12.3217 -0.429134 10.5033 1.2874L1.92989 9.38055C-0.643296 11.8096 -0.643296 15.7674 1.92989 18.1964C4.47734 20.6012 8.5896 20.6012 11.137 18.1964L19.7105 10.1033C20.0836 9.75109 20.098 9.16579 19.7426 8.79598C19.3873 8.42618 18.7968 8.4119 18.4237 8.7641Z"
      fill={color}
    />
  </svg>
);

export default Attach;
