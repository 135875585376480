import { takeLatest, put, call } from 'redux-saga/effects';

import { IVisit } from 'shared/interfaces/visit.interface';
import VisitsServcie from 'shared/services/VisitsService';
import { TAction } from 'shared/types';
import {
  setVisitsData,
  getVisitsFail,
  getVisitsData,
  setCurrentVisitData,
  getCurrentVisit
} from './visits.redux';

function* getVisitsSaga({ payload }: TAction<{ patientId: string }>) {
  try {
    if (payload) {
      const result: IVisit[] = yield call(
        VisitsServcie.getAllVisits,
        payload?.patientId
      );
      yield put(setVisitsData(result));
    }
  } catch (error) {
    yield put(getVisitsFail());
    console.log('getVisits error', error);
  }
}

function* getVisit({ payload }: TAction<string>) {
  try {
    const result: IVisit = yield call(VisitsServcie.getVisit, payload || '');

    yield put(setCurrentVisitData(result));
  } catch (error) {
    console.log('getVisit error', error);
  }
}

function* Visits() {
  yield takeLatest(getVisitsData.type, getVisitsSaga);
  yield takeLatest(getCurrentVisit.type, getVisit);
}

export default Visits;
