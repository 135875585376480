import { SvgIconType } from './svg.interface';

const Lock: SvgIconType = ({
  width = 26,
  height = 30,
  color = '#1e87f0',
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 26 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M13 2C5.8203 2 0 7.8203 0 15C0 22.1797 5.8203 28 13 28C20.1797 28 26 22.1797 26 15C26 7.8203 20.1797 2 13 2ZM12.9064 7.95092C14.6619 7.95929 16.2666 9.0788 16.9483 10.6408C17.1814 11.1848 17.3053 11.7437 17.3053 12.3499V13.8955H19.4349V22.0491H6.56506V13.8955H8.52332C8.50558 12.8034 8.48311 11.554 8.86609 10.6407C9.61077 8.99602 11.1508 7.94256 12.9064 7.95092ZM12.8112 10.5471C11.8387 10.5676 11.1974 11.307 11.1036 12.3498V13.8955H14.725V12.334C14.6803 11.3451 13.9319 10.5743 12.9063 10.5471C12.8746 10.5464 12.8429 10.5464 12.8112 10.5471Z"
      fill={color}
    />
  </svg>
);
export default Lock;
