import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Task: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 9.33331C12.4592 9.33331 13.9212 10.7482 13.9969 12.5221L14 12.6666V14C14 14.3682 13.7015 14.6666 13.3333 14.6666C12.9914 14.6666 12.7097 14.4093 12.6712 14.0777L12.6667 14V12.6666C12.6667 11.6015 11.8341 10.7309 10.7842 10.67L10.6667 10.6666H5.33333C4.26821 10.6666 3.39756 11.4993 3.33673 12.5491L3.33333 12.6666V14C3.33333 14.3682 3.03486 14.6666 2.66667 14.6666C2.32478 14.6666 2.043 14.4093 2.00449 14.0777L2 14V12.6666C2 10.8741 3.41487 9.4121 5.18874 9.33639L5.33333 9.33331H10.6667ZM8 1.33331C9.84095 1.33331 11.3333 2.8257 11.3333 4.66665C11.3333 6.5076 9.84095 7.99998 8 7.99998C6.15905 7.99998 4.66667 6.5076 4.66667 4.66665C4.66667 2.8257 6.15905 1.33331 8 1.33331ZM8 2.66665C6.89543 2.66665 6 3.56208 6 4.66665C6 5.77122 6.89543 6.66665 8 6.66665C9.10457 6.66665 10 5.77122 10 4.66665C10 3.56208 9.10457 2.66665 8 2.66665Z"
      fill={color}
      fillOpacity="0.88"
    />
  </svg>
);

export default Task;
