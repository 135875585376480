import { takeLatest, put, call, select, take } from 'redux-saga/effects';

import { NotificationMessages } from 'shared/enums';
import { USER_PROGRESS_DATA } from 'pages/OnlineVisit/constants';
import { IPatient } from 'shared/interfaces/patient.interface';
import { IOrder } from 'shared/interfaces/order.interface';
import PatientService from 'shared/services/PatientService';
import AddressService from 'shared/services/AddressService';
import {
  TAction,
  TCreatePatient,
  TCreatePatientAddress,
  TSetDefaultPatientAddress,
  TUpdatePatient,
  TUpdatePatientAddress,
  TUploadPatientFile
} from 'shared/types';
import { openNotificationMessage } from 'shared/utils/NotificationMessages';
import {
  getItemFromLocalStorage,
  removeOTPTokenFromLocalStorage,
  setTokenToLocalStorage
} from 'shared/utils/local-storage';
import {
  createPatient as createPatientAction,
  getPatientData,
  actionFail,
  setPatientAddressData,
  setPatientData,
  updatePatientData,
  updatePatientAddress,
  createPatientAddress,
  setDefaultPatientAddress,
  uploadPatientFile as uploadPatientFileAction,
  setPatientFiles
} from './patient.redux';
import {
  getCurrentOnlineVisitSelector,
  setCurrentOrder,
  setLoadingForm
} from 'store/onlineVisit/onlineVisit.redux';
import {
  createOrder as createOrderAction,
  getCurrentOrder
} from 'store/orders/orders.redux';
import { IAddressFull } from 'shared/interfaces/address.interface';
import { IFile } from 'shared/interfaces/file.interface';
import i18n, { I18N_NAMESPACE } from 'i18n';

function* createOrder({
  potentialOrder,
  patient
}: {
  potentialOrder: any;
  patient: IPatient;
}) {
  const potentialOrderAddress = potentialOrder.address;
  const address =
    potentialOrderAddress &&
    potentialOrderAddress.address1 &&
    potentialOrderAddress.city &&
    potentialOrderAddress.state &&
    potentialOrderAddress.zipcode
      ? { shipping: potentialOrderAddress, billing: potentialOrderAddress }
      : null;
  yield put(setLoadingForm(true));
  yield put(
    createOrderAction({
      patient: patient.id,
      affiliate: potentialOrder.affiliate,
      externalIdentifier: potentialOrder.externalIdentifier,
      project: potentialOrder.project,
      productVariations: potentialOrder.productVariations,
      address
    })
  );
}

function* createPatient({ payload }: TAction<TCreatePatient>) {
  try {
    yield put(setLoadingForm(true));
    const result: IPatient = yield call(
      PatientService.createPatient,
      payload as TCreatePatient
    );
    yield put(setPatientData(result));

    if (result) {
      yield put(setLoadingForm(false));
      if (result.token) {
        setTokenToLocalStorage(result.token);
        removeOTPTokenFromLocalStorage();
      }

      // ovs
      const order: IOrder = yield select(getCurrentOnlineVisitSelector);
      const potentialOrder = getItemFromLocalStorage(
        USER_PROGRESS_DATA.POTENTIAL_ORDER
      );
      if (!order && potentialOrder) {
        yield createOrder({ potentialOrder, patient: result });
        yield take(setCurrentOrder);
      }
      if (payload?.callback) {
        yield call(payload.callback);
      }
    }
  } catch (error: any) {
    yield put(actionFail());
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message:
        error.response.data.error.code === 11000
          ? 'The email has already been registered'
          : error.response.data.error
    });
    console.log('createPatient error', error);
  }
}

function* getPatient({ payload }: TAction<{ patientId: string }>) {
  try {
    const result: IPatient = yield call(PatientService.getPatient, payload);
    yield put(setPatientData(result));
  } catch (error) {
    yield put(actionFail());
    console.log('getPatient error', error);
  }
}

function* updatePatient({ payload }: TAction<TUpdatePatient>) {
  try {
    yield put(setLoadingForm(true));
    const result: IPatient = yield call(PatientService.editPatient, payload);
    yield put(setPatientData(result));
    const progress = getItemFromLocalStorage(USER_PROGRESS_DATA.USER_PROGRESS);
    if (!progress) {
      yield openNotificationMessage({
        type: NotificationMessages.Success,
        title: 'Success',
        message: "User's information was successfully updated."
      });
    }

    if (result) {
      // ovs
      yield put(setLoadingForm(false));
      const order: IOrder = yield select(getCurrentOnlineVisitSelector);
      const potentialOrder = getItemFromLocalStorage(
        USER_PROGRESS_DATA.POTENTIAL_ORDER
      );
      if (order) {
        yield put(setCurrentOrder({ ...order, patient: result }));
      }
      if (!order && potentialOrder) {
        let order;
        if (potentialOrder.id || potentialOrder.externalIdentifier) {
          yield put(
            getCurrentOrder(
              potentialOrder.id || potentialOrder.externalIdentifier
            )
          );
          const { payload: existingOrder } = yield take(setCurrentOrder);
          order = existingOrder;
        }
        if (!order) {
          yield createOrder({ potentialOrder, patient: result });
          yield take(setCurrentOrder);
        }
      }
      if (payload?.callback) {
        yield call(payload.callback);
      }
    }
  } catch (error: any) {
    yield put(actionFail());
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message:
        error.response.data.error.code === 11000
          ? 'The email has already been registered'
          : 'Internal Server Error'
    });
    console.log('updatePatient error', error);
  }
}

function* setDefaultAddress({ payload }: TAction<TSetDefaultPatientAddress>) {
  try {
    const result: IAddressFull = yield call(
      AddressService.setDefaultAddress,
      payload as TSetDefaultPatientAddress
    );
    yield put(setPatientAddressData(result));
    yield openNotificationMessage({
      type: NotificationMessages.Success,
      title: 'Success',
      message: 'Address was successfully updated'
    });
  } catch (error) {
    yield put(actionFail());
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message: 'Address was not updated.'
    });
    console.log('setDefaultAddress error', error);
  }
}

function* createAddress({ payload }: TAction<TCreatePatientAddress>) {
  try {
    const result: IAddressFull[] = yield call(
      AddressService.createAddress,
      payload as TCreatePatientAddress
    );
    yield put(setPatientAddressData(result));
    yield openNotificationMessage({
      type: NotificationMessages.Success,
      title: 'Success',
      message: 'Address was successfully create'
    });
  } catch (error) {
    yield put(actionFail());
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message: 'Address was not created.'
    });
    console.log('createAddress error', error);
  }
}

function* updateAddress({ payload }: TAction<TUpdatePatientAddress>) {
  try {
    const result: IPatient = yield call(
      AddressService.editAddress,
      payload as TUpdatePatientAddress
    );
    yield put(setPatientAddressData(result));
    yield openNotificationMessage({
      type: NotificationMessages.Success,
      title: 'Success',
      message: 'Address was successfully updated'
    });
  } catch (error) {
    yield put(actionFail());
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: 'Error',
      message: 'Address was not updated.'
    });
    console.log('updatePatientAddress error', error);
  }
}

function* uploadPatientFile({ payload }: TAction<TUploadPatientFile>) {
  try {
    const result: IFile = yield call(
      PatientService.uploadFile,
      payload as TUploadPatientFile
    );
    yield put(setPatientFiles(result));
    yield openNotificationMessage({
      type: NotificationMessages.Success,
      title: i18n.t('general.success', {
        defaultValue: 'Success',
        ns: I18N_NAMESPACE.general
      }),
      message: i18n.t('documents.uploadedSuccessfully', {
        defaultValue: 'The file was uploaded successfully',
        ns: I18N_NAMESPACE.documents
      })
    });
  } catch (error) {
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: i18n.t('general.error', {
        defaultValue: 'Error',
        ns: I18N_NAMESPACE.general
      }),
      message: i18n.t('documents.notUploaded', {
        defaultValue: 'The file was not uploaded',
        ns: I18N_NAMESPACE.documents
      })
    });
    console.log('uploadPatientFile error', error);
  }
}

function* User() {
  yield takeLatest(createPatientAction.type, createPatient);
  yield takeLatest(getPatientData.type, getPatient);
  yield takeLatest(updatePatientData.type, updatePatient);
  yield takeLatest(updatePatientAddress.type, updateAddress);
  yield takeLatest(createPatientAddress.type, createAddress);
  yield takeLatest(setDefaultPatientAddress.type, setDefaultAddress);
  yield takeLatest(uploadPatientFileAction.type, uploadPatientFile);
}

export default User;
