import { http, setupToken } from '../utils/axios';
import {
  ICompleteQuestionnaireInstance,
  IQuestionnaireInstance
} from '../interfaces/questionnaire-instance.interface';
import {
  TGetQIPayload,
  TAnswerQILocationPayload,
  TAnswerConfig,
  TAnswerQIPayload,
  TNavigateBackPayload
} from '../types';

class QuestionnaireService {
  private static instance: QuestionnaireService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): QuestionnaireService {
    if (!QuestionnaireService.instance) {
      QuestionnaireService.instance = new QuestionnaireService();
    }

    return QuestionnaireService.instance;
  }

  getQuestionnaireInstanceByPatientAndQuestionnaire = async (payload: {
    questionnaire: string;
    patient: string;
  }) => {
    const { data } = await http<IQuestionnaireInstance>({
      url: `/questionnaireInstances/getByPatientAndQuestionnaire/${payload.patient}/${payload.questionnaire}`,
      method: 'GET'
    });

    return data;
  };

  getQuestionnaireInstance = async ({ token, id }: TGetQIPayload) => {
    const { data } = await http<IQuestionnaireInstance>({
      method: 'GET',
      url: `/questionnaireInstances/${id}`,
      ...(token ? setupToken(token) : {})
    });

    return data;
  };

  answerQuestionnaireInstanceLocation = async ({
    answerData,
    config: {
      modifier,
      shouldNavigateNext = true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onUploadProgress = () => {}
    } = {} as TAnswerConfig,
    token
  }: TAnswerQILocationPayload) => {
    const { data } = await http<IQuestionnaireInstance>({
      method: 'PUT',
      url: `/questionnaireInstances/${answerData.questionnaireInstance}/actions/answerLocation`,
      params: {
        modifier,
        shouldNavigateNext
      },
      data: answerData,
      onUploadProgress,
      ...(token ? setupToken(token) : {})
    });

    return data;
  };

  answerQuestionnaireInstance = async ({
    id,
    answer,
    token
  }: TAnswerQIPayload) => {
    const { data } = await http<IQuestionnaireInstance>({
      method: 'PUT',
      url: `/questionnaireInstances/${id}/actions/answer`,
      data: answer,
      ...(token ? setupToken(token) : {})
    });

    return data;
  };

  navigateBack = async (payload: TNavigateBackPayload) => {
    const { data } = await http<IQuestionnaireInstance>({
      method: 'PUT',
      url: `/questionnaireInstances/${payload.questionnaireInstance}/navigateBack`,
      data: payload,
      ...(payload.token ? setupToken(payload.token) : {})
    });

    return data;
  };

  updateQuestionnaireInstance = async (payload: any) => {
    const url = payload.isInviteToken
      ? `/questionnaireInstances/completeQuestionnaire/${payload.id}`
      : `/questionnaireInstances/${payload.id}`;

    const { data } = await http<IQuestionnaireInstance>({
      method: 'PUT',
      url,
      data: payload,
      ...(payload.token ? setupToken(payload.token) : {})
    });

    return data;
  };

  verifyInviteToken = async (inviteToken: string) => {
    const { data } = await http<ICompleteQuestionnaireInstance>({
      url: `/patients/actions/verifyInviteCompleteQuestionnaireToken?invite_token=${inviteToken}`,
      method: 'GET'
    });

    return data;
  };
}

const instance = QuestionnaireService.getInstance();

export default instance;
