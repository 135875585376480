import { createAction, createSlice } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { setOTPTokenToLocalStorage } from 'shared/utils/local-storage';
import { initialStore } from '../initial-store';
import { TCheckUserExistingAndSendOtp } from 'shared/types';

export const checkUserExistingAndSendOtp = createAction(
  'auth/checkUserExistingAndSendOtp',
  (payload: TCheckUserExistingAndSendOtp) => ({ payload })
);

const authSlice = createSlice({
  name: 'auth',
  initialState: initialStore.auth,
  reducers: {
    setTwoFactorType: (state, { payload }) => {
      if (payload.user) {
        state.twoFAOptions = {
          email: payload.user.email,
          phone: payload.user.phone
        };
        setOTPTokenToLocalStorage(payload.token);
      }
    },
    change2FAType: (state, { payload }) => {
      state.twoFactorType = payload;
    },
    checkUserExistingAndSendOtpSuccess: (state, { payload }) => {
      state.isUserExisting = payload.isUserExisting;
      state.email = payload.email;
      state.phone = payload.phone;
    }
  }
});

export const {
  setTwoFactorType,
  change2FAType,
  checkUserExistingAndSendOtpSuccess
} = authSlice.actions;

export const { reducer } = authSlice;

// Selectors
export const getTwoFactorAuthSelector = (state: IState) =>
  state && state.auth.twoFactorType;

export const getTwoFAOptionsSelector = (state: IState) =>
  state && state.auth.twoFAOptions;

export const getMessageAuthSelector = (state: IState) =>
  state && state.auth.message;

export const getIsUserExistingAuthSelector = (state: IState) =>
  state && state.auth.isUserExisting;

export const getEmailAuthSelector = (state: IState) =>
  state && state.auth.email;

export const getPhoneAuthSelector = (state: IState) =>
  state && state.auth.phone;
