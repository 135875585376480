import React, { useState, useEffect, PropsWithChildren, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import { useMobile } from 'shared/utils/hooks/useMobile';
import CardChannel from './CardChannel';
import { IChannel } from 'shared/interfaces/channel.interface';
import {
  getOtherParticipantAvatar,
  getOtherParticipantName
} from 'shared/helpers/channelHelpers';
import { getUserSelector } from 'store/user/user.redux';
import { getChannelsSelector } from 'store/channel/channel.redux';
import {
  getTwilioConversationObjectsSelector,
  getConversation,
  upgradeTwilioConversation
} from 'store/conversation/conversation.redux';
import { useNavigate } from 'react-router-dom';
import { fileMessageFormats } from 'shared/constants/files';
import { truncateFileName } from 'shared/utils/truncateFileName';

import classes from './styles.module.scss';

interface ICard {
  setShowChatWindow?: (value: boolean) => void;
}

const Submenu: React.FC<PropsWithChildren<ICard>> = ({ setShowChatWindow }) => {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUserSelector);
  const selectedChatInPath = window.location.href.split('id=')[1];
  const twilioConversationObjects = useSelector(
    getTwilioConversationObjectsSelector
  );
  const channels = useSelector(getChannelsSelector);
  const filteredChannels = channels.filter((channel) => channel.conversation);
  const [currentChannel, setCurrentChannel] = useState<
    IChannel | null | undefined
  >(null);

  const getTwilioConversationObject = (channel: IChannel) => {
    return twilioConversationObjects.find(
      (conversation) => conversation.channel === channel.id
    );
  };

  const orderedChannels = useMemo(() => {
    const channelIdToChannelMap = new Map();

    filteredChannels.forEach((channel) => {
      channelIdToChannelMap.set(channel.id, channel);
    });

    return twilioConversationObjects.map((conversation) => {
      const channel = channelIdToChannelMap.get(conversation.channel);
      return channel || {};
    });
  }, [twilioConversationObjects, filteredChannels]);

  const getCardChannelProps = (channel: IChannel) => {
    const twilioConversationObject = getTwilioConversationObject(channel);
    const message =
      twilioConversationObject?.lastMessage?.text &&
      ReactHtmlParser(
        twilioConversationObject?.lastMessage?.text?.replace(/\n/g, '<br>')
      );
    const timestamp = twilioConversationObject?.lastMessage?.timestamp;
    const content = channel?.lastMessage?.content || '';

    return {
      id: channel.id,
      message: truncateFileName(content) || message,
      contactName: getOtherParticipantName(user, channel.participants),
      msgDate: timestamp,
      msgCount: twilioConversationObject?.unreadCount || 0,
      avatar: getOtherParticipantAvatar(user, channel.participants),
      attachedFile:
        content && fileMessageFormats.includes(content.split('.').pop() || '')
    };
  };

  useEffect(() => {
    if (
      !currentChannel &&
      channels.length &&
      twilioConversationObjects?.length
    ) {
      selectedChatInPath
        ? setCurrentChannel(
            orderedChannels.find(
              (channel) => channel?.id === selectedChatInPath
            )
          )
        : setCurrentChannel(orderedChannels[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channels, twilioConversationObjects]);

  useEffect(() => {
    if (currentChannel) {
      // If there is no selectedConversation then this
      // user has to get one by upgrading his channel
      if (!currentChannel.conversation) {
        dispatch(upgradeTwilioConversation({ channelId: currentChannel.id }));
        return;
      }
      dispatch(
        getConversation({
          conversationId: currentChannel.conversation.id
        })
      );
    }
  }, [dispatch, currentChannel]);

  const setChannel = (channel: IChannel) => {
    setCurrentChannel(channel);
    setShowChatWindow && setShowChatWindow(true);
  };

  useEffect(() => {
    if (currentChannel) {
      navigate(`?id=${currentChannel.id}`);
    }
  }, [currentChannel]);

  return (
    <>
      <div className={classes['content']}>
        <ul
          className={
            isMobile
              ? classes['content__list-card-mobile']
              : classes['content__list-card-desktop']
          }
        >
          {!!orderedChannels?.length &&
            orderedChannels.map((channel: IChannel | undefined) => {
              if (!channel) return;
              return (
                <li key={channel.id} onClick={() => setChannel(channel)}>
                  <CardChannel {...getCardChannelProps(channel)} />
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default Submenu;
