import React from 'react';
import { Avatar } from 'antd';

import { Text } from 'shared/components/Text';
import classes from './styles.module.scss';
import { useMobile } from 'shared/utils/hooks/useMobile';
import { TextWithTooltip } from 'shared/components/TextWithTooltip';
import { ICardChannel } from 'shared/interfaces/channel.interface';
import FileDocumentIcon from '../Icons/FileDocument';
import { DefaultUserIcon } from '../Icons';
import { themeIconDark } from 'shared/utils/cssUtility';

const CardChannel: React.FC<ICardChannel> = ({
  message,
  contactName,
  msgDate,
  msgCount,
  avatar,
  attachedFile
}) => {
  const isMobile = useMobile();
  return (
    <>
      {isMobile ? (
        <div className={classes['card-wrapper']}>
          <div className={classes['card-row']}>
            <div>
              <Avatar
                size={56}
                className={classes['card-content__avatar']}
                src={avatar}
                icon={!avatar && <DefaultUserIcon />}
              />
            </div>
            <div className={classes['card-content']}>
              <div className={classes['card-content__row']}>
                <TextWithTooltip type="sf-body-emphasized" color="text-primary">
                  {contactName}
                </TextWithTooltip>
                <Text
                  type="sf-caption"
                  color="text-secondary"
                  className={classes['dontShrink']}
                >
                  {msgDate}
                </Text>
              </div>
              <div className={classes['card-content__row']}>
                {attachedFile ? (
                  <div className={classes['message-with-attachment']}>
                    <span className={classes['attached-file']}>
                      <FileDocumentIcon
                        width={12}
                        height={13}
                        color={themeIconDark}
                      />
                    </span>
                    <TextWithTooltip
                      className={classes['text-ellipsis']}
                      type="sf-caption"
                      color="text-secondary"
                    >
                      {message}
                    </TextWithTooltip>
                  </div>
                ) : (
                  <TextWithTooltip
                    className={classes['text-ellipsis']}
                    type="sf-caption"
                    color="text-secondary"
                  >
                    {message}
                  </TextWithTooltip>
                )}

                {msgCount && msgCount > 0 ? (
                  <Text
                    type="sf-caption-emphasized"
                    color="text-light"
                    className={classes['badge']}
                  >
                    {msgCount}
                  </Text>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes['wrapper']}>
          <div className={classes['card-wrapper__desktop']}>
            <div className={classes['card-row']}>
              <div>
                <Avatar
                  size={56}
                  className={classes['card-content__avatar']}
                  src={avatar}
                  icon={!avatar && <DefaultUserIcon />}
                />
              </div>
              <div className={classes['card-content']}>
                <div className={classes['card-content__row']}>
                  <TextWithTooltip
                    type="sf-body-emphasized"
                    color="text-primary"
                  >
                    {contactName}
                  </TextWithTooltip>
                  <div className={classes['card-content__row_date']}>
                    <Text type="sf-caption" color="text-secondary">
                      {msgDate}
                    </Text>
                  </div>
                </div>
                <div className={classes['card-content__row']}>
                  {attachedFile ? (
                    <div className={classes['message-with-attachment']}>
                      <span className={classes['attached-file']}>
                        <FileDocumentIcon
                          width={12}
                          height={13}
                          color={themeIconDark}
                        />
                      </span>
                      <TextWithTooltip
                        className={classes['text-ellipsis']}
                        type="sf-caption"
                        color="text-secondary"
                      >
                        {message}
                      </TextWithTooltip>
                    </div>
                  ) : (
                    <TextWithTooltip
                      className={classes['text-ellipsis']}
                      type="sf-caption"
                      color="text-secondary"
                    >
                      {message}
                    </TextWithTooltip>
                  )}
                  {msgCount && msgCount > 0 ? (
                    <Text
                      type="sf-caption-emphasized"
                      color="text-light"
                      className={classes['badge']}
                    >
                      {msgCount}
                    </Text>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CardChannel;
