import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    const data: { [key: string]: string | null } = {};

    for (const item of params.keys()) {
      data[item] = params.has(item)
        ? decodeURIComponent(params.get(item) || '')
        : null;
    }

    return data;
  }, [search]);
};
