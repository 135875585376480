import ReactPixel from 'react-facebook-pixel';

import { FacebookPixelEvents } from 'shared/enums';
import {
  WHITE_LABEL_AFFILIATE,
  getItemFromLocalStorage
} from 'shared/utils/local-storage';

export const isReglow = (whiteLabelAffiliate?: string) => {
  const isReglow =
    (whiteLabelAffiliate || getItemFromLocalStorage(WHITE_LABEL_AFFILIATE)) ===
    'aff::af4948be-7086-4375-8af6-57c953bc3e07';
  return isReglow;
};

export const initFacebookPixel = () => {
  ReactPixel.init(process.env.REACT_APP_REGLOW_PIXEL_ID || '');
};

export const pageViewEvent = () => {
  ReactPixel.pageView();
};

export const subscribeEvent = () => {
  ReactPixel.track(FacebookPixelEvents.Subscribe, {});
};
