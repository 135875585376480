import { themeExternalDark } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Back: SvgIconType = ({
  width = 11,
  height = 18,
  className = '',
  color = themeExternalDark
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M10 17L2 9L10 1" stroke={color} strokeWidth="2" />
  </svg>
);

export default Back;
