import { themeIconDark } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const TrashExternalVisit: SvgIconType = ({
  width = 14,
  height = 16,
  color = themeIconDark,
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.00195 3V4H15.0055V3C15.0055 2.44772 14.5578 2 14.0055 2H10C10 0.89543 9.1046 0 8.00003 0C6.89546 0 6.00003 0.89543 6.00003 2H2.00195C1.44967 2 1.00195 2.44772 1.00195 3Z"
      fill={color}
    />
    <path
      d="M13.0475 15.0499L13.5 6H2.50003L2.95253 15.0499C2.97914 15.5821 3.41841 16 3.95128 16H12.0488C12.5817 16 13.0209 15.5821 13.0475 15.0499Z"
      fill={color}
    />
  </svg>
);

export default TrashExternalVisit;
