import { FC, useState } from 'react';
import { Tooltip } from 'antd';

import { Text, IText } from '../Text';
import classes from './styles.module.scss';
import { truncateFileName } from 'shared/utils/truncateFileName';
import { useMobile } from 'shared/utils/hooks/useMobile';
import { themeBackgroundPrimary } from 'shared/utils/cssUtility';

interface ITextWithTooltip extends IText {
  // antd doesn't provide a type for placement. placement should be a string, any for fix TS error
  placement?: string | any;
  arrowPointAtCenter?: boolean;
  tooltipColor?: string;
  isFile?: boolean;
}

export const TextWithTooltip: FC<ITextWithTooltip> = ({
  children,
  placement = 'bottomLeft',
  arrowPointAtCenter = true,
  tooltipColor = themeBackgroundPrimary,
  isFile = false,
  ...other
}) => {
  const isMobile = useMobile();
  const [visible, setVisible] = useState(false);
  const truncatedText = truncateFileName(
    typeof children === 'string' ? children : '',
    isMobile ? 10 : 20
  );

  const handleMouseEnter = (event: any) => {
    const { target } = event;
    if (
      target.offsetWidth < target.scrollWidth ||
      truncatedText.includes('....')
    ) {
      setVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (visible) {
      setVisible(false);
    }
  };

  const mouseEvents = {
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave
  };

  const touchEvents = {
    onTouchStart: handleMouseEnter,
    onTouchEnd: handleMouseLeave
  };

  const eventHandlers = isMobile ? touchEvents : mouseEvents;

  return (
    <Tooltip
      open={visible}
      title={<Text type="sf-caption">{children}</Text>}
      placement={placement}
      arrow={{ pointAtCenter: arrowPointAtCenter }}
      color={tooltipColor}
      overlayClassName="tooltip"
      overlayInnerStyle={{
        padding: '8px 16px'
      }}
    >
      <Text className={classes.ellipsis} {...eventHandlers} {...other}>
        {isFile ? truncatedText : children}
      </Text>
    </Tooltip>
  );
};
