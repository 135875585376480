import { useLayoutEffect, useState } from 'react';

import { Breakpoints } from '../../constants/breakpoints';
import { useResizeParams } from './useResizeParams';

export const useMobile = () => {
  const currentWidth = useResizeParams();
  const [width, setWidth] = useState(currentWidth);
  const isMobile = width <= Breakpoints.Min && width > 0;

  useLayoutEffect(() => {
    if (!currentWidth) {
      setWidth(window.innerWidth);
    } else {
      setWidth(currentWidth);
    }
  }, [currentWidth]);

  return isMobile;
};
