import { http } from '../utils/axios';
import { TGetConditionsSymptomsPayload } from 'shared/types';
import { getItemFromLocalStorage } from 'shared/utils/local-storage';
import { USER_PROGRESS_DATA } from 'pages/OnlineVisit/constants';

class ProductService {
  private static instance: ProductService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): ProductService {
    if (!ProductService.instance) {
      ProductService.instance = new ProductService();
    }
    return ProductService.instance;
  }

  getConditionsAndSymptoms = async ({
    products
  }: TGetConditionsSymptomsPayload) => {
    const token = getItemFromLocalStorage(USER_PROGRESS_DATA.TOKEN);
    const { data } = await http<{ conditions: []; symptoms: [] }>({
      method: 'GET',
      url: `/products/actions/getConditionsAndSymptoms?products=${products}`,
      params: {
        access_token: token
      }
    });

    return data;
  };
}

const instance = ProductService.getInstance();

export default instance;
