import dayjs from 'dayjs';
import i18n from 'i18next';
import { APP_LANGUAGES } from 'shared/enums';
import { LANGUAGE, setItemToLocalStorage } from 'shared/utils/local-storage';

export const changeLanguage = (language: APP_LANGUAGES) => {
  setItemToLocalStorage(LANGUAGE, language);
  i18n.changeLanguage(language);
  dayjs.locale(language);
};
