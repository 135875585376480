import { createAction, createSlice } from '@reduxjs/toolkit';

import { initialStore } from '../initial-store';
import { IState } from 'shared/interfaces/state.interface';
import { TCreateOrderPayload } from 'shared/types';
import { IOrder } from 'shared/interfaces/order.interface';

export const createOrder = createAction(
  'orders/createOrder',
  (payload: TCreateOrderPayload) => ({ payload })
);

export const updateDataOrder = createAction(
  'orders/updateDataOrder',
  (payload: {
    id: string;
    newData: IOrder | Partial<IOrder>;
    callback: () => void;
  }) => ({ payload })
);

const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialStore.orders,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOrdersData: (state, { payload }) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setOrdersData: (state, { payload }) => {
      state.isLoading = false;
      state.orders = payload;
    },
    getOrdersFail: (state) => {
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCurrentOrder: (state, data) => {
      state.isLoading = true;
    },
    setCurrentOrderData: (state, { payload }) => {
      state.isLoading = false;
      state.currentOrder = payload;
    },
    getCurrentOrderFail: (state) => {
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    processOrderPayment: (state, { payload }) => {
      state.orderPaymentIsLoading = true;
    },
    processOrderPaymentFail: (state) => {
      state.orderPaymentIsLoading = false;
    }
  }
});

export const {
  getOrdersData,
  setOrdersData,
  getOrdersFail,
  getCurrentOrder,
  setCurrentOrderData,
  getCurrentOrderFail,
  processOrderPayment,
  processOrderPaymentFail
} = ordersSlice.actions;

export const { reducer } = ordersSlice;

// Selectors
export const getOrdersSelector = (state: IState) =>
  state && state.orders.orders;

export const getOrdersLoadingSelector = (state: IState) =>
  state && state.orders.isLoading;

export const getOrderPaymentIsLoadingSelector = (state: IState) =>
  state && state.orders.orderPaymentIsLoading;

export const getCurrentOrderSelector = (state: IState) =>
  state && state.orders.currentOrder;
