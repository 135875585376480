import { generalInfo } from 'shared/constants/general-info';
import { baseTheme } from 'shared/themes/baseTheme';
import { IState } from '../shared/interfaces/state.interface';

export const initialStore: IState = {
  app: {
    appConfig: {
      appName: generalInfo.appName,
      companyUrl: generalInfo.companyUrl,
      logo: 'TelegraLogo',
      smallLogo: generalInfo.smallLogo,
      logoWithName: generalInfo.logoWithName
    },
    appTheme: baseTheme,
    whiteLabelAffiliate: null,
    affiliateStylesConfig: null
  },
  auth: {
    twoFactorType: null,
    message: null,
    twoFAOptions: null,
    isUserExisting: undefined,
    email: undefined,
    phone: undefined
  },
  user: {
    isLoading: false,
    data: null,
    currentVisit: null
  },
  channel: {
    isLoading: true,
    channels: []
  },
  conversation: {
    isLoading: true,
    conversationToken: '',
    twilioConversationObjects: [],
    selectedConversation: null
  },
  notifications: {
    isLoading: true,
    data: []
  },
  visits: {
    isLoading: true,
    data: [],
    currentVisit: null
  },
  orders: {
    isLoading: true,
    orderPaymentIsLoading: false,
    orders: [],
    currentOrder: null
  },
  labOrders: {
    isLoading: false,
    currentLabOrder: null
  },
  ecommerce: {
    isLoading: true,
    ecommerceOrders: [],
    ecommerceCurrentOrder: null,
    ecommerceSubscriptions: [],
    ecommerceCurrentSubscription: null
  },
  tasks: {
    isLoading: true,
    data: []
  },
  onlineVisit: {
    isLoading: false,
    isLoadingForm: false,
    currentOrder: null,
    affiliate: null,
    questionnaireInstances: [],
    currentQuestionnaireInstance: null,
    availableStates: [],
    questionnaires: {
      answers: [],
      disableArray: []
    },
    possibleSymptoms: [],
    payments: {
      setupIntent: null,
      defaultPaymentMethod: null,
      availablePaymentMethods: [],
      isAttachedPaymentIntent: false,
      isAttachPaymentIntentFail: false
    },
    visitLink: '',
    validationError: false,
    availablePharmacies: [],
    potentialPharmacies: [],
    completeQuestionnaireInstance: null
  },
  patientNotes: {
    isLoading: true,
    data: []
  },
  billing: {
    availablePaymentMethods: [],
    defaultPaymentMethod: '',
    isLoading: true,
    setupIntent: null
  },
  patient: {
    data: null,
    isLoading: false
  },
  states: {
    allStates: [],
    isLoading: true
  }
};
