import { SvgIconType } from './svg.interface';

const WarningTriangle: SvgIconType = ({
  width = 12,
  height = 12,
  color = '#F6E6BA',
  className = ''
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.58657 0.981064L11.8123 9.43256C12.2505 10.3089 11.8953 11.3745 11.0189 11.8127C10.7726 11.9359 10.501 12 10.2256 12H1.77406C0.794273 12 0 11.2057 0 10.2259C0 9.95053 0.0641235 9.6789 0.187292 9.43256L4.41304 0.981064C4.85121 0.104717 5.91684 -0.250492 6.79319 0.187681C7.13652 0.359345 7.41491 0.637735 7.58657 0.981064ZM6 7.875C5.37868 7.875 4.875 8.37868 4.875 9C4.875 9.62132 5.37868 10.125 6 10.125C6.62132 10.125 7.125 9.62132 7.125 9C7.125 8.37868 6.62132 7.875 6 7.875ZM6 6.75C6.41421 6.75 6.75 6.51015 6.75 6.21429V3.53571C6.75 3.23985 6.41421 3 6 3C5.58579 3 5.25 3.23985 5.25 3.53571V6.21429C5.25 6.51015 5.58579 6.75 6 6.75Z"
      fill={color}
    />
  </svg>
);

export default WarningTriangle;
