import { takeLatest, put, call } from 'redux-saga/effects';
import i18n, { I18N_NAMESPACE } from 'i18n';

import {
  getEcommerceOrders,
  setEcommerceOrders,
  getEcommerceCurrentOrder,
  setEcommerceCurrentOrder,
  getEcommerceSubscriptions,
  setEcommerceSubscriptions,
  getEcommerceCurrentSubscription,
  setEcommerceCurrentSubscription,
  getOrderReceipt,
  actionFail
} from './ecommerce.redux';
import EcommerceService from 'shared/services/EcommerceService';
import { TAction } from 'shared/types';
import { IEcommerceOrder } from 'shared/interfaces/ecommerceOrder.interface';
import { IEcommerceSubscription } from 'shared/interfaces/ecommerceSubscription.interface';
import { openNotificationMessage } from 'shared/utils/NotificationMessages';
import { NotificationMessages } from 'shared/enums';
import { downloadFile } from 'shared/utils/downloadFile';

function* getEcommerceOrdersSaga({
  payload
}: TAction<{
  token?: string;
  affiliate?: string;
}>) {
  try {
    const result: IEcommerceOrder[] = yield call(
      EcommerceService.getEcommerceOrders,
      payload
    );

    yield put(setEcommerceOrders(result));
  } catch (error) {
    yield put(actionFail());
    console.log('getOrders error', error);
  }
}

function* getEcommerceOrderSaga({ payload }: TAction<string>) {
  try {
    const result: IEcommerceOrder = yield call(
      EcommerceService.getEcommerceOrder,
      payload
    );
    yield put(setEcommerceCurrentOrder(result));
  } catch (error) {
    yield put(actionFail());
    console.log('getOrder error', error);
  }
}

function* getEcommerceSubscriptionsSaga({
  payload
}: TAction<{
  token?: string;
  affiliate?: string;
}>) {
  try {
    const result: IEcommerceSubscription[] = yield call(
      EcommerceService.getEcommerceSubscriptions,
      payload
    );

    yield put(setEcommerceSubscriptions(result));
  } catch (error) {
    yield put(actionFail());
    console.log('getOrders error', error);
  }
}

function* getEcommerceSubscriptionSaga({ payload }: TAction<string>) {
  try {
    const result: IEcommerceSubscription = yield call(
      EcommerceService.getEcommerceSubscription,
      payload
    );
    yield put(setEcommerceCurrentSubscription(result));
  } catch (error) {
    yield put(actionFail());
    console.log('getOrder error', error);
  }
}

function* getOrderReceiptSaga({
  payload
}: TAction<{ orderId: string; affiliate: string }>) {
  try {
    const result: string = yield call(
      EcommerceService.getOrderReceipt,
      payload
    );
    downloadFile(result, 'receipt.pdf', 'application/pdf');
    yield openNotificationMessage({
      type: NotificationMessages.Success,
      title: i18n.t('ordersHistory.success', {
        defaultValue: 'Success',
        ns: I18N_NAMESPACE.ordersHistory
      }),
      message: i18n.t('ordersHistory.recipeDownloadedSuccessfully', {
        defaultValue: 'Recipe downloaded successfully',
        ns: I18N_NAMESPACE.ordersHistory
      })
    });
  } catch (error) {
    console.log('getOrderReceipt error', error);
    yield put(actionFail());
    yield openNotificationMessage({
      type: NotificationMessages.Error,
      title: i18n.t('ordersHistory.error', {
        defaultValue: 'Error',
        ns: I18N_NAMESPACE.ordersHistory
      }),
      message: error?.response?.data?.message
    });
  }
}

function* Visits() {
  yield takeLatest(getEcommerceOrders.type, getEcommerceOrdersSaga);
  yield takeLatest(getEcommerceCurrentOrder.type, getEcommerceOrderSaga);
  yield takeLatest(
    getEcommerceSubscriptions.type,
    getEcommerceSubscriptionsSaga
  );
  yield takeLatest(
    getEcommerceCurrentSubscription.type,
    getEcommerceSubscriptionSaga
  );
  yield takeLatest(getOrderReceipt.type, getOrderReceiptSaga);
}

export default Visits;
