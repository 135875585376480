import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { initialStore } from './initial-store';
import { reducer as appReducer, resetApp } from './app/app.redux';
import { reducer as userReducer } from './user/user.redux';
import { reducer as channelReducer } from './channel/channel.redux';
import { reducer as conversationReducer } from './conversation/conversation.redux';
import { reducer as onlineVisitReducer } from './onlineVisit/onlineVisit.redux';
import { reducer as authReducer } from './auth/auth.redux';
import { reducer as notificationsReducer } from './notifications/notification.redux';
import { reducer as visitsReducer } from './visits/visits.redux';
import { reducer as tasksReducer } from './tasks/tasks.redux';
import { reducer as patientNotesReducer } from './patientNotes/patientNotes.redux';
import { reducer as ordersReducer } from './orders/orders.redux';
import { reducer as billingReducer } from './billing/billing.redux';
import { reducer as patientReducer } from './patient/patient.redux';
import { reducer as statesReducer } from './states/states.redux';
import { reducer as ecommerceReducer } from './ecommerce/ecommerce.redux';
import { reducer as labOrdersReducer } from './labOrders/labOrders.redux';

const globalReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  channel: channelReducer,
  conversation: conversationReducer,
  onlineVisit: onlineVisitReducer,
  notifications: notificationsReducer,
  visits: visitsReducer,
  auth: authReducer,
  tasks: tasksReducer,
  patientNotes: patientNotesReducer,
  orders: ordersReducer,
  billing: billingReducer,
  patient: patientReducer,
  states: statesReducer,
  ecommerce: ecommerceReducer,
  labOrders: labOrdersReducer
});

const rootReducer = (state: IState, action: AnyAction) => {
  if (action.type === resetApp.type) {
    state = initialStore;
  }

  return globalReducer(state, action);
};

export default rootReducer;
