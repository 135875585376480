import { http } from '../utils/axios';
import { getItemFromLocalStorage } from 'shared/utils/local-storage';
import { USER_PROGRESS_DATA } from 'pages/OnlineVisit/constants';
import { TAddPaymentMethodAction, TStripeIntent } from 'shared/types';
import { IPaymentMethod } from 'shared/interfaces/payment-method.interface';

class StripeService {
  private static instance: StripeService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): StripeService {
    if (!StripeService.instance) {
      StripeService.instance = new StripeService();
    }

    return StripeService.instance;
  }

  getAvailablePaymentMethods = async (payload: { patient?: string }) => {
    const token = getItemFromLocalStorage(USER_PROGRESS_DATA.TOKEN);
    const { data } = await http<IPaymentMethod[]>({
      url: '/billingDetails/actions/getAvailablePaymentMethods',
      method: 'GET',
      params: { ...payload, access_token: token }
    });

    return data;
  };

  getPaymentMethod = async (payload: string) => {
    const token = getItemFromLocalStorage(USER_PROGRESS_DATA.TOKEN);
    const { data } = await http<IPaymentMethod>({
      url: `/billingDetails/actions/getPaymentMethod/${payload}`,
      method: 'GET',
      params: {
        access_token: token
      }
    });

    return data;
  };

  createStripeSetupIntentPreauthenticatedUser = async () => {
    const token = getItemFromLocalStorage(USER_PROGRESS_DATA.TOKEN);
    const { data } = await http<any>({
      url: '/billingDetails/actions/setupIntentPreauthenticatedUser',
      method: 'POST',
      params: {
        access_token: token
      }
    });

    return data;
  };

  createStripeSetupIntent = async () => {
    const { data } = await http<TStripeIntent>({
      url: '/billingDetails/actions/stripe/setupIntent',
      method: 'POST'
    });

    return data;
  };

  addPaymentMethod = async (payload: TAddPaymentMethodAction) => {
    const { data } = await http<IPaymentMethod>({
      url: '/billingDetails/actions/attachPaymentMethod',
      method: 'POST',
      data: payload
    });

    return data;
  };

  removeCard = async ({ patientId, paymentMethodId }: any) => {
    const { data } = await http<any>({
      url: '/billingDetails/actions/removeCard',
      method: 'DELETE',
      data: {
        patientId,
        paymentMethodId
      }
    });

    return data;
  };

  setDefaultCard = async ({
    patientId,
    paymentMethodId,
    affiliateId
  }: {
    patientId: string;
    paymentMethodId: string;
    affiliateId?: string;
  }) => {
    const { data } = await http<IPaymentMethod>({
      url: '/billingDetails/actions/setDefaultCard',
      method: 'PATCH',
      data: {
        patientId,
        paymentMethodId,
        affiliateId
      }
    });

    return data;
  };
}

const instance = StripeService.getInstance();

export default instance;
