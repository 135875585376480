import { call, put, takeLatest } from 'redux-saga/effects';

import { TwoFATypes } from 'shared/enums';
import {
  TAction,
  TCheckUserExistingAndSendOtp,
  TResponseCheckUserExistingAndSendOtp
} from 'shared/types';
import AuthService from 'shared/services/AuthService';
import {
  change2FAType as change2FATypeAction,
  checkUserExistingAndSendOtp as checkUserExistingAndSendOtpAction,
  checkUserExistingAndSendOtpSuccess
} from './auth.redux';
import {
  getItemFromLocalStorage,
  WHITE_LABEL_AFFILIATE
} from 'shared/utils/local-storage';

function* change2FAType({ payload }: TAction<TwoFATypes>) {
  try {
    yield call(AuthService.changeType2fa, payload as TwoFATypes);
    // yield call(AuthService.getOtpCode);
  } catch (error: any) {
    console.log('change2FAType error', error);
  }
}

function* checkUserExistingAndSendOtp({
  payload
}: TAction<TCheckUserExistingAndSendOtp>) {
  try {
    const whiteLabelAffiliate = getItemFromLocalStorage(WHITE_LABEL_AFFILIATE);
    if (whiteLabelAffiliate) {
      payload = { ...payload, affiliate: whiteLabelAffiliate };
    }
    const result: TResponseCheckUserExistingAndSendOtp = yield call(
      AuthService.checkUserExistingAndSendOtp,
      payload as TCheckUserExistingAndSendOtp
    );
    yield put(checkUserExistingAndSendOtpSuccess(result));
  } catch (error: any) {
    console.log('checkUserExistingAndSendOtp error', error);
  }
}

function* Auth() {
  yield takeLatest(change2FATypeAction.type, change2FAType);
  yield takeLatest(
    checkUserExistingAndSendOtpAction.type,
    checkUserExistingAndSendOtp
  );
}

export default Auth;
