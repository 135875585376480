import { themeExternalPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const UploadIcon: SvgIconType = ({
  width = 64,
  height = 64,
  className = '',
  color = themeExternalPrimary
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.528 29.6327C49.3627 20.454 41.5013 13.334 32.0107 13.334C24.6613 13.334 18.2773 17.63 15.3627 24.4007C9.63466 26.1127 5.34399 31.5207 5.34399 37.334C5.34399 44.686 11.3253 50.6673 18.6773 50.6673H21.344V45.334H18.6773C14.2667 45.334 10.6773 41.7447 10.6773 37.334C10.6773 33.59 13.8747 29.982 17.8053 29.2913L19.3547 29.0193L19.8667 27.5313C21.7413 22.062 26.3947 18.6673 32.0107 18.6673C39.3627 18.6673 45.344 24.6487 45.344 32.0007V34.6673H48.0107C50.952 34.6673 53.344 37.0593 53.344 40.0007C53.344 42.942 50.952 45.334 48.0107 45.334H42.6773V50.6673H48.0107C53.8933 50.6673 58.6773 45.8833 58.6773 40.0007C58.6742 37.61 57.8696 35.2895 56.3923 33.41C54.915 31.5305 52.8502 30.2005 50.528 29.6327Z"
      fill={color}
    />
    <path
      d="M29.3441 39.9993L29.3441 50.666L34.6774 50.666L34.6774 39.9993L42.6774 39.9993L32.0107 26.666L21.3441 39.9993L29.3441 39.9993Z"
      fill={color}
    />
  </svg>
);

export default UploadIcon;
