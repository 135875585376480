import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'antd';

import { Urls } from 'shared/constants/urls';
import {
  themePrimary,
  critical,
  themeTextSecondary
} from 'shared/utils/cssUtility';
import { ChatIcon } from 'shared/components/Icons';
import classes from './styles.module.scss';
import { getTwilioConversationObjectsSelector } from 'store/conversation/conversation.redux';

const route = Urls.Messages;

const Chat = () => {
  const navigate = useNavigate();
  const color = critical;
  const iconColor = themeTextSecondary;
  const twilioConversationObjects = useSelector(
    getTwilioConversationObjectsSelector
  );

  const isHL = (route: string) => {
    const path = window.location.pathname;
    return path === route;
  };

  const getColor = (selected: boolean) => {
    return selected ? themePrimary : iconColor;
  };

  return (
    <div
      // TODO: Figure out why this class is causing the icon to disaper when Note This is needed before I
      //Close out the ticket but I will come back to this
      className={classes['header__icons__chat-icon']}
      onClick={() => {
        navigate(route);
      }}
    >
      {/* TODO: Add the count of notifications here once the hook is there */}
      <Badge
        count={twilioConversationObjects.reduce((total, message) => {
          total += message.unreadCount;
          return total;
        }, 0)}
        size={'small'}
        overflowCount={9}
        offset={[5, -1]}
        color={color}
      >
        <ChatIcon color={getColor(isHL(route))} />
      </Badge>
    </div>
  );
};

export default Chat;
