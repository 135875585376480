import { IStateAddress } from 'shared/interfaces/state-address.interface';
import { http } from '../utils/axios';

class StatesService {
  private static instance: StatesService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  static getInstance(): StatesService {
    if (!StatesService.instance) {
      StatesService.instance = new StatesService();
    }

    return StatesService.instance;
  }

  getAllStates = async () => {
    const { data } = await http<IStateAddress[]>({
      url: '/states',
      method: 'GET'
    });

    return data;
  };
}

const instance = StatesService.getInstance();

export default instance;
