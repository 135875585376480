import { call, put, takeLatest } from 'redux-saga/effects';

import LabOrderService from 'shared/services/LabOrderService';
import { ILabOrder } from 'shared/interfaces/labOrder.interface';
import { TAction } from 'shared/types';
import {
  getLabOrder,
  setLabOrder,
  failAction,
  getLabOrders,
  setLabOrders
} from './labOrders.redux';

function* getLabOrdersSaga({ payload }: TAction<{ affiliate?: string }>) {
  try {
    const result: ILabOrder[] = yield call(
      LabOrderService.getLabOrders,
      payload as { affiliate?: string }
    );
    yield put(setLabOrders(result));
  } catch (error) {
    console.log('getLabOrdersSaga error', error);
    yield put(failAction());
  }
}

function* getLabOrderSaga({ payload }: TAction<{ id: string }>) {
  try {
    const labOrder: ILabOrder = yield call(
      LabOrderService.getLabOrder,
      payload?.id as string
    );
    yield put(setLabOrder(labOrder));
  } catch (error) {
    console.log('getLabOrderSaga error', error);
    yield put(setLabOrder(null));
    yield put(failAction());
  }
}

function* Orders() {
  yield takeLatest(getLabOrders.type, getLabOrdersSaga);
  yield takeLatest(getLabOrder.type, getLabOrderSaga);
}

export default Orders;
