import { createAction, createSlice } from '@reduxjs/toolkit';

import { IState } from 'shared/interfaces/state.interface';
import { IVisit } from 'shared/interfaces/visit.interface';
import { initialStore } from '../initial-store';

export const getVisits = createAction(
  'visits/getVisitsData',
  (payload: { patientId: string }) => ({ payload })
);

const visitsSlice = createSlice({
  name: 'visits',
  initialState: initialStore.visits,
  reducers: {
    getVisitsData: (state) => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    },
    setVisitsData: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload.visits;
    },
    getVisitsFail: (state) => {
      state.isLoading = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCurrentVisit: (state, data) => {
      state.isLoading = true;
    },
    setCurrentVisitData: (state, { payload }) => {
      state.isLoading = false;
      state.currentVisit = payload as IVisit;
    }
  }
});

export const {
  getVisitsData,
  getVisitsFail,
  setVisitsData,
  getCurrentVisit,
  setCurrentVisitData
} = visitsSlice.actions;

export const { reducer } = visitsSlice;

// Selectors
export const getVisitsSelector = (state: IState) => state && state.visits.data;

export const getCurrentVisitSelector = (state: IState) =>
  state && state.visits.currentVisit;

export const getVisitsLoadingSelector = (state: IState) =>
  state && state.visits.isLoading;
