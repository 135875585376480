import { useLayoutEffect, useState } from 'react';
import { IframeComponents } from 'shared/enums';

export const useOnlineVisit = () => {
  const [isOnlineVisit, setIsOnlineVisit] = useState(true);

  useLayoutEffect(() => {
    setIsOnlineVisit(
      window.location.href.includes('onlineVisit') ||
        window.location.href.includes('startVisit') ||
        window.location.href.includes(
          `iframe/${IframeComponents.Questionnaire}`
        ) ||
        window.location.href.includes(`iframe/${IframeComponents.Visit}`)
    );
  }, []);

  return isOnlineVisit;
};
