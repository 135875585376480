import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Invoice: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2V16L12.5 14L10.25 16L8 14L5.75 16L3.5 14L1 16V2C1 0.89543 1.89543 0 3 0H13C14.1046 0 15 0.89543 15 2ZM4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H12C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6H4ZM7 9C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11H12C12.5523 11 13 10.5523 13 10C13 9.44771 12.5523 9 12 9H7ZM4 3C3.44772 3 3 3.44772 3 4C3 4.55228 3.44772 5 4 5H12C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3H4Z"
      fill={color}
    />
  </svg>
);

export default Invoice;
