import './styles/index.scss'; // style import should be the first

import { FC, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ConfigProvider } from 'antd';

import TwilioProvider from 'shared/utils/twilio';
import store from './store';
import { App } from './App';
import {
  isReglow,
  initFacebookPixel
} from 'shared/components/ReglowFacebookPixel';
import {
  getAffiliateGlobalStylesSelector,
  getAppThemeSelector,
  getWhiteLabelAffiliateSelector
} from 'store/app/app.redux';

import './i18n';
import { antLanguage } from 'shared/utils/ant-language';
import { LANGUAGE, getItemFromLocalStorage } from 'shared/utils/local-storage';
import IntegrationsProvider from 'shared/utils/integrations';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''
);

const OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Oswald&display=swap'
    }
  ]
};

const AppWrapper: FC<PropsWithChildren> = () => {
  const appTheme = useSelector(getAppThemeSelector);
  const whiteLabelAffiliate = useSelector(getWhiteLabelAffiliateSelector);
  const affiliateGlobalStyles = useSelector(getAffiliateGlobalStylesSelector);
  const language = getItemFromLocalStorage(LANGUAGE);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === 'clearLocalStorage') {
        localStorage.clear();
        console.log('OVS local storage has been cleared.');
      }
    };

    window.addEventListener('message', handleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (whiteLabelAffiliate) {
      if (isReglow(whiteLabelAffiliate.id)) {
        initFacebookPixel();
      }
    }
  }, [whiteLabelAffiliate]);

  return (
    <div className="appWrapper" style={affiliateGlobalStyles?.variables}>
      <ConfigProvider theme={appTheme} locale={antLanguage[language]}>
        <Elements stripe={stripePromise} options={OPTIONS}>
          <Router>
            <TwilioProvider>
              <IntegrationsProvider>
                <App />
              </IntegrationsProvider>
            </TwilioProvider>
          </Router>
        </Elements>
      </ConfigProvider>
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>
);
