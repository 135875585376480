import { themeTextPrimary } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Task: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = themeTextPrimary
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25671 0.331044C7.62616 -0.0794662 8.25845 -0.112745 8.66896 0.256714C9.07947 0.626173 9.11275 1.25846 8.74329 1.66897L4.24329 6.66897C3.86002 7.09483 3.19802 7.11224 2.79289 6.70712L0.292893 4.20712C-0.0976311 3.81659 -0.0976311 3.18343 0.292893 2.7929C0.683418 2.40238 1.31658 2.40238 1.70711 2.7929L3.46181 4.5476L7.25671 0.331044ZM16 10C16 9.44772 15.5523 9.00001 15 9.00001H3L2.88338 9.00674C2.38604 9.0645 2 9.48717 2 10C2 10.5523 2.44772 11 3 11H15L15.1166 10.9933C15.614 10.9355 16 10.5128 16 10ZM16 15C16 14.4477 15.5523 14 15 14H3L2.88338 14.0067C2.38604 14.0645 2 14.4872 2 15C2 15.5523 2.44772 16 3 16H15L15.1166 15.9933C15.614 15.9355 16 15.5128 16 15ZM15 4.00001H10L9.88338 4.00674C9.38604 4.0645 9 4.48717 9 5.00001C9 5.55229 9.44771 6.00001 10 6.00001H15L15.1166 5.99328C15.614 5.93552 16 5.51284 16 5.00001C16 4.44772 15.5523 4.00001 15 4.00001Z"
      fill={color}
    />
  </svg>
);

export default Task;
