import { fileMessageFormats } from 'shared/constants/files';

export const truncateFileName = (str: string, number = 10) => {
  const parts = str.split('.');
  if (fileMessageFormats.includes(parts[1])) {
    const fileName = parts[0].substring(0, number);
    const fileExtension = parts[1];
    return fileName.length >= number ? `${fileName}....${fileExtension}` : str;
  } else {
    return str;
  }
};
