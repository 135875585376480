import { baseGrey } from 'shared/utils/cssUtility';
import { SvgIconType } from './svg.interface';

const Close: SvgIconType = ({
  width = 16,
  height = 16,
  className = '',
  color = baseGrey
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00065 6.58513L12.5926 1.99316C12.9835 1.60228 13.6173 1.60228 14.0081 1.99316C14.399 2.38405 14.399 3.0178 14.0081 3.40868L9.41683 8L14.0081 12.5913C14.399 12.9822 14.399 13.6159 14.0081 14.0068C13.6173 14.3977 12.9835 14.3977 12.5926 14.0068L8.00065 9.41487L3.40868 14.0068C3.0178 14.3977 2.38405 14.3977 1.99316 14.0068C1.60228 13.6159 1.60228 12.9822 1.99316 12.5913L6.58447 8L1.99316 3.40868C1.60228 3.0178 1.60228 2.38405 1.99316 1.99316C2.38405 1.60228 3.0178 1.60228 3.40868 1.99316L8.00065 6.58513Z"
      fill={color}
    />
  </svg>
);

export default Close;
